import React, { Component } from "react";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import {
    importStyle,
    topFunction,
    GoogleAnalyticsStatusEvent,
} from "../../App";
import ReactGA from "react-ga";
import { login } from "../../functions/services/Auth";

class Controller extends Component {
    constructor(props) {
        super(props);

        let bgColor = sessionStorage.getItem("--bg-color");

        this.state = {
            personalization: null,
            wallet: null,
            loading: true,
            bgColor,
        };
    }

    render() {
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div className="loader__position">
                    <div className="lds-ring">
                        <div
                            style={
                                this.state.bgColor !== "#FFFFFF" ||
                                    this.state.bgColor !== "#FFF" ||
                                    this.state.bgColor !== "#ffffff" ||
                                    this.state.bgColor !== "#fff"
                                    ? {
                                        borderColor:
                                            "#DDDDDD transparent transparent transparent",
                                    }
                                    : {
                                        borderColor:
                                            "#FFFFFF transparent transparent transparent",
                                    }
                            }
                        ></div>
                        <div
                            style={
                                this.state.bgColor !== "#FFFFFF" ||
                                    this.state.bgColor !== "#FFF" ||
                                    this.state.bgColor !== "#ffffff" ||
                                    this.state.bgColor !== "#fff"
                                    ? {
                                        borderColor:
                                            "#DDDDDD transparent transparent transparent",
                                    }
                                    : {
                                        borderColor:
                                            "#FFFFFF transparent transparent transparent",
                                    }
                            }
                        ></div>
                        <div
                            style={
                                this.state.bgColor !== "#FFFFFF" ||
                                    this.state.bgColor !== "#FFF" ||
                                    this.state.bgColor !== "#ffffff" ||
                                    this.state.bgColor !== "#fff"
                                    ? {
                                        borderColor:
                                            "#DDDDDD transparent transparent transparent",
                                    }
                                    : {
                                        borderColor:
                                            "#FFFFFF transparent transparent transparent",
                                    }
                            }
                        ></div>
                        <div
                            style={
                                this.state.bgColor !== "#FFFFFF" ||
                                    this.state.bgColor !== "#FFF" ||
                                    this.state.bgColor !== "#ffffff" ||
                                    this.state.bgColor !== "#fff"
                                    ? {
                                        borderColor:
                                            "#DDDDDD transparent transparent transparent",
                                    }
                                    : {
                                        borderColor:
                                            "#FFFFFF transparent transparent transparent",
                                    }
                            }
                        ></div>
                    </div>
                </div>
            </CSSTransition>
        );
    }

    componentDidMount() {
        this.onLoading();
    }

    onLoading() {
        switch (sessionStorage.getItem("Action")) {
            case "ConsultTree":
                this.consultTree();
                break;
            case "SaveAnswer":
                this.saveAnswer();
                break;
            case "Assist":
                this.assist();
                break;
            case "NextQuest":
                this.NextQuest();
                break;
            case "Conclude":
                this.conclude();
                break;
            case "Home":
                this.iniciate();
                break;
            case "Covered":
                this.props.history.push(`/itens-cobertos`);
                break;
            case "GoToPersonalInformation":
                this.props.history.push(`/informacoes-pessoais`);
                break;
            case "GoHome":
                this.props.history.push(`/`);
                break;
            case "Accompaniment":
                this.Accompaniment();
                break;
            case "AssistStatements":
                this.props.history.push(`/extrato-assistencias`);
                break;
            case "AssistDetail":
                this.AssistDetail();
                break;
            case "Cancel":
                this.Cancel();
                break;
            case "GoToLogin":
                this.GoToLogin();
                break;
            case "Rescheduling":
                this.Rescheduling();
                break;
            case "Logout":
                this.Logout();
                break;
            case "FAQ":
                this.FAQ();
                break;
            default:
                this.consultContact();
        }
    }

    async iniciate() {
        sessionStorage.clear();
        const url = process.env.REACT_APP_API_URL_ESSENCIA + "/auth";

        let todayLocalePtBr = this.getBrazilFormatDate();
        let formatedDate =
            todayLocalePtBr.getFullYear() +
            "-" +
            ("0" + (todayLocalePtBr.getMonth() + 1)).slice(-2) +
            "-" +
            ("0" + todayLocalePtBr.getDate()).slice(-2) +
            ("0" + todayLocalePtBr.getHours()).slice(-2) +
            ":" +
            (todayLocalePtBr.getMinutes() < 10 ? "0" : "") +
            todayLocalePtBr.getMinutes() +
            ":00";

        fetch(url, {
            headers: new Headers({
                "Content-Type": "application/json",
            }),
            method: "post",
            body: JSON.stringify({
                timestamp: formatedDate,
                apitoken: process.env.REACT_APP_API_TOKEN,
            }),
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                } else {
                    return response.json();
                }
            })
            .then((response) => {
                GoogleAnalyticsStatusEvent(
                    sessionStorage.getItem("SelectedWalet"),
                    "Carregamento Cliente Corporativo - Sucesso",
                    "Sucesso"
                );
                sessionStorage.setItem("Token", response.token);
                this.custom();
            })
            .catch((error) => {
                console.log(error);
                GoogleAnalyticsStatusEvent(
                    sessionStorage.getItem("SelectedWalet"),
                    "Carregamento Cliente Corporativo - Erro",
                    "Erro de conexão ao consumo dos serviços JUVO"
                );
                this.props.history.push("/404");
            });
    }

    getBrazilFormatDate() {
        let todayLocal = new Date();

        let todayUtc = todayLocal.toUTCString();

        let todayLocalePtBr = new Date(
            todayUtc.toLocaleString("pt-BR", { timeZone: "America/Bahia" })
        );
        return todayLocalePtBr;
    }

    custom() {
        var url_string__logo = window.location.href;
        var corporate_client = new URL(url_string__logo);
        let c = corporate_client.searchParams.get("c");
        if (c === "null") {
            sessionStorage.setItem(
                "Erro400",
                "Erro - Cliente Corporativo não foi informado."
            );
            this.props.history.push(`/Erro400`);
            return;
        }
        let idCarteira = corporate_client.searchParams.get("idCarteira");
        let idItem = corporate_client.searchParams.get("idItem");
        const Token = sessionStorage.getItem("Token");
        const url =
            process.env.REACT_APP_API_URL_ESSENCIA + "/cliente-corporativo";
        fetch(url, {
            headers: new Headers({
                Authorization: "Bearer " + Token,
                "Content-Type": "application/json",
            }),
            method: "post",
            body: JSON.stringify({
                idclientecorporativo: c,
            }),
        })
            .then((response) => {
                if (response.status === 401) {
                    this.iniciate();
                }
                return response.json();
            })
            .then((response) => {
                if (response.message) {
                    sessionStorage.setItem("Erro400", response.message);
                    this.props.history.push("/Erro400");
                    return;
                }
                // Client Style
                

                sessionStorage.setItem("FlagTrackProviderArrival", response.flacompchegpres === "sim" ? "True" : "False");
                sessionStorage.setItem("FlagCancelAssist", response.flcanassistencia === "sim" ? "True" : "False");
                sessionStorage.setItem("FlagRescheduleAssist", response.flreagassistencia === "sim" ? "True" : "False");
                sessionStorage.setItem("FlagAttachments", response.fllinkvideofoto === "sim" ? "True" : "False");
                sessionStorage.setItem("FlagPreAssist", response.flpreassistencia === "sim" ? "True" : "False");
                sessionStorage.setItem("FlagProtocolCli", response.flprotocolocli === "sim" ? "True" : "False");
                var stylePerso = [];
                sessionStorage.setItem(
                    "--bg-color",
                    "#" + response["bg-color"]
                );
                stylePerso.push("--bg-color");
                stylePerso.push("#" + response["bg-color"]);
                sessionStorage.setItem(
                    "--danger--color",
                    "#" + response["danger--color"]
                );
                stylePerso.push("--danger--color");
                stylePerso.push("#" + response["danger--color"]);
                sessionStorage.setItem(
                    "--main-bg-color",
                    "#" + response["main-bg-color"]
                );
                stylePerso.push("--main-bg-color");
                stylePerso.push("#" + response["main-bg-color"]);
                sessionStorage.setItem(
                    "--main-text-color",
                    "#" + response["main-text-color"]
                );
                stylePerso.push("--main-text-color");
                stylePerso.push("#" + response["main-text-color"]);
                sessionStorage.setItem(
                    "--question--color-card",
                    "#" + response["question--color-card"]
                );
                stylePerso.push("--question-color-card");
                stylePerso.push("#" + response["question--color-card"]);
                sessionStorage.setItem(
                    "--question-bg-color",
                    "#" + response["question-bg-color"]
                );
                stylePerso.push("--question-bg-color");
                stylePerso.push("#" + response["question-bg-color"]);
                sessionStorage.setItem(
                    "--question-color-text",
                    "#" + response["question-color-color"]
                );
                stylePerso.push("--question-color-text");
                stylePerso.push("#" + response["question-color-color"]);
                sessionStorage.setItem(
                    "--secundary-text-color",
                    "#" + response["secundary-text-color"]
                );
                stylePerso.push("--secundary-text-color");
                stylePerso.push("#" + response["secundary-text-color"]);
                sessionStorage.setItem(
                    "--success--color",
                    "#" + response["success--color"]
                );
                stylePerso.push("--success--color");
                stylePerso.push("#" + response["success--color"]);
                sessionStorage.setItem("--font-family-main", response.fonte);
                stylePerso.push("--font-family-main");
                stylePerso.push(response.fonte);
                sessionStorage.setItem(
                    "--header-first-page-color",
                    "#" + response["header-first-page-color"]
                );
                stylePerso.push("--header-first-page-color");
                stylePerso.push("#" + response["header-first-page-color"]);

                /**/
                sessionStorage.setItem(
                    "--icon-text-color",
                    "#" + response["icon-text-color"]
                );
                stylePerso.push("--icon-text-color");
                stylePerso.push("#" + response["icon-text-color"]);
                sessionStorage.setItem(
                    "--action-button-color",
                    "#" + response["action-button-color"]
                );
                stylePerso.push("--action-button-color");
                stylePerso.push("#" + response["action-button-color"]);
                sessionStorage.setItem(
                    "--text-action-button-color",
                    "#" + response["text-action-button-color"]
                );
                stylePerso.push("--text-action-button-color");
                stylePerso.push("#" + response["text-action-button-color"]);
                sessionStorage.setItem(
                    "--text-field-color",
                    "#" + response["text-field-color"]
                );
                stylePerso.push("--text-field-color");
                stylePerso.push("#" + response["text-field-color"]);
                sessionStorage.setItem(
                    "--question-faq-color",
                    "#" + response["question-faq-color"]
                );
                stylePerso.push("--question-faq-color");
                stylePerso.push("#" + response["question-faq-color"]);
                sessionStorage.setItem(
                    "--answer-faq-color",
                    "#" + response["answer-faq-color"]
                );
                stylePerso.push("--answer-faq-color");
                stylePerso.push("#" + response["answer-faq-color"]);
                sessionStorage.setItem(
                    "--card-faq-color",
                    "#" + response["card-faq-color"]
                );
                stylePerso.push("--card-faq-color");
                stylePerso.push("#" + response["card-faq-color"]);


                sessionStorage.setItem("Favicon", response.nmarqfavicon);
                importStyle(stylePerso);
                sessionStorage.setItem("Logo", response.imglogo);
                // Headers API Tempo
                sessionStorage.setItem("token", response.token);
                sessionStorage.setItem("chave", response.chave);
                sessionStorage.setItem(
                    "idclientecorporativo",
                    response.idclientecorporativo
                );
                sessionStorage.setItem("Authorization", response.authorization);
                sessionStorage.setItem("checkClient", "true");
                sessionStorage.setItem(
                    "NmCliCorporativo",
                    response.nmclicorporativo
                );

                /** Guarda os dados planos no storage */
                if(Object.values(response.planos).length > 0){
                    sessionStorage.setItem("planos", JSON.stringify(response.planos));
                }

                if (response.carteiras !== undefined) {
                    let size = sessionStorage.length;
                    for (let i = 0; i < size; i++) {
                        if (sessionStorage.key(i) != null) {
                            if (sessionStorage.key(i).includes("HomeName")) {
                                sessionStorage.removeItem(
                                    sessionStorage.key(i)
                                );
                                i = i - 1;
                                size = size - 1;
                            }
                        }
                        if (sessionStorage.key(i) != null) {
                            if (sessionStorage.key(i).includes("Home")) {
                                sessionStorage.removeItem(
                                    sessionStorage.key(i)
                                );
                                i = i - 1;
                                size = size - 1;
                            }
                        }
                        if (sessionStorage.key(i) != null) {
                            if (sessionStorage.key(i).includes("HomeCustomField")) {
                                sessionStorage.removeItem(
                                    sessionStorage.key(i)
                                );
                                i = i - 1;
                                size = size - 1;
                            }
                        }
                        if (sessionStorage.key(i) != null) {
                            if (sessionStorage.key(i).includes("HomeId")) {
                                sessionStorage.removeItem(
                                    sessionStorage.key(i)
                                );
                                i = i - 1;
                                size = size - 1;
                            }
                        }
                    }
                    sessionStorage.setItem(
                        "WaletSize",
                        response.carteiras.length
                    );
                    for (let i = 0; i < response.carteiras.length; i++) {
                        if (response.carteiras[i].campos && response.carteiras[i].campos.emailcli) {
                            sessionStorage.setItem(
                                "HomeCustomField" + response.carteiras[i].idcarteira,
                                response.carteiras[i].campos.emailcli.flobrigatorio
                            );
                        }
                        sessionStorage.setItem(
                            "HomeName" + i,
                            response.carteiras[i].nomecarteira
                        );
                        sessionStorage.setItem(
                            "Home" + response.carteiras[i].nomecarteira,
                            response.carteiras[i].urlicone
                        );
                        sessionStorage.setItem(
                            "HomeId" + i,
                            response.carteiras[i].idcarteira
                        );
                        sessionStorage.setItem(
                            "HomeLogin" + response.carteiras[i].idcarteira,
                            JSON.stringify(response.carteiras[i].login)
                        );
                    }
                    this.checkFAQ();
                    if (idCarteira !== null && idItem !== null) {
                        login("F9A777F6C2550A281D01D162C40C2588");
                        sessionStorage.setItem("idclientecorporativo", c);
                        sessionStorage.setItem("idtipocarteira", idCarteira);
                        sessionStorage.setItem("SelectedWaletId", idCarteira);
                        if (idCarteira === "1") {
                            sessionStorage.setItem(
                                "SelectedWalet",
                                "Automóvel"
                            );
                        } else {
                            sessionStorage.setItem(
                                "SelectedWalet",
                                "Residencia"
                            );
                        }
                        sessionStorage.setItem("ConveredSelectedItem", idItem);
                        sessionStorage.setItem("Action", "ConsultTree");
                        sessionStorage.setItem("FirstQuestion", "True");
                        this.consultTree();
                        return;
                    } else if (idCarteira !== null) {
                        sessionStorage.setItem("idclientecorporativo", c);
                        sessionStorage.setItem("idtipocarteira", idCarteira);
                        sessionStorage.setItem("SelectedWaletId", idCarteira);
                        if (idCarteira === "1") {
                            sessionStorage.setItem(
                                "SelectedWalet",
                                "Automóvel"
                            );
                        } else {
                            sessionStorage.setItem(
                                "SelectedWalet",
                                "Residencia"
                            );
                        }
                        sessionStorage.setItem("Action", "GoToLogin");
                        this.GoToLogin();
                        return;
                    }
                    sessionStorage.setItem("Action", "Home");
                    this.props.history.push("/");
                    GoogleAnalyticsStatusEvent(
                        sessionStorage.getItem("SelectedWalet"),
                        "Carregamento Cliente Corporativo - Sucesso",
                        "Sucesso"
                    );
                } else {
                    GoogleAnalyticsStatusEvent(
                        sessionStorage.getItem("SelectedWalet"),
                        "Carregamento Cliente Corporativo - Erro",
                        "Cliente Corporativo não Cadastrado."
                    );
                    sessionStorage.setItem(
                        "Erro400",
                        "Erro - Cliente Corporativo não Cadastrado."
                    );
                    this.props.history.push("/Erro400");
                    return;
                }
            })
            .catch((error) => {
                GoogleAnalyticsStatusEvent(
                    sessionStorage.getItem("SelectedWalet"),
                    "Carregamento Cliente Corporativo - Erro",
                    "Erro de conexão ao consumo dos serviços JUVO"
                );
                sessionStorage.setItem("Erro400", error.message);
                this.props.history.push("/Erro400");
                return;
            });
    }

    checkFAQ() {
        const Token = sessionStorage.getItem("Token");
        const url = process.env.REACT_APP_API_URL_ESSENCIA + "/faq";
        fetch(url, {
            headers: new Headers({
                Authorization: "Bearer " + Token,
                "Content-Type": "application/json",
            }),
            method: "post",
            body: JSON.stringify({
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                sessionStorage.setItem(
                    "NoFaqReturn",
                    Object.keys(response.faqs).length
                );
                if (Object.keys(response.faqs).length !== null) {
                    document.getElementById("faq-sidebar").style.display =
                        "block";
                }
            })
            .catch((error) => {
                console.log(error);
                sessionStorage.setItem("NoFaqReturn", "0");
            });
    }

    GoToLogin() {
        sessionStorage.setItem("ActualPage", "/Login");
        this.props.history.push("Login");
        this.props.reloadFunc();
    }

    consultTree() {
        const ConveredSelectedItem = sessionStorage.getItem(
            "ConveredSelectedItem"
        );
        const url =
            process.env.REACT_APP_API_URL_TEMPO +
            "/questionarioAssistencia/existeArvoreDecisao/" +
            ConveredSelectedItem;
        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("ConveredSelectedPlanToken") != undefined ? sessionStorage.getItem("ConveredSelectedPlanToken") : sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("ConveredSelectedPlanChave") != undefined ? sessionStorage.getItem("ConveredSelectedPlanChave") : sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.codigo === 0) {
                    sessionStorage.setItem(
                        "juvoTransactionID",
                        response.juvoTransactionID
                    );
                    ReactGA.ga("set", "page", "/");
                    ReactGA.ga("send", "pageview", {
                        page: "/",
                        title: "Tempo Push",
                    });
                    ReactGA.ga("set", "page", "/");
                    ReactGA.ga("send", "pageview", {
                        page: "/login",
                        title: "Tempo Push",
                    });
                    this.requestFirstQuestion(ConveredSelectedItem);
                } else {
                    if (
                        response.descricao === "Cliente corporativo inv�lido."
                    ) {
                        sessionStorage.setItem(
                            "SpecificError",
                            "Consulta de arvore: " +
                            "Cliente corporativo inválido."
                        );
                    } else {
                        sessionStorage.setItem(
                            "SpecificError",
                            "Consulta de arvore: " + response.descricao
                        );
                    }
                    sessionStorage.removeItem("FirstQuestion");
                    GoogleAnalyticsStatusEvent(
                        sessionStorage.getItem("SelectedWalet"),
                        "Árvore - Erro",
                        "Consulta de arvore: " + response.descricao
                    );
                    sessionStorage.setItem("SpecificError", response.descricao);
                    sessionStorage.setItem("GenericError", "False");
                    this && this.consultContact();
                }
            })
            .catch((error) => {
                GoogleAnalyticsStatusEvent(
                    sessionStorage.getItem("SelectedWalet"),
                    "Árvore - Erro",
                    "Erro de conexão ao consumo dos serviços JUVO"
                );
                sessionStorage.setItem("SpecificError", "Não conseguimos prosseguir com a abertura da sua assistência.");
                sessionStorage.setItem("GenericError", "False");
                this && this.consultContact();
            });
        topFunction();
    }

    requestFirstQuestion(idItemCoberto) {
        const url =
            process.env.REACT_APP_API_URL_TEMPO +
            "/questionarioAssistencia/arvoreDecisao/" +
            idItemCoberto +
            "/1/false";

        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("ConveredSelectedPlanToken") != undefined ? sessionStorage.getItem("ConveredSelectedPlanToken") : sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("ConveredSelectedPlanChave") != undefined ? sessionStorage.getItem("ConveredSelectedPlanChave") : sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.codigo === 999) {
                    GoogleAnalyticsStatusEvent(
                        sessionStorage.getItem("SelectedWalet"),
                        "Árvore - Erro",
                        "Chamada da pergunta inicial: " + response.descricao
                    );
                    this && this.consultContact();

                    const contacts = JSON.parse(
                        sessionStorage.getItem("ContactDescription")
                            ? sessionStorage.getItem("ContactDescription")
                            : []
                    );
                    sessionStorage.setItem("SpecificError", "Não conseguimos prosseguir com a abertura da sua assistência.");
                    sessionStorage.setItem("GenericError", "False");
                    return;
                }
                sessionStorage.removeItem("ArrayQuestions");
                sessionStorage.removeItem("QuestionTitle");
                let size = sessionStorage.length;
                for (let i = 0; i < size; i++) {
                    if (sessionStorage.key(i) != null) {
                        if (sessionStorage.key(i).includes("QuestionText")) {
                            sessionStorage.removeItem(sessionStorage.key(i));
                            i = i - 1;
                            size = size - 1;
                        }
                    }

                    if (sessionStorage.key(i) != null) {
                        if (sessionStorage.key(i).includes("QuestionId")) {
                            sessionStorage.removeItem(sessionStorage.key(i));
                            i = i - 1;
                            size = size - 1;
                        }
                    }

                    if (sessionStorage.key(i) != null) {
                        if (sessionStorage.key(i).includes("NextQuestion")) {
                            sessionStorage.removeItem(sessionStorage.key(i));
                            i = i - 1;
                            size = size - 1;
                        }
                    }

                    if (sessionStorage.key(i) != null) {
                        if (sessionStorage.key(i).includes("QuestionUrl")) {
                            sessionStorage.removeItem(sessionStorage.key(i));
                            i = i - 1;
                            size = size - 1;
                        }
                    }
                }
                size = sessionStorage.length;
                for (let i = 0; i < size; i++) {
                    if (sessionStorage.key(i) != null) {
                        if (sessionStorage.key(i).includes("QuestionText")) {
                            sessionStorage.removeItem(sessionStorage.key(i));
                            i = i - 1;
                            size = size - 1;
                        }
                    }

                    if (sessionStorage.key(i) != null) {
                        if (sessionStorage.key(i).includes("QuestionId")) {
                            sessionStorage.removeItem(sessionStorage.key(i));
                            i = i - 1;
                            size = size - 1;
                        }
                    }

                    if (sessionStorage.key(i) != null) {
                        if (sessionStorage.key(i).includes("NextQuestion")) {
                            sessionStorage.removeItem(sessionStorage.key(i));
                            i = i - 1;
                            size = size - 1;
                        }
                    }

                    if (sessionStorage.key(i) != null) {
                        if (sessionStorage.key(i).includes("QuestionUrl")) {
                            sessionStorage.removeItem(sessionStorage.key(i));
                            i = i - 1;
                            size = size - 1;
                        }
                    }
                }
                sessionStorage.setItem(
                    "QuestionTitle",
                    response.descricaoPergunta
                );
                sessionStorage.setItem(
                    "QuestionPath",
                    response.urlImagemExternaPergunta
                );
                sessionStorage.setItem("AtualQuestion", "1");
                sessionStorage.setItem("AtualId", response.idPergunta);
                for (let i = 0; i < response.itensArvoreDecisao.length; i++) {
                    sessionStorage.setItem(
                        "QuestionText" + i,
                        response.itensArvoreDecisao[i].resposta
                    );
                    sessionStorage.setItem(
                        "QuestionId" + i,
                        response.itensArvoreDecisao[i].idResposta
                    );
                    sessionStorage.setItem(
                        "QuestionUrl" + i,
                        response.itensArvoreDecisao[i].urlImagemExternaResposta
                    );
                    sessionStorage.setItem(
                        "NextQuestion" + i,
                        response.itensArvoreDecisao[i].idProximaPergunta
                    );
                }
                this.redirectToQuestion(response.idTipoPerguntaFluxo);
                this.props.reloadSidebar(this);
            })
            .catch((error) => {
                GoogleAnalyticsStatusEvent(
                    sessionStorage.getItem("SelectedWalet"),
                    "Árvore - Erro",
                    "Erro de conexão ao consumo dos serviços JUVO"
                );
                sessionStorage.setItem("SpecificError", "Não conseguimos prosseguir com a abertura da sua assistência.");
                sessionStorage.setItem("GenericError", "False");
                this && this.consultContact();
            });
    }

    saveAnswer() {
        const ConveredSelectedItem = sessionStorage.getItem(
            "ConveredSelectedItem"
        );
        const AtualQuestion = sessionStorage.getItem("AtualId");
        const IdAnswer = sessionStorage.getItem("IdAnswer");
        const JuvoTransacaoID = sessionStorage.getItem("juvoTransactionID");
        const url =
            process.env.REACT_APP_API_URL_TEMPO +
            "/questionarioAssistencia/respondeArvoreDecisao/371573681/0/" +
            AtualQuestion +
            "/" +
            IdAnswer;
        fetch(url, {
            method: "post",
            headers: new Headers({
                token: sessionStorage.getItem("ConveredSelectedPlanToken") != undefined ? sessionStorage.getItem("ConveredSelectedPlanToken") : sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("ConveredSelectedPlanChave") != undefined ? sessionStorage.getItem("ConveredSelectedPlanChave") : sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
                "X-JuvoTransacaoID": JuvoTransacaoID,
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                GoogleAnalyticsStatusEvent(
                    sessionStorage.getItem("SelectedWalet"),
                    "Árvore - Sucesso",
                    "Sucesso"
                );
            })
            .catch((error) => {
                console.log("");
                GoogleAnalyticsStatusEvent(
                    sessionStorage.getItem("SelectedWalet"),
                    "Árvore - Erro",
                    "Erro de conexão ao consumo dos serviços JUVO"
                );
                sessionStorage.setItem("SpecificError", "");
                sessionStorage.setItem("GenericError", "True");
                this && this.consultContact();
            });
        topFunction();
        this.requestNextQuestion(ConveredSelectedItem);
    }

    NextQuest() {
        let ConveredSelectedItem = sessionStorage.getItem(
            "ConveredSelectedItem"
        );
        let ArrayQuestions = JSON.parse(
            sessionStorage.getItem("ArrayQuestions")
        );
        if (ArrayQuestions.length <= 1) {
            this.props.history.push("/itens-cobertos");
            sessionStorage.setItem("ActualPage", "/itens-cobertos");
        } else {
            ArrayQuestions.pop();
            sessionStorage.setItem(
                "NextQuestionSelected",
                ArrayQuestions[ArrayQuestions.length - 1]
            );
            sessionStorage.setItem(
                "ArrayQuestions",
                JSON.stringify(ArrayQuestions)
            );
            this.requestNextQuestion(ConveredSelectedItem);
        }
    }

    requestNextQuestion(idItemCoberto) {
        let nextQuestion = sessionStorage.getItem("NextQuestionSelected");
        nextQuestion = nextQuestion !== null ? nextQuestion : "";
        if (nextQuestion !== "0") {
            const url =
                process.env.REACT_APP_API_URL_TEMPO +
                "/questionarioAssistencia/arvoreDecisao/" +
                idItemCoberto +
                "/" +
                nextQuestion +
                "/false";

            fetch(url, {
                headers: new Headers({
                    token: sessionStorage.getItem("ConveredSelectedPlanToken") != undefined ? sessionStorage.getItem("ConveredSelectedPlanToken") : sessionStorage.getItem("token"),
                    chave: sessionStorage.getItem("ConveredSelectedPlanChave") != undefined ? sessionStorage.getItem("ConveredSelectedPlanChave") : sessionStorage.getItem("chave"),
                    idclientecorporativo: sessionStorage.getItem(
                        "idclientecorporativo"
                    ),
                    idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                    Authorization: sessionStorage.getItem("Authorization"),
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    sessionStorage.removeItem("QuestionTitle");
                    sessionStorage.removeItem("AtualQuestion");
                    let size = sessionStorage.length;
                    for (let i = 0; i < size; i++) {
                        if (sessionStorage.key(i) != null) {
                            if (
                                sessionStorage.key(i).includes("QuestionText")
                            ) {
                                sessionStorage.removeItem(
                                    sessionStorage.key(i)
                                );
                                i = i - 1;
                                size = size - 1;
                            }
                        }
                    }

                    size = sessionStorage.length;
                    for (let i = 0; i < size; i++) {
                        if (sessionStorage.key(i) != null) {
                            if (sessionStorage.key(i).includes("QuestionId")) {
                                sessionStorage.removeItem(
                                    sessionStorage.key(i)
                                );
                                i = i - 1;
                                size = size - 1;
                            }
                        }
                    }

                    size = sessionStorage.length;
                    for (let i = 0; i < size; i++) {
                        if (sessionStorage.key(i) != null) {
                            if (
                                sessionStorage.key(i).includes("NextQuestion")
                            ) {
                                sessionStorage.removeItem(
                                    sessionStorage.key(i)
                                );
                                i = i - 1;
                                size = size - 1;
                            }
                        }
                    }

                    size = sessionStorage.length;
                    for (let i = 0; i < size; i++) {
                        if (sessionStorage.key(i) != null) {
                            if (sessionStorage.key(i).includes("QuestionUrl")) {
                                sessionStorage.removeItem(
                                    sessionStorage.key(i)
                                );
                                i = i - 1;
                                size = size - 1;
                            }
                        }
                    }

                    sessionStorage.setItem(
                        "QuestionTitle",
                        response.descricaoPergunta
                    );
                    sessionStorage.setItem("AtualQuestion", response.grupo);
                    sessionStorage.setItem("AtualId", response.idPergunta);
                    sessionStorage.setItem(
                        "QuestionPath",
                        response.urlImagemExternaPergunta
                    );
                    for (
                        let i = 0;
                        i < response.itensArvoreDecisao.length;
                        i++
                    ) {
                        sessionStorage.setItem(
                            "QuestionText" + i,
                            response.itensArvoreDecisao[i].resposta
                        );
                        sessionStorage.setItem(
                            "QuestionId" + i,
                            response.itensArvoreDecisao[i].idResposta
                        );
                        sessionStorage.setItem(
                            "QuestionUrl" + i,
                            response.itensArvoreDecisao[i]
                                .urlImagemExternaResposta
                        );
                        sessionStorage.setItem(
                            "NextQuestion" + i,
                            response.itensArvoreDecisao[i].idProximaPergunta
                        );
                    }
                    this.redirectToQuestion(response.idTipoPerguntaFluxo);
                })
                .catch((error) => {
                    console.log(error);
                    GoogleAnalyticsStatusEvent(
                        sessionStorage.getItem("SelectedWalet"),
                        "Árvore - Erro",
                        "Erro de conexão ao consumo dos serviços JUVO"
                    );
                    sessionStorage.setItem("SpecificError", "");
                    sessionStorage.setItem("GenericError", "True");
                    this && this.consultContact();
                });
        } else {
            this.getIdCombo();
        }
    }

    redirectToQuestion(type) {
        switch (type) {
            case 1:
                this.props.history.push(`/decisao`);
                break;
            case 2:
                this.props.history.push(`/escolha-unica-2a6`);
                break;
            case 3:
                this.props.history.push(`/escolha-unica-6aN`);
                break;
            case 4:
                this.props.history.push(`/escala-estatica`);
                break;
            case 5:
                this.props.history.push(`/escala-dinamica`);
                break;
            case 6:
                this.props.history.push(`/alerta`);
                break;
            default:
                this.props.history.push(`/escolha-unica-6aN`);
        }
    }

    getIdCombo() {
        let AtualQuestion = sessionStorage.getItem("AtualQuestion");
        const ConveredSelectedItem = sessionStorage.getItem(
            "ConveredSelectedItem"
        );

        AtualQuestion = AtualQuestion !== null ? AtualQuestion : "";
        const url =
            process.env.REACT_APP_API_URL_TEMPO +
            "/questionarioAssistencia/arvoreDecisao/" +
            ConveredSelectedItem +
            "/" +
            AtualQuestion +
            "/false";

        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("ConveredSelectedPlanToken") != undefined ? sessionStorage.getItem("ConveredSelectedPlanToken") : sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("ConveredSelectedPlanChave") != undefined ? sessionStorage.getItem("ConveredSelectedPlanChave") : sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                for (let i = 0; i < response.itensArvoreDecisao.length; i++) {
                    if (
                        response.itensArvoreDecisao[i].idResposta + "" ===
                        sessionStorage.getItem("IdAnswer")
                    ) {
                        if (response.itensArvoreDecisao[i].fimFluxo) {
                            sessionStorage.setItem(
                                "SpecificError",
                                response.itensArvoreDecisao[i].mensagemFimFluxo
                            );
                            sessionStorage.setItem("GenericError", "false");
                            sessionStorage.setItem("Action", "NextQuest");
                            this && this.consultContact();
                        } else {
                            let combo = response.itensArvoreDecisao[i].combo.id;
                            this.consultCombo(combo);
                        }
                    }
                }
            })
            .catch((error) => {
                console.log("");
                GoogleAnalyticsStatusEvent(
                    sessionStorage.getItem("SelectedWalet"),
                    "Árvore - Erro",
                    "Erro de conexão ao consumo dos serviços JUVO"
                );
                sessionStorage.setItem("SpecificError", "");
                sessionStorage.setItem("GenericError", "True");
                this && this.consultContact();
            });
    }

    consultCombo(combo) {
        const url =
            process.env.REACT_APP_API_URL_TEMPO +
            "/questionarioAssistencia/recuperaComboArvore/" +
            combo;

        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                sessionStorage.setItem("ComboId", combo);
                sessionStorage.setItem("CoverageSelectedId", response.listaCombo[0].idCobertura);
                sessionStorage.setItem("EventSelectedId", response.listaCombo[0].idEvento);
                let DestinyRequired = false;
                for (let i = 0; i < response.listaCombo.length; i++) {
                    if (response.listaCombo[i].exigeEnderecoDestino === "V") {
                        DestinyRequired = true;
                    }
                }
                DestinyRequired
                    ? sessionStorage.setItem("DestinyRequired", "True")
                    : sessionStorage.setItem("DestinyRequired", "False");

                var planCover = sessionStorage.getItem("ConveredSelectedPlanCover");
                var planProduct = sessionStorage.getItem("ConveredSelectedPlanProduct");
                
                if(planCover != null && planProduct != null){
                    this.preAssist();
                }else{
                    this.props.history.push(`/extrato-assistencia`);
                }
            })
            .catch((error) => {
                sessionStorage.setItem("SpecificError", "");
                sessionStorage.setItem("GenericError", "True");
                this && this.consultContact();
            });
    }

    preAssist() {
        var isOmni = sessionStorage.getItem("FlagPreAssist");
        if(isOmni == 'True'){
            this.preAssistCreate();  
        }else{
            this.props.history.push("/informacoes-pessoais");
        
            topFunction();
        }
    }

    preAssistCreate() {
        const url =
        process.env.REACT_APP_API_URL_TEMPO +
        "/pre-assistencia/criar";

        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
                "Content-Type": "application/json",
                "X-JuvoTransacaoID": sessionStorage.getItem("juvoTransactionID")
            }),
            method: "post",
            body: JSON.stringify({
                idItemCoberto: sessionStorage.getItem("ConveredSelectedItem"),
                idChaveCombo: sessionStorage.getItem("ComboId"),
                usuarioAbertura: sessionStorage.getItem("clerkName") === null
                            ? ""
                            : sessionStorage.getItem("clerkName")
            }),
        })
            .then((response) => {
                try {
                    return response.json();
                } catch (error) {
                    return Promise.resolve({});
                }
            })
            .then((response) => {
                if (response.idAssistencia) {
                    sessionStorage.setItem("PreAssistId", response.idAssistencia);
                    this.props.history.push("/informacoes-pessoais");
        
                    topFunction();
                } 
            })
            .catch((error) => {
                console.log(error);
            });
    }

    preAssistFinish() {
        const url =
        process.env.REACT_APP_API_URL_TEMPO +
        "/pre-assistencia/criar-assistencia";

        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
                "Content-Type": "application/json",
                "X-JuvoTransacaoID": sessionStorage.getItem("juvoTransactionID")
            }),
            method: "post",
            body: JSON.stringify({
                "preNovoServicoAssistencia":{
                    "idAssistencia": sessionStorage.getItem("PreAssistId")
                },
            }),
        })
        .then((response) => {
            try {
                return response.json();
            } catch (error) {
                return Promise.resolve({});
            }
        })
        .then((response) => {
            if (response.codigo === 999) {
                sessionStorage.setItem("GenericError", "False");
                if (response.descricaoAuxiliar) {
                    sessionStorage.setItem(
                        "SpecificError",
                        response.descricao +
                        "\n\r" +
                        (response.descricaoAuxiliar !==
                            undefined &&
                            response.descricaoAuxiliar != "-1"
                            ? response.descricaoAuxiliar
                            : "")
                    );
                    GoogleAnalyticsStatusEvent(
                        sessionStorage.getItem("SelectedWalet"),
                        "Abertura da assistência - Erro",
                        response.descricaoAuxiliar
                    );
                } else {
                    sessionStorage.setItem(
                        "SpecificError",
                        response.descricao
                    );
                    GoogleAnalyticsStatusEvent(
                        sessionStorage.getItem("SelectedWalet"),
                        "Abertura da assistência - Erro",
                        response.descricao
                    );
                }
                this && this.consultContact();
            } else {
                this.generateConcludeAssistData(
                    response,
                    response.idAssistencia
                );
                GoogleAnalyticsStatusEvent(
                    sessionStorage.getItem("SelectedWalet"),
                    "Abertura da assistência - Sucesso",
                    response.descricao
                );
            }
            this.clearAddress();
        })
        .catch((error) => {
            GoogleAnalyticsStatusEvent(
                sessionStorage.getItem("SelectedWalet"),
                "Abertura da assistência - Erro",
                "Erro de conexão ao consumo dos serviços JUVO"
            );
            this.clearAddress();
            console.log(error);
            sessionStorage.setItem("SpecificError", "");
            sessionStorage.setItem("GenericError", "True");
            this && this.consultContact();
        });
    }

    assist() {
        let BackPage = JSON.parse(sessionStorage.getItem("BackPage"));
        if (BackPage[BackPage.length - 1] === "/AddressDestiny") {
            this.props.history.push(`/AddressOrigin`);
        } else {
            if (sessionStorage.getItem("DestinyRequired") === "True") {
                this.props.history.push(`/AddressDestiny`);
            } else {
                var isOmni = sessionStorage.getItem("FlagPreAssist");
                if(isOmni == 'True'){
                    this.preAssistFinish();
                }else{
                    let str = sessionStorage.getItem("ContactTel");
                    let ddd = str.substr(1, 2);
                    var number = "";
                    if (str.length !== 14) {
                        number = str.substr(4, 4) + str.substr(9, 4);
                        for (let i = 0; i < number.length; i++) {
                            if (number[i] === "_") {
                                number = number.replace("_", "");
                                i--;
                            }
                        }
                    } else {
                        number = str.substr(4, 5) + str.substr(10, 4);
                        for (let i = 0; i < number.length; i++) {
                            if (number[i] === "_") {
                                number = number.replace("_", "");
                                i--;
                            }
                        }
                    }
                    const url =
                        process.env.REACT_APP_API_URL_TEMPO +
                        "/assistencia/" +
                        sessionStorage.getItem("ConveredSelectedItem") +
                        "?x-JuvoTransacaoID=" +
                        sessionStorage.getItem("juvoTransactionID");

                    fetch(url, {
                        headers: new Headers({
                            token: sessionStorage.getItem("token"),
                            chave: sessionStorage.getItem("chave"),
                            idclientecorporativo: sessionStorage.getItem(
                                "idclientecorporativo"
                            ),
                            idtipocarteira: sessionStorage.getItem(
                                "SelectedWaletId"
                            ),
                            Authorization: sessionStorage.getItem("Authorization"),
                            "Content-Type": "application/json",
                            "x-JuvoTransacaoID": sessionStorage.getItem(
                                "juvoTransactionID"
                            ),
                        }),
                        method: "post",
                        body: JSON.stringify({
                            descricaoEvento: sessionStorage.getItem(
                                "ContactDescription"
                            ),
                            descricaoServico: sessionStorage.getItem("descriptionService"),
                            nomeCondutor: sessionStorage.getItem("ContactName"),
                            nomeContato: sessionStorage.getItem("ContactName"),
                            telefoneContato: {
                                ddd: ddd,
                                ddi: "55",
                                numero: number,
                            },
                            detalheAssistencia: {
                                email: sessionStorage.getItem("ContactEmail")
                            },
                            enderecoCompletoOrigem: {
                                bairro: sessionStorage.getItem(
                                    "OriginNeighborhood"
                                ),
                                cidade: sessionStorage.getItem("OriginCity"),
                                complemento: sessionStorage.getItem(
                                    "OriginComplement"
                                ),
                                logradouro: sessionStorage.getItem("OriginStreet"),
                                uf: sessionStorage.getItem("OriginState"),
                                numero: this.numberValidator("Origin"),
                                cep: sessionStorage.getItem("OriginCep"),
                                latitude: this.isWalletAuto()
                                    ? sessionStorage.getItem("OriginLatitude")
                                    : "",
                                longitude: this.isWalletAuto()
                                    ? sessionStorage.getItem("OriginLongitude")
                                    : "",
                                referenciaLocal:
                                    sessionStorage.getItem("OriginReference") ===
                                        null
                                        ? ""
                                        : sessionStorage.getItem("OriginReference"),
                            },
                            dataAgendamento: sessionStorage.getItem(
                                "ContactDateTime"
                            ),
                            idChaveCombo: sessionStorage.getItem("ComboId"),
                            usuarioAbertura: sessionStorage.getItem("clerkName") === null
                            ? ""
                            : sessionStorage.getItem("clerkName"),
                        }),
                    })
                        .then((response) => response.json())
                        .then((response) => {
                            if (response.codigo === 999) {
                                sessionStorage.setItem("GenericError", "False");
                                if (response.descricaoAuxiliar) {
                                    sessionStorage.setItem(
                                        "SpecificError",
                                        response.descricao +
                                        "\n\r" +
                                        (response.descricaoAuxiliar !==
                                            undefined &&
                                            response.descricaoAuxiliar != "-1"
                                            ? response.descricaoAuxiliar
                                            : "")
                                    );
                                    GoogleAnalyticsStatusEvent(
                                        sessionStorage.getItem("SelectedWalet"),
                                        "Abertura da assistência - Erro",
                                        response.descricaoAuxiliar
                                    );
                                } else {
                                    sessionStorage.setItem(
                                        "SpecificError",
                                        response.descricao
                                    );
                                    GoogleAnalyticsStatusEvent(
                                        sessionStorage.getItem("SelectedWalet"),
                                        "Abertura da assistência - Erro",
                                        response.descricao
                                    );
                                }
                                this && this.consultContact();
                            } else {
                                if (response.codigo === 998) {
                                    sessionStorage.setItem(
                                        "IdAssistencia",
                                        response.idAssistencia
                                    );
                                    sessionStorage.setItem(
                                        "SurplusValue",
                                        response.valorExcedente
                                    );
                                } else {
                                    this.generateConcludeAssistData(
                                        response,
                                        response.idAssistencia
                                    );
                                    GoogleAnalyticsStatusEvent(
                                        sessionStorage.getItem("SelectedWalet"),
                                        "Abertura da assistência - Sucesso",
                                        response.descricao
                                    );
                                }
                            }
                            this.clearAddress();
                        })
                        .catch((error) => {
                            GoogleAnalyticsStatusEvent(
                                sessionStorage.getItem("SelectedWalet"),
                                "Abertura da assistência - Erro",
                                "Erro de conexão ao consumo dos serviços JUVO"
                            );
                            this.clearAddress();
                            sessionStorage.setItem("SpecificError", "");
                            sessionStorage.setItem("GenericError", "True");
                            this && this.consultContact();
                        });
                    }
            }
        }
    }

    conclude() {
        var isOmni = sessionStorage.getItem("FlagPreAssist");
        if(isOmni == 'True'){
            this.preAssistFinish();
        }else{
            let str = sessionStorage.getItem("ContactTel");
            let ddd = str.substr(1, 2);
            var number = "";
            if (str.length !== 14) {
                number = str.substr(4, 4) + str.substr(9, 4);
                for (let i = 0; i < number.length; i++) {
                    if (number[i] === "_") {
                        number = number.replace("_", "");
                        i--;
                    }
                }
            } else {
                number = str.substr(4, 5) + str.substr(10, 4);
                for (let i = 0; i < number.length; i++) {
                    if (number[i] === "_") {
                        number = number.replace("_", "");
                        i--;
                    }
                }
            }
            const url =
                process.env.REACT_APP_API_URL_TEMPO +
                "/assistencia/" +
                sessionStorage.getItem("ConveredSelectedItem");

            fetch(url, {
                headers: new Headers({
                    token: sessionStorage.getItem("token"),
                    chave: sessionStorage.getItem("chave"),
                    idclientecorporativo: sessionStorage.getItem(
                        "idclientecorporativo"
                    ),
                    idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                    Authorization: sessionStorage.getItem("Authorization"),
                    "Content-Type": "application/json",
                    "x-JuvoTransacaoID": sessionStorage.getItem(
                        "juvoTransactionID"
                    ),
                }),
                method: "post",
                body: JSON.stringify({
                    descricaoEvento: sessionStorage.getItem("ContactDescription"),
                    descricaoServico: sessionStorage.getItem("descriptionService"),
                    nomeCondutor: sessionStorage.getItem("ContactName"),
                    nomeContato: sessionStorage.getItem("ContactName"),
                    telefoneContato: {
                        ddd: ddd,
                        ddi: "55",
                        numero: number,
                    },
                    detalheAssistencia: {
                        email: sessionStorage.getItem("ContactEmail")
                    },
                    enderecoCompletoOrigem: {
                        bairro:
                            sessionStorage.getItem("OriginNeighborhood") === null
                                ? ""
                                : sessionStorage.getItem("OriginNeighborhood"),
                        cidade:
                            sessionStorage.getItem("OriginCity") === null
                                ? ""
                                : sessionStorage.getItem("OriginCity"),
                        complemento:
                            sessionStorage.getItem("OriginComplement") === null
                                ? ""
                                : sessionStorage.getItem("OriginComplement"),
                        logradouro:
                            sessionStorage.getItem("OriginStreet") === null
                                ? ""
                                : sessionStorage.getItem("OriginStreet"),
                        uf:
                            sessionStorage.getItem("OriginState") === null
                                ? ""
                                : sessionStorage.getItem("OriginState"),
                        numero:
                            sessionStorage.getItem("OriginNumber") === null
                                ? "Vazio"
                                : this.numberValidator("Origin"),
                        cep:
                            sessionStorage.getItem("OriginCep") === null
                                ? ""
                                : sessionStorage.getItem("OriginCep"),
                        latitude: this.isWalletAuto()
                            ? sessionStorage.getItem("OriginLatitude")
                            : "",
                        longitude: this.isWalletAuto()
                            ? sessionStorage.getItem("OriginLongitude")
                            : "",
                        referenciaLocal:
                            sessionStorage.getItem("OriginReference") === null
                                ? ""
                                : sessionStorage.getItem("OriginReference"),
                    },
                    enderecoCompletoDestino: {
                        bairro: sessionStorage.getItem("DestinyNeighborhood"),
                        cidade: sessionStorage.getItem("DestinyCity"),
                        complemento: sessionStorage.getItem("DestinyComplement"),
                        logradouro: sessionStorage.getItem("DestinyStreet"),
                        uf: sessionStorage.getItem("DestinyState"),
                        numero: this.numberValidator("Destiny"),
                        cep: sessionStorage.getItem("DestinyCep"),
                        latitude: this.isWalletAuto()
                            ? sessionStorage.getItem("DestinyLatitude")
                            : "",
                        longitude: this.isWalletAuto()
                            ? sessionStorage.getItem("DestinyLongitude")
                            : "",
                        referenciaLocal:
                            sessionStorage.getItem("DestinyReference") === null
                                ? ""
                                : sessionStorage.getItem("DestinyReference"),
                    },
                    dataAgendamento: sessionStorage.getItem("ContactDateTime"),
                    idChaveCombo: sessionStorage.getItem("ComboId"),
                    usuarioAbertura: sessionStorage.getItem("clerkName") === null
                    ? ""
                    : sessionStorage.getItem("clerkName"),
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.codigo === 999) {
                        sessionStorage.setItem("GenericError", "False");
                        sessionStorage.setItem("SpecificError", response.descricao);
                        this && this.consultContact();
                        GoogleAnalyticsStatusEvent(
                            sessionStorage.getItem("SelectedWalet"),
                            "Abertura da assistência - Erro",
                            response.descricao
                        );
                    } else {
                        if (response.codigo === 998) {
                            sessionStorage.setItem(
                                "IdAssistencia",
                                response.idAssistencia
                            );
                            sessionStorage.setItem(
                                "senhaValidacao",
                                response.senhaValidacao
                            );
                            sessionStorage.setItem(
                                "SurplusValue",
                                response.valorExcedente
                            );
                            this.props.history.push(`/valor-excedente`);
                            GoogleAnalyticsStatusEvent(
                                sessionStorage.getItem("SelectedWalet"),
                                "Abertura da assistência - Erro",
                                response.descricao
                            );
                        } else {
                            this.generateConcludeAssistData(
                                response,
                                response.idAssistencia
                            );
                            GoogleAnalyticsStatusEvent(
                                sessionStorage.getItem("SelectedWalet"),
                                "Abertura da assistência - Sucesso",
                                response.descricao
                            );
                        }
                    }
                    this.clearAddress();
                })
                .catch((error) => {
                    GoogleAnalyticsStatusEvent(
                        sessionStorage.getItem("SelectedWalet"),
                        "Abertura da assistência - Erro",
                        "Erro de conexão ao consumo dos serviços JUVO"
                    );
                    this.clearAddress();
                    console.log(error);
                    sessionStorage.setItem("SpecificError", "");
                    sessionStorage.setItem("GenericError", "True");
                    this && this.consultContact();
                });
        }
    }

    numberValidator(type) {
        if (
            sessionStorage.getItem(type + "Number") === "" &&
            sessionStorage.getItem(type + "Latitude") !== "" &&
            sessionStorage.getItem(type + "Longitude") !== ""
        ) {
            return "Vazio";
        } else {
            return sessionStorage.getItem(type + "Number");
        }
    }

    isWalletAuto() {
        let SelectedWaletId = sessionStorage.getItem("SelectedWaletId");
        if (SelectedWaletId === "1") {
            return true;
        } else {
            return false;
        }
    }

    clearAddress() {
        sessionStorage.removeItem("OriginNeighborhood");
        sessionStorage.removeItem("OriginCity");
        sessionStorage.removeItem("OriginComplement");
        sessionStorage.removeItem("OriginStreet");
        sessionStorage.removeItem("OriginState");
        sessionStorage.removeItem("OriginNumber");
        sessionStorage.removeItem("OriginCep");
        sessionStorage.removeItem("OriginLatitude");
        sessionStorage.removeItem("OriginLongitude");

        sessionStorage.removeItem("DestinyNeighborhood");
        sessionStorage.removeItem("DestinyCity");
        sessionStorage.removeItem("DestinyComplement");
        sessionStorage.removeItem("DestinyStreet");
        sessionStorage.removeItem("DestinyState");
        sessionStorage.removeItem("DestinyNumber");
        sessionStorage.removeItem("DestinyCep");
        sessionStorage.removeItem("DestinyLatitude");
        sessionStorage.removeItem("DestinyLongitude");
    }

    generateConcludeAssistData(response, idAssistencia) {
        const url =
            process.env.REACT_APP_API_URL_JUVO +
            "/assistenciaServico/segurado?idItemCoberto=" +
            sessionStorage.getItem("ConveredSelectedItem") +
            "&notInStatus=3&notInStatus=4&notInStatus=5&notInStatus=6";

        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
            }),
        })
            .then((response) => {
                try {
                    return response.json();
                } catch (error) {
                    return Promise.resolve({});
                }
            })
            .then((response) => {
                if (response.length !== 0) {
                    let cont = 0;
                    for (let k in response) {
                        if (response[k].idAssistencia === idAssistencia) {
                            if (response[k].servicos[0].urlFotosVideos) {
                                const splittedUrl = response[k].servicos[0].urlFotosVideos.split(" ");
                                const lastSplittedWord = splittedUrl[splittedUrl.length - 1];

                                sessionStorage.setItem(
                                    "urlAttachmentsFromCreated",
                                    lastSplittedWord
                                );
                            }
                            sessionStorage.setItem(
                                "ConcludeAssitParams",
                                "TRUE"
                            );
                            sessionStorage.setItem(
                                "DataOpen",
                                response[k].servicos[0].dataPrevisaoChegadaStr
                            );
                            sessionStorage.setItem(
                                "IdAssistencia",
                                response[k].idAssistencia
                            );
                            
                            if(response[k].servicos[0].protocoloCliente != 'undefined'){
                                sessionStorage.setItem(
                                    "protocol",
                                    response[k].servicos[0].protocoloCliente
                                );
                            }

                            sessionStorage.setItem(
                                "senhaValidacao",
                                response[k].senhaValidacao
                            );
                            sessionStorage.setItem(
                                "ContactType" + k,
                                response[k].servicos[0].emergencial == 'F' ? 'Agendado' : 'Imediato'
                            );
                    
                            sessionStorage.setItem(
                                "DateRange" + k,
                                response[k].servicos[0].emergencial == 'F' ?  response[k].servicos[0].dataPrevisaoInicialStr + ' - ' +  response[k].servicos[0].dataPrevisaoFinalStr : response[k].servicos[0].dataPrevisaoChegadaStr
                            );

                            if(response[k].servicos[0].dataAgendamento != undefined && sessionStorage.getItem("SelectedWalet") == 'Automóvel'){
                                let timestamp = response[k].servicos[0].dataAgendamento;
                                let dateScheduling = new Date(timestamp);
                                dateScheduling = dateScheduling.toLocaleDateString('pt-BR') + ' ' + dateScheduling.toLocaleTimeString('pt-BR', {hour: '2-digit', minute:'2-digit'});

                                let timestampEnd = (response[k].servicos[0].dataAgendamento + (sessionStorage.getItem("idclientecorporativo") === '542' ? 7200000 : 10800000));
                                let dateSchedulingEnd = new Date(timestampEnd);
                                dateSchedulingEnd = dateSchedulingEnd.toLocaleDateString('pt-BR') + ' ' + dateSchedulingEnd.toLocaleTimeString('pt-BR', {hour: '2-digit', minute:'2-digit'});

                                sessionStorage.setItem(
                                    "DateRange" + k,
                                    dateScheduling + ' - ' + dateSchedulingEnd
                                );
                            }

                            this.props.history.push(`/concluir-assistencia`);
                            cont = 1;
                        }
                    }
                    if (cont === 0) {
                        sessionStorage.setItem("ConcludeAssitParams", "FALSE");
                        sessionStorage.setItem("DataOpen", "-");
                        sessionStorage.setItem("IdAssistencia", "-");
                        sessionStorage.setItem("senhaValidacao", null);
                        this.props.history.push(`/concluir-assistencia`);
                        cont++;
                    }
                } else {
                    console.log("");
                    sessionStorage.setItem("ConcludeAssitParams", "FALSE");
                    sessionStorage.setItem("DataOpen", "-");
                    sessionStorage.setItem("IdAssistencia", "-");
                    sessionStorage.setItem("senhaValidacao", null);
                    this.props.history.push(`/concluir-assistencia`);
                }
            })
            .catch((error) => {
                console.log(error);
                sessionStorage.setItem("ConcludeAssitParams", "FALSE");
                sessionStorage.setItem("DataOpen", "-");
                sessionStorage.setItem("IdAssistencia", "-");
                sessionStorage.setItem("senhaValidacao", null);
                this.props.history.push(`/concluir-assistencia`);
            });
    }

    consultContact() {
        const Token = sessionStorage.getItem("Token");
        const url =
            process.env.REACT_APP_API_URL_ESSENCIA +
            "/cliente-corporativo/contatos";
        fetch(url, {
            headers: new Headers({
                Authorization: "Bearer " + Token,
                "Content-Type": "application/json",
            }),
            method: "post",
            body: JSON.stringify({
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.codigo === 999) {
                    sessionStorage.setItem(
                        "ContactDescription",
                        JSON.stringify(response)
                    );
                    sessionStorage.setItem("GenericError", "False");
                    sessionStorage.setItem("ContactError", "True");
                    if (response.descricao === "Cliente corporativo inv�lido") {
                        sessionStorage.setItem(
                            "SpecificError",
                            "Cliente corporativo inválido."
                        );
                    } else if (
                        response.descricao === "Tipo Carteira n�o informado"
                    ) {
                        sessionStorage.setItem(
                            "SpecificError",
                            "Tipo Carteira não informado"
                        );
                    } else {
                        sessionStorage.setItem(
                            "SpecificError",
                            response.descricao
                        );
                    }
                    this.props.history.push(`/contatos`);
                } else {
                    sessionStorage.setItem(
                        "ContactDescription",
                        JSON.stringify(response)
                    );
                    sessionStorage.setItem("GenericError", "False");
                    sessionStorage.setItem("ContactError", "False");
                    this.props.history.push(`/contatos`);
                }
            })
            .catch((error) => {
                console.log("3");
                sessionStorage.setItem("ContactDescription", "[]");
                sessionStorage.setItem("GenericError", "False");
                sessionStorage.setItem("ContactError", "True");
                this.props.history.push(`/contatos`);
            });
    }

    Accompaniment() {
        const ConveredSelectedItem = sessionStorage.getItem(
            "ConveredSelectedItem"
        );
        const url =
            process.env.REACT_APP_API_URL_JUVO +
            "/assistenciaServico/segurado?idItemCoberto=" +
            ConveredSelectedItem +
            "&notInStatus=2&notInStatus=3&notInStatus=4&notInStatus=5&notInStatus=6";

        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
            }),
        })
            .then((response) => {
                if (response.headers.get('content-type').match(/application\/json/)) {
                  return response.json();
                }
                return response;
               })
            .then((response) => {
                this.makeAssistList(response);
            })
            .catch((error) => {
                sessionStorage.setItem("SpecificError", "");
                sessionStorage.setItem("GenericError", "True");
                this && this.consultContact();
            });
    }

    makeAssistList(response) {
        sessionStorage.removeItem("QuestionTitle");
        sessionStorage.removeItem("AtualQuestion");
        let size = sessionStorage.length;
        for (let i = 0; i < size; i++) {
            if (sessionStorage.key(i) != null) {
                if (sessionStorage.key(i).includes("QuestionText")) {
                    sessionStorage.removeItem(sessionStorage.key(i));
                    i = i - 1;
                    size = size - 1;
                }
            }
            if (sessionStorage.key(i) != null) {
                if (sessionStorage.key(i).includes("QuestionId")) {
                    sessionStorage.removeItem(sessionStorage.key(i));
                    i = i - 1;
                    size = size - 1;
                }
            }
            if (sessionStorage.key(i) != null) {
                if (sessionStorage.key(i).includes("NextQuestion")) {
                    sessionStorage.removeItem(sessionStorage.key(i));
                    i = i - 1;
                    size = size - 1;
                }
            }
            if (sessionStorage.key(i) != null) {
                if (
                    sessionStorage.key(i).includes("DescriptionAccompaniment")
                ) {
                    sessionStorage.removeItem(sessionStorage.key(i));
                    i = i - 1;
                    size = size - 1;
                }
            }

            if (sessionStorage.key(i) != null) {
                if (
                    sessionStorage.key(i).includes("protocoloCliente")
                ) {
                    sessionStorage.removeItem(sessionStorage.key(i));
                    i = i - 1;
                    size = size - 1;
                }
            }

            if (sessionStorage.key(i) != null) {
                if (
                    sessionStorage.key(i).includes("senhaValidacao")
                ) {
                    sessionStorage.removeItem(sessionStorage.key(i));
                    i = i - 1;
                    size = size - 1;
                }
            }

            if (sessionStorage.key(i) != null) {
                if (
                    sessionStorage.key(i).includes("ContactType")
                ) {
                    sessionStorage.removeItem(sessionStorage.key(i));
                    i = i - 1;
                    size = size - 1;
                }
            }

            if (sessionStorage.key(i) != null) {
                if (
                    sessionStorage.key(i).includes("DateRange")
                ) {
                    sessionStorage.removeItem(sessionStorage.key(i));
                    i = i - 1;
                    size = size - 1;
                }
            }
        }
        sessionStorage.setItem(
            "QuestionTitle",
            "Qual assistência deseja acompanhar?"
        );
        for (let i = 0; i < response.length; i++) {
            sessionStorage.setItem(
                "QuestionText" + i,
                response[i].idAssistencia
            );
            sessionStorage.setItem(
                "QuestionId" + i,
                response[i].dataAberturaAssistencia
            );
            sessionStorage.setItem(
                "DescriptionAccompaniment" + i,
                response[i].nomeEvento
            );
            sessionStorage.setItem(
                "protocoloCliente" + i,
                response[i].servicos[0].protocoloCliente
            );
            sessionStorage.setItem(
                "senhaValidacao" + i,
                response[i].senhaValidacao
            );

            sessionStorage.setItem(
                "ContactType" + i,
                response[i].servicos[0].emergencial == 'F' ? 'Agendado' : 'Imediato'
            );
    
            sessionStorage.setItem(
                "DateRange" + i,
                response[i].servicos[0].emergencial == 'F' ?  response[i].servicos[0].dataPrevisaoInicialStr + ' - ' +  response[i].servicos[0].dataPrevisaoFinalStr : response[i].servicos[0].dataPrevisaoChegadaStr
            );

            if(response[i].servicos[0].dataAgendamento != undefined && sessionStorage.getItem("SelectedWalet") == 'Automóvel'){
                let timestamp = response[i].servicos[0].dataAgendamento;
                let dateScheduling = new Date(timestamp);
                dateScheduling = dateScheduling.toLocaleDateString('pt-BR') + ' ' + dateScheduling.toLocaleTimeString('pt-BR', {hour: '2-digit', minute:'2-digit'});
    
                let timestampEnd = (response[i].servicos[0].dataAgendamento + (sessionStorage.getItem("idclientecorporativo") === '542' ? 7200000 : 10800000));
                let dateSchedulingEnd = new Date(timestampEnd);
                dateSchedulingEnd = dateSchedulingEnd.toLocaleDateString('pt-BR') + ' ' + dateSchedulingEnd.toLocaleTimeString('pt-BR', {hour: '2-digit', minute:'2-digit'});
                
                sessionStorage.setItem(
                    "DateRange" + i,
                    dateScheduling + ' - ' + dateSchedulingEnd
                );
            }
        }
        this.props.history.push(`/acompanhamentos`);
    }

    AssistDetail() {
        const ConveredSelectedItem = sessionStorage.getItem(
            "ConveredSelectedItem"
        );
        const url =
            process.env.REACT_APP_API_URL_JUVO +
            "/assistenciaServico/segurado?idItemCoberto=" +
            ConveredSelectedItem +
            "&notInStatus=3&notInStatus=4&notInStatus=5&notInStatus=6";

        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                for (let i = 0; i < response.length; i++) {
                    if (
                        sessionStorage.getItem("IdAnswer") ===
                        response[i].idAssistencia + ""
                    ) {
                        if (response[i].servicos[0].urlFotosVideos) {
                            const splittedUrl = response[i].servicos[0].urlFotosVideos.split(" ");
                            const lastSplittedWord = splittedUrl[splittedUrl.length - 1];

                            sessionStorage.setItem(
                                "urlAttachments",
                                lastSplittedWord
                            );
                        } else {
                            if (sessionStorage.getItem("urlAttachments")) {
                                sessionStorage.removeItem("urlAttachments")
                            }
                        };

                        sessionStorage.setItem(
                            "idTipoCarteira",
                            response[i].idTipoCarteira
                        );
                        sessionStorage.setItem(
                            "IdAssistencia",
                            response[i].servicos[0].idAssistServico
                        );
                        sessionStorage.setItem(
                            "HourChoose",
                            response[i].servicos[0].dataPrevisaoChegada
                        );
                        sessionStorage.setItem(
                            "NameChoose",
                            response[i].servicos[0].nomeCobertura
                        );

                        if(response[i].servicos[0].protocoloCliente != undefined){
                            sessionStorage.setItem(
                                "protocol",
                                response[i].servicos[0].protocoloCliente
                            );
                        }
                        
                        sessionStorage.setItem(
                            "SubNameChoose",
                            response[i].servicos[0].nome
                        );
                        sessionStorage.setItem(
                            "StateChoose",
                            response[i].servicos[0].tipoStatusServico.descricao
                        );
                        // Montagem endereços
                        let destinyStreet =
                            response[i].servicos[0].enderecoDestino
                                .logradouro === undefined
                                ? ""
                                : response[i].servicos[0].enderecoDestino
                                    .logradouro;
                        let destinyNumber =
                            response[i].servicos[0].enderecoDestino.numero ===
                                undefined
                                ? ""
                                : response[i].servicos[0].enderecoDestino
                                    .numero;
                        let destinyNeighborhood =
                            response[i].servicos[0].enderecoDestino.bairro ===
                                undefined
                                ? ""
                                : response[i].servicos[0].enderecoDestino
                                    .bairro;
                        let destinyCity =
                            response[i].servicos[0].enderecoDestino.cidade ===
                                undefined
                                ? ""
                                : response[i].servicos[0].enderecoDestino
                                    .cidade;
                        let destinyState =
                            response[i].servicos[0].enderecoDestino.uf ===
                                undefined
                                ? ""
                                : response[i].servicos[0].enderecoDestino.uf;
                        let destinyAddress =
                            destinyStreet +
                            " " +
                            destinyNumber +
                            " " +
                            destinyNeighborhood +
                            " " +
                            destinyCity +
                            " " +
                            destinyState;
                        sessionStorage.setItem(
                            "DestinyAddress",
                            destinyAddress.length === 4 ? "" : destinyAddress
                        );

                        let localStreet =
                            response[i].servicos[0].enderecoLocal.logradouro ===
                                undefined
                                ? ""
                                : response[i].servicos[0].enderecoLocal
                                    .logradouro;
                        let localNumber =
                            response[i].servicos[0].enderecoLocal.numero ===
                                undefined
                                ? ""
                                : response[i].servicos[0].enderecoLocal.numero;
                        let localNeighborhood =
                            response[i].servicos[0].enderecoLocal.bairro ===
                                undefined
                                ? ""
                                : response[i].servicos[0].enderecoLocal.bairro;
                        let localCity =
                            response[i].servicos[0].enderecoLocal.cidade ===
                                undefined
                                ? ""
                                : response[i].servicos[0].enderecoLocal.cidade;
                        let localState =
                            response[i].servicos[0].enderecoLocal.uf ===
                                undefined
                                ? ""
                                : response[i].servicos[0].enderecoLocal.uf;
                        let localAddress =
                            localStreet +
                            " " +
                            localNumber +
                            " " +
                            localNeighborhood +
                            " " +
                            localCity +
                            " " +
                            localState;
                        sessionStorage.setItem(
                            "LocalAddress",
                            localAddress.length === 4 ? "" : localAddress
                        );
                        sessionStorage.setItem("AssistCancel", "True");
                        if (response[i].servicos[0].prestador !== undefined) {
                            this.searchProvider(response[i]);
                        } else {
                            if (response[i].servicos[0].placaValidada !== "V" || response[i].servicos[0].statusServico[0].idStatusServico !== 3) {
                                sessionStorage.setItem(
                                    "AssistRescheduling",
                                    "True"
                                );
                            } else {
                                sessionStorage.setItem(
                                    "AssistRescheduling",
                                    "False"
                                );
                            }
                            this.props.history.push(`/detalhe-acompanhamento`);
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                sessionStorage.setItem("SpecificError", "");
                sessionStorage.setItem("GenericError", "True");
                this && this.consultContact();
            });
    }

    searchProvider(response) {
        if (response.servicos[0].prestador === undefined) {
            sessionStorage.setItem("AssistMaps", "False");
            this.props.history.push(`/detalhe-acompanhamento`);
        } else {
            sessionStorage.setItem("AssistMaps", "True");
            const idAssistServico = response.servicos[0].idAssistServico;
            sessionStorage.setItem(
                "IdAssistServico",
                response.servicos[0].idAssistServico
            );
            const url =
                process.env.REACT_APP_API_URL_TEMPO +
                "/assistenciaServico/ultimaPosicaoPrestador/" +
                idAssistServico;

            fetch(url, {
                headers: new Headers({
                    token: sessionStorage.getItem("token"),
                    chave: sessionStorage.getItem("chave"),
                    idclientecorporativo: sessionStorage.getItem(
                        "idclientecorporativo"
                    ),
                    idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                    Authorization: sessionStorage.getItem("Authorization"),
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.codigo === 999) {
                        sessionStorage.setItem("AssistMaps", "False");
                        this.props.history.push(`/detalhe-acompanhamento`);
                    } else {
                        sessionStorage.setItem("AssistMaps", "True");
                        sessionStorage.setItem(
                            "GoogleDestinyLon",
                            response.ultimaPosicaoPrestador.longitude
                        );
                        sessionStorage.setItem(
                            "GoogleDestinyLat",
                            response.ultimaPosicaoPrestador.latitude
                        );
                        this.props.history.push(`/detalhe-acompanhamento`);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    Cancel() {
        const ConveredSelectedItem = sessionStorage.getItem(
            "ConveredSelectedItem"
        );
        const url =
            process.env.REACT_APP_API_URL_JUVO +
            "/assistenciaServico/segurado?idItemCoberto=" +
            ConveredSelectedItem +
            "&notInStatus=3&notInStatus=4&notInStatus=5&notInStatus=6";

        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                for (let i = 0; i < response.length; i++) {
                    if (
                        sessionStorage.getItem("IdAnswer") ===
                        response[i].idAssistencia + ""
                    ) {
                        this.requestCancel(
                            response[i].idAssistencia,
                            response[i].servicos[0].idAssistServico
                        );
                    }
                }
            });
    }

    requestCancel(idAssistencia, idAssistServico) {
        const url =
            process.env.REACT_APP_API_URL_TEMPO +
            "/assistenciaServico/cancelar";

        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
                Accept: "application/json",
                "Content-Type": "application/json",
            }),
            method: "delete",
            body: JSON.stringify({
                idAssistServico: idAssistServico,
                idAssistencia: idAssistencia,
                idMotivo: 2,
                userLogin: "PUSH.ESSENCIA",
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);
                if (response.codigo === 0) {
                    let BackPage = JSON.parse(
                        sessionStorage.getItem("BackPage")
                    );
                    BackPage.pop();
                    BackPage.pop();
                    console.log(BackPage);
                    sessionStorage.setItem(
                        "BackPage",
                        JSON.stringify(BackPage)
                    );
                    console.log(response);
                    sessionStorage.setItem("FeedbackAssistCancel", "true");
                    this.props.history.push("detalhe-acompanhamento");
                } else {
                    sessionStorage.setItem("FeedbackAssistCancel", "false");
                    sessionStorage.setItem("FeedbackAssistCancelMessage", response.descricao);
                    this.props.history.push("detalhe-acompanhamento");
                }
            })
            .catch((error) => {
                sessionStorage.setItem("FeedbackAssistCancel", "false");
                this.props.history.push("detalhe-acompanhamento");
            });
    }

    Logout() {
        let originalURL = window.location.href;
        let alteredURL = this.removeParam("idItem", originalURL);
        alteredURL = this.removeParam("idCarteira", alteredURL);
        alteredURL = this.removeParam("parametro", alteredURL);
        sessionStorage.clear();
        document.getElementById("sidebar-btn-logout").style.display = "none";
        document.getElementById("sidebar-btn-home").style.display = "none";
        document.getElementById("sidebar-btn-acompanhamento").style.display =
            "none";
        window.location.href = alteredURL;
    }

    removeParam(key, sourceURL) {
        var rtn = sourceURL.split("?")[0],
            param,
            params_arr = [],
            queryString =
                sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
        if (queryString !== "") {
            params_arr = queryString.split("&");
            for (var i = params_arr.length - 1; i >= 0; i -= 1) {
                param = params_arr[i].split("=")[0];
                if (param === key) {
                    params_arr.splice(i, 1);
                }
            }
            rtn = rtn + "?" + params_arr.join("&");
        }
        return rtn;
    }

    FAQ() {
        const Token = sessionStorage.getItem("Token");
        const url = process.env.REACT_APP_API_URL_ESSENCIA + "/faq";
        fetch(url, {
            headers: new Headers({
                Authorization: "Bearer " + Token,
                "Content-Type": "application/json",
            }),
            method: "post",
            body: JSON.stringify({
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                let size = sessionStorage.length;
                for (let i = 0; i < size; i++) {
                    if (sessionStorage.key(i).includes("FaqQuestion")) {
                        sessionStorage.removeItem(sessionStorage.key(i));
                        i = i - 1;
                        size = size - 1;
                    }
                    if (sessionStorage.key(i).includes("FaqAnswer")) {
                        sessionStorage.removeItem(sessionStorage.key(i));
                        i = i - 1;
                        size = size - 1;
                    }
                }
                if (response.message === undefined) {
                    for (
                        var i = 0;
                        i < Object.keys(response.faqs).length;
                        i++
                    ) {
                        let contador = Object.keys(response.faqs)[i];
                        sessionStorage.setItem(
                            "FaqQuestion" + i,
                            response.faqs[contador].pergunta
                        );
                        sessionStorage.setItem(
                            "FaqAnswer" + i,
                            response.faqs[contador].resposta
                        );
                    }
                } else {
                    sessionStorage.setItem("NoFaqReturn", response.message);
                }

                this.props.history.push(`/faq`);
            })
            .catch((error) => {
                sessionStorage.setItem("SpecificError", "");
                sessionStorage.setItem("GenericError", "True");
                this && this.consultContact();
            });
    }

    Rescheduling() {
        const url =
            process.env.REACT_APP_API_URL_JUVO +
            "/1.0.2/assistenciaServico/update/agendamento";

        const idclientecorporativo = sessionStorage.getItem(
            "idclientecorporativo"
        );

        const endTime = idclientecorporativo === '542' ? 7200000 : 10800000;

        let body = {};
        if(sessionStorage.getItem("SelectedWalet") == 'Automóvel'){
            body = {
                idAssistServico: sessionStorage.getItem("IdAssistencia") + "",
                dataAgendamento: sessionStorage.getItem("ReschedulingDate"),
            };
        }else{
            body = {
                idAssistServico: sessionStorage.getItem("IdAssistencia") + "",
                dataAgendamentoFim:
                    String(Number(sessionStorage.getItem("ReschedulingDate")) + endTime),
                dataAgendamentoInicio:
                    sessionStorage.getItem("ReschedulingDate"),
            };
        }

        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo,
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
                "Content-Type": "application/json",
            }),
            method: "post",
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.codigo === 999) {
                    sessionStorage.setItem("GenericError", "False");
                    sessionStorage.setItem("SpecificError", response.descricao);
                    this && this.consultContact();
                } else {
                    this.props.history.push(`/concluir-reagendamento`);
                }
            });
    }
}

export default Controller;
