import React , {Component} from 'react';
import CSSTransition from "react-transition-group/esm/CSSTransition";
import {NavLink} from "react-router-dom";
import {backPage, topFunction} from "../../App";

class NoAssist extends Component {
    constructor(props) {
        super(props);
        props.keep();
        backPage(this);
    }
    render() {
        return(
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div>
                    <div className="content-header">
                        <div className="persona-header" >
                            <div className="persona-header__content">
                                <h3 className="persona-header__title">Contatos</h3>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="question">
                            <div>
                                <h2 className="contacts">Você não possui assistencias abertas, deseja fazer alguma solicitação?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="container__div__footer">
                        <NavLink onClick={function(event){topFunction()}} to="/" className="waves-effect waves-light btn-large btn-success">
                            <i className="fa fa-lg fa-home margin__button-big"/>  Fazer solicitação
                        </NavLink>
                    </div>
                </div>
            </CSSTransition>
        )
    }
}

export default NoAssist;