import React, { Component } from "react";
import { backPage, topFunction } from "../../App";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import { NavLink } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import { Accompaniment } from "../controller/ControllerFunctions";
registerLocale("pt", pt);

var numbers = ["Question1"];

class AssistDetail extends Component {
    constructor(props) {
        super(props);
        
        let date = new Date(sessionStorage.getItem("HourChoose") / 1);
        let feedbackAssistCancel = sessionStorage.getItem("FeedbackAssistCancel");
        date = date.toLocaleString();
        let name = sessionStorage.getItem("NameChoose");
        let subName = sessionStorage.getItem("SubNameChoose");
        let stateChoose = sessionStorage.getItem("StateChoose");
        let destinyAddress = sessionStorage.getItem("DestinyAddress");
        let localAddress = sessionStorage.getItem("LocalAddress");
        let idTipoCarteira = sessionStorage.getItem("idTipoCarteira");
        let mapsAvailable = sessionStorage.getItem("AssistMaps");
        let scheduledTime = sessionStorage.getItem("ScheduledTime");
        let flagCancelAssist = sessionStorage.getItem("FlagCancelAssist");
        let flagRescheduleAssist = sessionStorage.getItem("FlagRescheduleAssist");
        let flagProtocol = sessionStorage.getItem("FlagProtocolCli");
        let protocol = sessionStorage.getItem("protocol");
        let flagTrackProviderArrival = sessionStorage.getItem("FlagTrackProviderArrival");
        let flagAttachments = sessionStorage.getItem("FlagAttachments");
        let urlAttachments = sessionStorage.getItem("urlAttachments");
        let senha = sessionStorage.getItem("senhaValidacao");

        this.state = {
            mapsAvailable,
            text: process.env.REACT_APP_PUSH_TEMPO_FRAME + "MapDirections.html",
            modalShow: (feedbackAssistCancel != undefined && feedbackAssistCancel != '' ? true : false),
            date: date,
            type: feedbackAssistCancel !== undefined && feedbackAssistCancel !== '' ? (feedbackAssistCancel === 'true' ? 'feedback-success' : 'feedback-error') : "cancel",
            name,
            subName,
            senha,
            stateChoose,
            destinyAddress,
            localAddress,
            idTipoCarteira,
            scheduledTime,
            flagCancelAssist,
            flagRescheduleAssist,
            flagTrackProviderArrival,
            flagAttachments,
            urlAttachments,
            flagProtocol,
            protocol
        };

        sessionStorage.removeItem("FeedbackAssistCancel");
        props.keep();
        backPage(this);

    }

    render() {
        let modalClose = () => this.setState({ modalShow: false });
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div>
                    {numbers.map((number, cont) => (
                        <div key={number.toString()}>
                            <div className="content-header">
                                <div className="persona-header">
                                    <div className="persona-header__content">
                                        <h3 className="persona-header__title">
                                            {this.state.flagProtocol === 'True' && this.state.protocol != '' && this.state.protocol != null && this.state.protocol != 'undefined' ? this.state.protocol : sessionStorage.getItem("IdAnswer")}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="spacer" />
                            <div className={"conclude-assist-container"}>
                                <span
                                    className={
                                        "question-color-text conclude-alert-label"
                                    }
                                >
                                    {this.state.name}
                                </span>
                                <div className={"divisor-line"} />
                                <span
                                    className={
                                        "question-color-text conclude-label"
                                    }
                                >
                                    {this.state.subName}
                                </span>
                            </div>
                            <div className="spacer" />
                            <div
                                className={"conclude-assist-container"}
                                style={
                                    this.state.date !== "Invalid Date"
                                        ? { display: "grid" }
                                        : { display: "none" }
                                }
                            >
                                {this.state.flagTrackProviderArrival === "True" && (
                                    <span
                                        onClick={this.opemMap.bind(this)}
                                        className={"btn-success link-assist"}
                                        style={
                                            sessionStorage.getItem(
                                                "PlacaValidada"
                                            ) === "V"
                                                || sessionStorage.getItem(
                                                    "IdStatusServico"
                                                ) === "3"
                                                || sessionStorage.getItem(
                                                    "AssistMaps"
                                                ) === "False"
                                                ? { display: "none" }
                                                : { display: "table" }
                                        }
                                    >
                                        <i className="fa fa-lg fa-map-marker container__link--margin" />
                                    Ver localização
                                    </span>
                                )}
                            </div>
                            {sessionStorage.getItem("senhaValidacao") != null &&
                            sessionStorage.getItem("senhaValidacao") != 'undefined' ? (
                                <>
                                    <div className="spacer" />
                                    <div className={"conclude-assist-container"}>
                                        <span style={{ fontSize: 16, fontWeight: 700 }} className={"question-color-text"}>
                                            Esta é a senha de segurança que deverá ser informada ao prestador:
                                        </span>
                                        <span className={"question-color-text"}>
                                            {this.state.senha}
                                        </span>
                                        <div className={"divisor-line"} />
                                    </div>
                                </>
                            ) : null}
                            <div className="spacer" />
                            {sessionStorage.getItem("ContactType") != null &&
                            sessionStorage.getItem("ContactType") != undefined ? (
                                <>
                                    <div className="spacer" />
                                    <div className={"conclude-assist-container"}>
                                        <span style={{ fontSize: 16, fontWeight: 700 }} className={"question-color-text"}>
                                            Tipo de acionamento
                                        </span>
                                        <span className={"question-color-text"}>
                                            {sessionStorage.getItem("ContactType")}
                                        </span>
                                        <div className={"divisor-line"} />
                                    </div>
                                </>
                            ) : null}
                            <div className="spacer" />
                            {sessionStorage.getItem("DateRange") != null &&
                            sessionStorage.getItem("DateRange") != undefined ? (
                                <>
                                    <div className="spacer" />
                                    <div className={"conclude-assist-container"}>
                                        <span style={{ fontSize: 16, fontWeight: 700 }} className={"question-color-text"}>
                                            Previsão de Chegada
                                        </span>
                                        <span className={"question-color-text"}>
                                            {sessionStorage.getItem("DateRange")}
                                        </span>
                                        <div className={"divisor-line"} />
                                    </div>
                                </>
                            ) : null}
                            <div className="spacer" />
                            <div className={"conclude-assist-container"}>
                                <span
                                    className={
                                        "question-color-text conclude-label"
                                    }
                                >
                                    Endereço Origem:{" "}
                                </span>
                                <div className={"divisor-line"} />
                                <span
                                    className={
                                        "question-color-text conclude-label"
                                    }
                                >
                                    {this.state.localAddress}
                                </span>
                            </div>
                            <div className="spacer" />
                            <div
                                className={"conclude-assist-container"}
                                style={
                                    sessionStorage.getItem("DestinyAddress")
                                        .length !== 0
                                        ? { display: "grid" }
                                        : { display: "none" }
                                }
                            >
                                <span
                                    className={
                                        "question-color-text conclude-label"
                                    }
                                >
                                    Endereço Destino:{" "}
                                </span>
                                <div className={"divisor-line"} />
                                <span
                                    className={
                                        "question-color-text conclude-label"
                                    }
                                >
                                    {this.state.destinyAddress}
                                </span>
                            </div>
                            <div className="spacer" />
                            <div
                                className={"conclude-assist-container"}
                                style={
                                    this.state.idTipoCarteira === "2" &&
                                        sessionStorage.getItem("ClientPhoneNumber") !== 'undefined'
                                        ? { display: "grid" }
                                        : { display: "none" }
                                }
                            >
                            </div>
                            <div className="spacer" />
                            {/* <div
                                className={'conclude-assist-container margin--botton__35'}
                                 style={ sessionStorage.getItem("LocalAddress").length !== 0 ? { display : "grid"} : { display : "none"} }
                            >
                                <span className={'conclude-label'}>Status: </span>
                                <div className={'divisor-line'} />
                                <span className={'conclude-label'}>{this.state.stateChoose}</span>
                            </div> */}
                            <div className="container-assist-detail">
                                <div className="question">
                                    <div
                                        style={
                                            sessionStorage.getItem(
                                                "PlacaValidada"
                                            ) !== "V" &&
                                                sessionStorage.getItem(
                                                    "AssistCancel"
                                                ) !== "True"
                                                ? { display: "none" }
                                                : { display: "flex", justifyContent: "space-between", flexWrap: "wrap" }
                                        }
                                    >
                                        {(this.state.flagRescheduleAssist === "True" && sessionStorage.getItem("ContactType") == 'Agendado') && (
                                            <div
                                                style={
                                                    sessionStorage.getItem(
                                                        "PlacaValidada"
                                                    ) === "V"
                                                        || sessionStorage.getItem(
                                                            "IdStatusServico"
                                                        ) === "3"
                                                        || sessionStorage.getItem("ScheduledTime") === 'undefined'
                                                        || sessionStorage.getItem("AssistRescheduling") === "False"
                                                        ? { display: "none" }
                                                        : { display: "block" }
                                                }
                                            >
                                                <div
                                                    onClick={this.chooseChoice.bind(
                                                        this,
                                                        0
                                                    )}
                                                    className={"link-assist-reschedule btn-large"}
                                                >
                                                    REAGENDAR
                                            </div>
                                            </div>
                                        )}
                                        {this.state.flagAttachments === "True" && this.state.urlAttachments !== null && (
                                            <div
                                                style={
                                                    sessionStorage.getItem(
                                                        "PlacaValidada"
                                                    ) !== "V"
                                                        ? { display: "block" }
                                                        : { display: "none" }
                                                }
                                            >
                                                <div
                                                    onClick={this.chooseChoice.bind(
                                                        this,
                                                        2
                                                    )}
                                                    className={"link-assist-reschedule btn-large"}
                                                >
                                                    Anexar fotos e vídeos
                                            </div>
                                            </div>
                                        )}
                                        {this.state.flagCancelAssist === "True" && (
                                            <div
                                                style={
                                                    sessionStorage.getItem(
                                                        "PlacaValidada"
                                                    ) !== "V" &&
                                                        sessionStorage.getItem(
                                                            "AssistCancel"
                                                        ) === "True"
                                                        ? { display: "block" }
                                                        : { display: "none" }
                                                }
                                            >
                                                <div
                                                    onClick={this.chooseChoice.bind(
                                                        this,
                                                        1
                                                    )}
                                                    className={"link-assist btn-large"}
                                                >
                                                    CANCELAR
                                            </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                    ))}
                    <MyVerticallyCenteredModal
                        show={this.state.modalShow}
                        onHide={modalClose}
                        type={this.state.type}
                    />
                </div>
            </CSSTransition >
        );
    }

    opemMap() {
        const idAssistServico = sessionStorage.getItem(
            "IdAssistServico"
        );

        const baseUrl = process.env.REACT_APP_MAPS_URL_TEMPO;
        const url =  baseUrl + idAssistServico;
        window.open(url, '_blank', 'noopener,noreferrer');
        // this.props.history.push("/mapa-servico");
    }

    chooseChoice(id) {
        if (id === 0) {
            topFunction();
            /* this.setState({
                modalShow: true,
                type: "rescheduling",
            }); */
            this.props.history.push("/reagendamento");
        } else if (id === 2) {
            topFunction();
            const url = sessionStorage.getItem("urlAttachments");
            window.open(url, "_self");
        } else {
            topFunction();
            this.setState({
                modalShow: true,
                type: "cancel",
            });
        }
    }
}

class MyVerticallyCenteredModal extends React.Component {
    constructor(props) {
        super(props);

        var dt = new Date();
        dt.setHours(dt.getHours() + 2);

        this.state = {
            idclientecorporativo: sessionStorage.getItem("idclientecorporativo"),
            date: new Date(),
            minDate: dt,
            startDate: new Date().getTime() + 60000 * 180,
            teste: 0,
        };
    }

    componentDidMount() {
        let BackPage = JSON.parse(sessionStorage.getItem("BackPage"));
        if (BackPage[BackPage.length - 2] === "/acompanhamentos") {
            sessionStorage.setItem("Action", "Accompaniment");
        }
    }

    handleChange = (date) => {
        sessionStorage.setItem('ReschedulingDate', date.getTime());

        var today = new Date();
        const diffTime = date.getTime() - today.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60));
        if (diffDays <= 180) {
            document.getElementById("warning-low-time").style.display = "block";
            this.setState({
                startDate: today.getTime() + 60000 * 180,
            });
        } else {
            document.getElementById("warning-low-time").style.display = "none";
            this.setState({
                startDate: date,
            });
        }
    };

    render() {
        return (
            <div>
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id={"modalRescheduling"}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            AVISO
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p
                            style={
                                this.props.type === "cancel"
                                    ? { display: "block" }
                                    : { display: "none" }
                            }
                        >
                            Tem certeza que deseja cancelar a assistência?
                        </p>
                        <p
                            style={
                                this.props.type === "rescheduling"
                                    ? { display: "block" }
                                    : { display: "none" }
                            }
                        >
                            Para reagendar seu atendimento, basta escolher a nova data e horário:
                        </p>
                        <p
                            style={
                                this.props.type === "feedback-success"
                                    ? { display: "block" }
                                    : { display: "none" }
                            }
                        >
                            Cancelamento efetuado!
                        </p>
                        <p
                            style={
                                this.props.type === "feedback-error"
                                    ? { display: "block" }
                                    : { display: "none" }
                            }
                        >
                            {
                                sessionStorage.getItem('FeedbackAssistCancelMessage')
                                    ? sessionStorage.getItem('FeedbackAssistCancelMessage')
                                    : 'Ocorreu um erro no cancelamento da assistência'
                            }
                        </p>
                        <div
                            style={
                                this.props.type === "rescheduling"
                                    ? { display: "block" }
                                    : { display: "none" }
                            }
                        >
                            <div className="datebox">
                                <div className="datepicker-wrapper">
                                    <span>Para qual data deseja reagendar?</span>
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.handleChange}
                                        locale="pt"
                                        minDate={this.state.minDate}
                                        dateFormat="dd/MM/yyyy"
                                        withPortal
                                    />
                                </div>

                                <div className="datepicker-wrapper">
                                    <span>Melhor horário</span>
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.handleChange}
                                        locale="pt"
                                        minDate={this.state.minDate}
                                        dateFormat="HH:mm"
                                        withPortal
                                        timeIntervals={15}
                                        showTimeSelect
                                        showTimeSelectOnly
                                    />
                                </div>
                            </div>
                            <div className="datebox">
                                <div className="datepicker-wrapper">
                                    <span>Data e hora do agendamento</span>
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.handleChange}
                                        locale="pt"
                                        minDate={this.state.minDate}
                                        dateFormat="Pp"
                                        withPortal
                                        disabled
                                    />
                                </div>

                                <div className="datepicker-wrapper">
                                    <span>Prazo máximo para chegada até o local</span>
                                    <DatePicker
                                        selected={new Date(this.state.startDate).getTime() + (this.state.idclientecorporativo === '542' ? 7200000 : 10800000)}
                                        onChange={this.handleChange}
                                        locale="pt"
                                        minDate={this.state.minDate}
                                        dateFormat="Pp"
                                        withPortal
                                        disabled
                                    />
                                </div>
                            </div>
                            <small
                                style={{ display: "none" }}
                                id="warning-low-time"
                            >
                                Agendamentos para o mesmo dia devem ter ao menos
                                de 2h:10min de diferença da data da solicitação{" "}
                            </small>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <NavLink
                            style={
                                this.props.type === "feedback-error" || this.props.type === "feedback-success"
                                    ? { display: "none" }
                                    : { display: "block" }
                            }
                            onClick={() => this.handleClick(this.props.type)}
                            to={"/carregando"}
                            className="waves-effect waves-light btn-large btn-success"
                        >
                            <i className="fa fa-lg fa-check container__button--margin " />{" "}
                            Sim
                        </NavLink>
                        <NavLink
                            style={
                                this.props.type === "feedback-error" || this.props.type === "feedback-success"
                                    ? { display: "none" }
                                    : { display: "block" }
                            }
                            onClick={() => this.closeModal(this)}
                            to={"detalhe-acompanhamento"}
                            className="waves-effect waves-light btn-large btn-danger"
                        >
                            <i className="fa fa-lg fa-times container__button--margin " />{" "}
                            Não
                        </NavLink>
                        <NavLink
                            style={
                                this.props.type === "feedback-error" || this.props.type === "feedback-success"
                                    ? { display: "block" }
                                    : { display: "none" }
                            }
                            onClick={() => this.handleClick(this.props.type)}
                            to={"/carregando"}
                            className="waves-effect waves-light btn-large btn-success"
                        >
                            <i className="fa fa-lg fa-times container__button--margin " />{" "}
                            Ok
                        </NavLink>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

    handleClick(type) {
        if (type === "cancel") {
            sessionStorage.setItem("Action", "Cancel");
        } else if (type === "rescheduling") {
            sessionStorage.setItem(
                "ReschedulingDate",
                this.state.date.getTime()
            );
            sessionStorage.setItem("Action", "Rescheduling");
        } else if (type === "feedback-error") {
            sessionStorage.setItem("Action", "Accompaniment");
        } else if (type === "feedback-success") {
            Accompaniment();
            sessionStorage.setItem("Action", "Accompaniment");
        }
        topFunction();
    }

    closeModal(status) {
        status.props.onHide();
        topFunction();
    }
}

export default AssistDetail;
