import React from 'react';
import { NavLink } from 'react-router-dom';
import { topFunction } from '../../../App'
import CSSTransition from "react-transition-group/esm/CSSTransition";

const Others = () => {
    return(
        <CSSTransition
            in={true}
            appear={true}
            timeout={800}
            classNames="fade"
        >
            <div>
                <div className="content-header">
                    <div className="persona-header" >
                        <div className="persona-header__content">
                            <h3 className="persona-header__title">Vou te ajudar, me conte o que aconteceu:</h3>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="card card--no-pad">
                        <div className="card-content">
                            <ul className="collection collection--acionamento with-header">
                                <NavLink onClick={function(event){topFunction()}} to="Attendance">
                                    <li className="collection-item">
                                        <div className=" hspace-between">
                                            <span className="title"><strong> Quando tento dar a partida o veículo não funciona</strong></span>
                                        </div>
                                    </li>
                                </NavLink>
                                <NavLink onClick={function(event){topFunction()}} to="Attendance">
                                    <li className="collection-item">
                                        <div className=" hspace-between">
                                            <span className="title"><strong> Não sei o que está acontecendo, o veículo não funciona</strong></span>
                                        </div>
                                    </li>
                                </NavLink>
                                <NavLink onClick={function(event){topFunction()}} to="Attendance">
                                    <li className="collection-item">
                                        <div className=" hspace-between">
                                            <span className="title"><strong> Tenho problema de pneu furado ou está murcho</strong></span>
                                        </div>
                                    </li>
                                </NavLink>
                                <NavLink onClick={function(event){topFunction()}} to="Attendance">
                                    <li className="collection-item">
                                        <div className=" hspace-between">
                                            <span className="title"><strong> Quando eu ligo o veículo, ele fica com um barulho anormal</strong></span>
                                        </div>
                                    </li>
                                </NavLink>
                                <NavLink onClick={function(event){topFunction()}} to="Attendance">
                                    <li className="collection-item">
                                        <div className=" hspace-between">
                                            <span className="title"><strong> O veículo está com super aquecimento (motor ferveu) ou vazamento de água</strong></span>
                                        </div>
                                    </li>
                                </NavLink>
                                <NavLink onClick={function(event){topFunction()}} to="Attendance">
                                    <li className="collection-item">
                                        <div className=" hspace-between">
                                            <span className="title"><strong> Acabou o combustível   </strong></span>
                                        </div>
                                    </li>
                                </NavLink>
                                <NavLink onClick={function(event){topFunction()}} to="Attendance">
                                    <li className="collection-item">
                                        <div className=" hspace-between">
                                            <span className="title"><strong> Problemas com a chave</strong></span>
                                        </div>
                                    </li>
                                </NavLink>
                                <NavLink onClick={function(event){topFunction()}} to="Attendance">
                                    <li className="collection-item">
                                        <div className=" hspace-between">
                                            <span className="title"><strong> Acidente com o veículo</strong></span>
                                        </div>
                                    </li>
                                </NavLink>
                                <NavLink onClick={function(event){topFunction()}} to="Attendance">
                                    <li className="collection-item">
                                        <div className=" hspace-between">
                                            <span className="title"><strong> O veículo pegou fogo</strong></span>
                                        </div>
                                    </li>
                                </NavLink>
                                <NavLink onClick={function(event){topFunction()}} to="Attendance">
                                    <li className="collection-item">
                                        <div className=" hspace-between">
                                            <span className="title"><strong> Roubo/Furto do veículo</strong></span>
                                        </div>
                                    </li>
                                </NavLink>
                                <NavLink onClick={function(event){topFunction()}} to="Attendance">
                                    <li className="collection-item">
                                        <div className=" hspace-between">
                                            <span className="title"><strong> Solicitação de carro reserva</strong></span>
                                        </div>
                                    </li>
                                </NavLink>
                                <NavLink onClick={function(event){topFunction()}} to="Attendance">
                                    <li className="collection-item">
                                        <div className=" hspace-between">
                                            <span className="title"><strong> Solicitação de carro reserva quando terceiro</strong></span>
                                        </div>
                                    </li>
                                </NavLink>
                                <NavLink onClick={function(event){topFunction()}} to="Attendance">
                                    <li className="collection-item">
                                        <div className=" hspace-between">
                                            <span className="title"><strong> Outros</strong></span>
                                        </div>
                                    </li>
                                </NavLink>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default Others;