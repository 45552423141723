import React, { Component } from "react";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import { backPage } from "../App";

class ConcludeAssist extends Component {
    constructor(props) {
        super(props);
        try {
            let ConcludeAssitParams = sessionStorage.getItem(
                "ConcludeAssitParams"
            );
            let dataAbertura = sessionStorage.getItem("DataOpen");
            let idAssist = sessionStorage.getItem("IdAssistencia");
            let senha = sessionStorage.getItem("senhaValidacao");
            let flagAttachments = sessionStorage.getItem("FlagAttachments");
            let urlAttachments = sessionStorage.getItem("urlAttachmentsFromCreated");

            const idclientecorporativo = sessionStorage.getItem("idclientecorporativo");
            const selectedWallet = sessionStorage.getItem("SelectedWalet");
            const hourString = idclientecorporativo === '542' ? 'duas' : 'três';
            
            const ContactType = sessionStorage.getItem("ContactType0");
            const DateRange = sessionStorage.getItem("DateRange0");
            const protocol = sessionStorage.getItem("protocol");
            let flagProtocol = sessionStorage.getItem("FlagProtocolCli");
            sessionStorage.setItem("ContactType", ContactType);
            sessionStorage.setItem("DateRange", DateRange);
            
            this.state = {
                selectedWallet,
                hourString,
                dataAbertura,
                idAssist,
                senha,
                ConcludeAssitParams,
                hideMessagePassword: false,
                flagAttachments,
                urlAttachments,
                ContactType,
                DateRange,
                protocol,
                flagProtocol
            };

            props.keep();
            this.closeMessagePassword = this.closeMessagePassword.bind(this);
            backPage(this);
        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount() {
        setInterval(() => {
            if (sessionStorage.getItem("Logo") === null) {
                this.props.history.push(`/`);
            }
        }, 1000);
        sessionStorage.setItem("Action", "GoHome");
        document.getElementById("success-back-buttom").style.display = "block";
    }

    componentWillUnmount() {
        if (document.getElementById("success-back-buttom") !== null) {
            document.getElementById("success-back-buttom").style.display =
                "none";
        }
    }

    closeMessagePassword() {
        this.setState({
            hideMessagePassword: true
        });
    }

    render() {
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div>
                    <div className={"content-message-password"} style={
                        (this.state.hideMessagePassword === false && this.state.selectedWallet === 'Residência') ?
                            {
                                display: 'block',
                            }
                            :
                            {
                                display: 'none',
                            }
                    }
                    >
                        Anote a Senha de Abertura!

                        <span
                            onClick={this.closeMessagePassword}
                        >
                            <i className={"fa fa-close"}></i>
                        </span>
                    </div>
                    <div style={{ maxWidth: 750, margin: '0 auto' }}>
                        <div style={{ margin: 'auto', maxWidth: 'none' }} className={"content-conclude-wrapper"}>
                            <div style={{ paddingBottom: 0, maxWidth: 'initial' }} className="content-header">
                                <div style={{ paddingLeft: 0, paddingRight: 0 }} className="persona-header">
                                    <div style={{ textAlign: 'left' }} className="persona-header__content">
                                        <h3 style={{ padding: 0 }} className="persona-header__title">
                                            Pronto! Seu serviço foi aberto e em breve
                                            chegaremos até você.
                                </h3>
                                    </div>
                                </div>
                            </div>
                            {this.state.ConcludeAssitParams === "TRUE" ? (
                                <>
                                    <div className="spacer" />
                                    <div style={{ padding: 0, maxWidth: 'none' }} className={"conclude-assist-container"}>
                                        <span style={{ fontSize: 16, fontWeight: 700 }} className={"question-color-text"}>
                                            Protocolo
                                </span>
                                        <span className={"question-color-text"}>
                                            {this.state.flagProtocol === 'True' && this.state.protocol != '' && this.state.protocol != null  && this.state.protocol != 'undefined' ? this.state.protocol : this.state.idAssist}
                                        </span>
                                        <div className={"divisor-line"} />
                                    </div>
                                    <div className="spacer" />
                                    
                                    {this.state.ContactType != null &&
                                    this.state.ContactType != undefined ? (
                                        <div
                                            className={"conclude-assist-container"}
                                            style={{padding: 0, maxWidth: 'none'}}
                                        >
                                            <span style={{ fontSize: 16, fontWeight: 700 }} className={"question-color-text"}>
                                                Tipo de acionamento
                                            </span>
                                            <span className={"question-color-text"}>
                                                {this.state.ContactType}
                                            </span>
                                            <div className={"divisor-line"} />
                                        </div>
                                    ) : null}
                                    <div className="spacer" />
                                    {this.state.DateRange != null &&
                                    this.state.DateRange != undefined ? (
                                        <div
                                            className={"conclude-assist-container"}
                                            style={{padding: 0, maxWidth: 'none'}}
                                        >
                                            <span style={{ fontSize: 16, fontWeight: 700 }} className={"question-color-text"}>
                                                    Previsão de chegada
                                            </span>
                                            <span className={"question-color-text"}>
                                                {this.state.DateRange}
                                            </span>
                                            <div className={"divisor-line"} />
                                        </div>
                                    ) : null}
                                </>
                            ) : (
                                    <></>
                                )}
                        </div>

                        {sessionStorage.getItem("senhaValidacao") != null &&
                            sessionStorage.getItem("senhaValidacao") != 'undefined' ? (
                                <>
                                    <div className="spacer" />
                                    <div style={{ padding: 0, maxWidth: 'none' }} className={"conclude-assist-container"}>
                                        <span style={{ fontSize: 16, fontWeight: 700 }} className={"question-color-text"}>
                                            Esta é a senha de segurança que deverá ser informada ao prestador:
                                        </span>
                                        <span className={"question-color-text"}>
                                            {this.state.senha}
                                        </span>
                                        <div className={"divisor-line"} />
                                    </div>
                                </>
                            ) : null}

                        <div className="spacer" />
                        <div style={{ padding: 0, maxWidth: 'none' }} className={"conclude-assist-container"}>
                            <span
                                className={
                                    "question-color-text conclude-alert-label"
                                }
                            >
                                Atenção!
                        </span>
                            {sessionStorage.getItem("AssistTimeType") ===
                                "Agendado" &&
                                sessionStorage.getItem("SelectedWaletId") === "2" ? (
                                    <span
                                        className={"question-color-text conclude-label"}
                                    >
                                        {`
                                            Para serviços agendados, o prazo para o
                                            profissional comparecer ao local indicado é de
                                            até ${this.state.hourString} horas a partir do horário selecionado.
                                        `}
                                    </span>
                                ) : (
                                    <></>
                                )}

                            <span className={"question-color-text conclude-label"}>
                                Mesmo através desse canal virtual, temos um time de
                                profissionais acompanhando sua solicitação 24hrs por
                                dia.
                        </span>
                            <span className={"question-color-text conclude-label"}>
                                Caso ocorra algum problema, nós iremos lhe contatar
                                imediatamente.
                        </span>
                            <span className={"question-color-text conclude-label"}>
                                Enquanto você nos espera, use os botões abaixo para
                                acompanhar o trajeto de nosso profissional até você:
                        </span>
                        </div>
                        <div className="spacer" />
                        <div style={{ margin: 0 }} className="question-ans">
                            <div
                                onClick={this.chooseChoice.bind(this, 1)}
                                className="waves-effect waves-light btn-large btn-success"
                            >
                                <i className="fa fa-lg fa-home container__button--margin " />
                            Finalizar
                        </div>
                            {(this.state.flagAttachments === "True" && this.state.urlAttachments !== null) ? (
                                <div
                                    onClick={this.chooseChoice.bind(this, 2)}
                                    className="waves-effect waves-light btn-large btn-success"
                                >
                                    <i className="fa fa-lg fa-camera container__button--margin" />
                                Anexar fotos e vídeos
                                </div>
                            ) : (
                                    <></>
                                )}
                            {this.state.ConcludeAssitParams === "TRUE" ? (
                                <div
                                    onClick={this.chooseChoice.bind(this, 0)}
                                    className="waves-effect waves-light btn-large btn-success"
                                >
                                    <i className="fa fa-lg fa-info-circle container__button--margin" />
                                Acompanhar
                                </div>
                            ) : (
                                    <></>
                                )}
                        </div>
                        <div className="spacer" />
                        <div style={{ maxWidth: 'none' }} className={"conclude-assist-container"}>
                            <span className={"question-color-text conclude-label"}>
                                A qualquer momento, consulte demais informações na
                                opção FAQ no menu lateral
                        </span>
                        </div>
                    </div>
                </div>

            </CSSTransition>
        );
    }

    chooseChoice(element) {
        if (element === 1) {
            this.props.history.push("/");
        } else if (element === 2) {
            const url = sessionStorage.getItem("urlAttachmentsFromCreated");
            window.open(url, "_self");
        } else {
            sessionStorage.setItem("IdAnswer", this.state.idAssist);
            sessionStorage.setItem("Action", "AssistDetail");
            this.props.history.push("/carregando");
        }
    }
}

export default ConcludeAssist;
