import React from "react";
import CSSTransition from "react-transition-group/esm/CSSTransition";

const Error404 = () => {
    return (
        <CSSTransition in={true} appear={true} timeout={800} classNames="fade">
            <header className="App-header">
                <p className="text-color-black intro__text padding-15">
                    Pagina não encontrada
                </p>
            </header>
        </CSSTransition>
    );
};

export default Error404;
