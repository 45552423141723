import React from 'react';
import { NavLink } from 'react-router-dom';
import { topFunction } from '../../../App';
import CSSTransition from "react-transition-group/esm/CSSTransition";

const ItensImportants = () => {
    return(
        <CSSTransition
            in={true}
            appear={true}
            timeout={800}
            classNames="fade"
        >
            <div>
                <div className="content-header">
                    <div className="persona-header" >
                        <div className="persona-header__content">
                            <h3 className="persona-header__title">Vamos te ajudar! O que aconteceu?  </h3>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="row center">
                    </div>
                    <div className="row row__cards--display">
                        <div className="col s6 m6 l4">
                            <NavLink onClick={function(event){topFunction()}} to="/Proposta" className="card-link">
                                <div className="card card--sant">
                                    <div className="card-content proposal-main">
                                        <h2 className="proposal__text"><i className="fa fa-exclamation-triangle"/> Acidente</h2>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col s6 m6 l4">
                            <NavLink onClick={function(event){topFunction()}} to="/Proposta" className="card-link">
                                <div className="card card--sant">
                                    <div className="card-content proposal-main">
                                        <h2 className="proposal__text"><i className="fa fa-exclamation-triangle"/> Furto ou roubo</h2>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col s6 m6 l4">
                            <NavLink onClick={function(event){topFunction()}} to="/Proposta" className="card-link">
                                <div className="card card--sant">
                                    <div className="card-content proposal-secundary">
                                        <h2 className="proposal__text">Fogo</h2>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col s6 m6 l4">
                            <NavLink onClick={function(event){topFunction()}} to="/Proposta/MecanicoChaveOuPneu" className="card-link">
                                <div className="card card--sant">
                                    <div className="card-content proposal">
                                        <h2 className="proposal__text">Mecânico, chave ou pneu</h2>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col s6 m6 l4">
                            <NavLink onClick={function(event){topFunction()}} to="/Proposta" className="card-link">
                                <div className="card card--sant">
                                    <div className="card-content proposal">
                                        <h2 className="proposal__text">Carro reserva</h2>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col s6 m6 l4">
                            <NavLink onClick={function(event){topFunction()}} to="/Proposta" className="card-link">
                                <div className="card card--sant">
                                    <div className="card-content proposal">
                                        <h2 className="proposal__text">Outra situação</h2>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default ItensImportants;