import React, { Component } from "react";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import Modal from "react-bootstrap/Modal";
import { Redirect } from "react-router-dom";
import { Accompaniment } from "../controller/ControllerFunctions.js";
import {
    backPageQuestions,
    topFunction,
    GoogleAnalyticsEvent,
} from "../../App";
import { ProgressBar } from "react-bootstrap";

class SingleChoice2To6 extends Component {
    constructor(props) {
        super(props);
        let Action = sessionStorage.getItem("Action");
        const numbers = [];
        const ids = [];
        const url = [];
        const next = [];
        for (var i = 0; i < sessionStorage.length; i++) {
            if (sessionStorage.key(i).includes("QuestionText")) {
                numbers.push(numbers.length);
            }
            if (sessionStorage.key(i).includes("QuestionId")) {
                ids.push(ids.length);
            }
            if (sessionStorage.key(i).includes("QuestionUrl")) {
                url.push(url.length);
            }
            if (sessionStorage.key(i).includes("NextQuestion")) {
                next.push(next.length);
            }
        }

        for (var k = 0; k < numbers.length; k++) {
            numbers[k] =
                sessionStorage.getItem("QuestionText" + k) !== null
                    ? sessionStorage.getItem("QuestionText" + k)
                    : "";
            ids[k] = sessionStorage.getItem("QuestionId" + k);
            url[k] = sessionStorage.getItem("QuestionUrl" + k);
            next[k] = sessionStorage.getItem("NextQuestion" + k);
        }

        props.keep();
        backPageQuestions(this);
        if (
            Action !== "NextQuest" &&
            Action !== "ConsultTree" &&
            Action !== "SaveAnswer"
        ) {
            sessionStorage.setItem("Action", "NextQuest");
            props.history.push("/carregando");
            return;
        }

        if (sessionStorage.getItem("FirstQuestion") === "True") {
            sessionStorage.removeItem("FirstQuestion");
            this.verifyOpenAssistence(sessionStorage.getItem("ConveredSelectedItem"));
        }

        /** Caso volte pra tela de produtos, remove a opção selecionada anteriormente */
        var plan = sessionStorage.getItem("ConveredSelectedPlanData");
        if(plan != null){
            plan = JSON.parse(plan);
            
            var options = numbers;
            Object.values(options).map((o, index) => {
                var option = o.toString().toLowerCase()
                Object.keys(plan.produtos).map((e, i) => {
                    var selected = plan.produtos[e].nmproduto.toString().toLowerCase();
                    if(selected == option){
                        sessionStorage.removeItem("ConveredSelectedPlanProduct");
                    }
                });
            });
        }
        
        /** Caso volte pra tela de coberturas, remove a opção selecionada anteriormente */
        var product = sessionStorage.getItem("ConveredSelectedPlanProduct");
        if(product != null){        
            product = JSON.parse(product);
            var options = numbers;
            var finded = false;
            Object.values(options).map((o, index) => {
                var option = o.toString().toLowerCase()
                Object.keys(product.coberturas).map((e, i) => {
                    var selected = product.coberturas[e].nmcobertura.toString().toLowerCase();
                    if(selected == option){
                        sessionStorage.removeItem("ConveredSelectedPlanCover");
                        finded = true;
                    }
                });
            });
        }
        
        sessionStorage.setItem("hasLogged", "false");

        this.state = {
            numbers,
            ids,
            url,
            next,
            modalShow: true,
            coverModalShow: false,
            assistenceValue: "",
            partValue: "",
            limit: "",
            used: ""
        };
    }

    async componentDidMount() {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "unset";
        document.body.classList.remove("modal-open");
        document.body.classList.remove("contact-list");
        document.getElementsByClassName("modal")[0].style.display = "none";
        document.getElementsByClassName("modal-backdrop")[0].style.display =
            "none";
        document.getElementById("back-icon").style.display = "block";

        let answers = sessionStorage.getItem("allAnswers");
        if(answers != null){
            answers = JSON.parse(answers);
            for (var key in answers) {
                if (answers.hasOwnProperty(key)) {
                    if(sessionStorage.getItem("QuestionTitle") == answers[key].title){
                        delete answers[key];
                    }
                }
            }
            answers = JSON.stringify(answers);
            sessionStorage.setItem("allAnswers", answers);
        }

        /** Lógica para exibir os limites e usos (Caso esteja em uma tela diferente da tela de coberturas, não será exibido) */
        var product = sessionStorage.getItem("ConveredSelectedPlanProduct");
        if(product != null){
            product = JSON.parse(product);;
            var productId = product.idproduto;

            var options = this.state.numbers;
            var finded = false;
            Object.values(options).map((o, index) => {
                var option = o.toString().toLowerCase()
                Object.keys(product.coberturas).map((e, i) => {
                    var selected = product.coberturas[e].nmcobertura.toString().toLowerCase();
                    if(selected == option){
                        sessionStorage.removeItem("ConveredSelectedPlanCover");
                        finded = true;
                    }
                });
            });

            if(finded == false){
                this.setState({limit:"", used: ""});
            }else{
                const idItemCoberto = sessionStorage.getItem("ConveredSelectedItem");
                const accessToken = await this.getAccessToken();
        
                const assist = await this.getAssistStatement(idItemCoberto, accessToken);
                var limite = [];
                if (assist) {
                    limite = assist.coberturas.filter(cobertura => cobertura.id_descricao_limite_produto == productId);

                    if(Object.values(limite).length > 0){
                        this.setState({
                            limit: limite[0].quantidade_limite_ocorrencias,
                            used: (limite[0].quantidade_limite_ocorrencias - limite[0].quantidade_disponivel)
                        })
                    }
                }
            }
        } 
    }

    render() {
        let modalClose = () => this.setState({ modalShow: false });
        let coverModalClose = () => this.setState({ coverModalShow: false });
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div>
                    <div className="content-header" style={{paddingBottom: this.state.used !== "" ? "0" : "15px" }}>
                        <div className="persona-header">
                            <div className="persona-header__content">
                                <h3 className="persona-header__title">
                                    {sessionStorage.getItem("QuestionTitle")}
                                </h3>
                                {this.state.used !== "" ? (
                                    <div style={{ display: "flex", padding: "15px"}}>
                                        <div style={{width:"50%"}}>
                                            <p style={{paddingTop: "10px"}}>
                                            SEU SALDO POR ANO 
                                            </p>
                                        </div>
                                        <div style={{width:"50%"}}>
                                            <div style={{ display: "flex"}}>
                                                <p className="progress-bar-text" style={{textAlign:"left", width:"50%", fontSize: 10}}>
                                                    UTILIZADO
                                                </p>
                                                <p className="progress-bar-text" style={{textAlign:"right", width:"50%", fontSize: 10}}>
                                                    LIMITE
                                                </p>
                                            </div>
                                            <div style={{ display: "flex"}}>
                                                <p className="progress-bar-text" style={{textAlign:"left", width:"50%", fontSize: 10}}>
                                                    {this.state.used}
                                                </p>
                                                <p className="progress-bar-text" style={{textAlign:"right", width:"50%", fontSize: 10}}>
                                                   {this.state.limit}
                                                </p>
                                            </div>
                                            <ProgressBar className="progress-bar-product"  now={((this.state.used / this.state.limit ) * 100)} />
                                        </div>
                                    </div>
                                
                                ): ""}
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="row center"></div>
                        <div
                            id="home-wallet"
                            className="row row__cards--display list__question--padding"
                        >
                            {this.state.numbers === null ? (
                                <></>
                            ) : (
                                <ul className="remove-border collection collection--acionamento list__question with-header">
                                    {this.state.numbers.map((number, cont) => (
                                        <div
                                            onClick={this.chooseChoice.bind(
                                                this,
                                                cont
                                            )}
                                            className={"list-item-space"}
                                            key={number.toString()}
                                        >
                                            <li className="list-collection-item collection-item">
                                                <div className="list__position">
                                                    <img
                                                        onError={this.erroHandler.bind(
                                                            cont
                                                        )}
                                                        id={"errorImg" + cont}
                                                        alt=""
                                                        className="list__img"
                                                        src={
                                                            this.state.url[cont]
                                                        }
                                                    />
                                                    <span
                                                        nextquestion={
                                                            this.state.next[
                                                                cont
                                                            ]
                                                        }
                                                        id={
                                                            this.state.ids[cont]
                                                        }
                                                        className={
                                                            "itemClicked" +
                                                            cont +
                                                            " title list__text"
                                                        }
                                                    >
                                                        <strong>
                                                            {number}
                                                        </strong>
                                                    </span>
                                                </div>
                                            </li>
                                        </div>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                    <MyVerticallyCenteredModal
                        show={this.state.modalShow}
                        onHide={modalClose}
                        destiny={this.state.destiny}
                    />
                    <CoverModal
                        show={this.state.coverModalShow}
                        onHide={coverModalClose}
                        redirectToNext={this.redirectToNext.bind(this)}
                        chooseId={this.state.chooseId}
                        assistenceValue={this.state.assistenceValue}
                        partValue={this.state.partValue}
                    />
                </div>
            </CSSTransition>
        );
    }

    /** Busca o token para consumir o serviço de dados de limite e disponível no juvo */
    getAccessToken() {
        const url = process.env.REACT_APP_API_URL_ASSIST_TOKEN;

        const res = fetch(url, {
            method: 'post',
            headers: new Headers({
                Authorization: 'Basic YmI1YjM3MjktMTFjYi0zZTEwLWI5Y2QtNzI5ODhlNjMzNjZmOjQyNjI5Njg1LTk0NWYtMzAzZi05MTJjLWZjZTAyY2ZkMmJlNA==',
                "Content-Type": "application/json",
            }),
            body: JSON.stringify({
                grant_type: 'client_credentials'
            })
        })
            .then((response) => response.json())
            .then((response) => {
                return response.access_token;
            });
        return res;
    }

    /** Busca os dados de limite e disponível no juvo */
    getAssistStatement(idItemCoberto, accessToken) {
        const url =  process.env.REACT_APP_API_URL_ASSIST_LIST + idItemCoberto;

        const res = fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem('SelectedWaletId'),
                access_token: accessToken,
                client_id: 'bb5b3729-11cb-3e10-b9cd-72988e63366f'
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                return response;
            });

        return res;
    }

    erroHandler(cont) {
        cont.target.style.display = "none";
    }

    redirectToNext() {
        this.props.history.push(`/Carregando`);
    }
    
    chooseChoice(id) {
        var itemClicked = document.getElementsByClassName("itemClicked" + id)[0]
            .id;
        var NextQuestionSelected = document
            .getElementsByClassName("itemClicked" + id)[0]
            .getAttribute("nextquestion");
        sessionStorage.setItem("IdAnswer", itemClicked);
        sessionStorage.setItem("Action", "SaveAnswer");
        sessionStorage.setItem("NextQuestionSelected", NextQuestionSelected);
        topFunction();

        let label = this.state.numbers[id] + " - " + itemClicked;
        
        let answers = sessionStorage.getItem("allAnswers");
        if(answers == null){
            answers = {};
        }else{
            answers = JSON.parse(answers);
        }
        
        const answer = {
            "title": sessionStorage.getItem("QuestionTitle"),
            "answer": this.state.numbers[id]
        }
        answers[Object.keys(answers).length] = answer;
        
        answers = JSON.stringify(answers);
        sessionStorage.setItem("allAnswers", answers);

        GoogleAnalyticsEvent(
            sessionStorage.getItem("SelectedWalet"),
            sessionStorage.getItem("AtualId"),
            label
        );

        /** Caso seja uma opção relacionada a planos/produto/cobertura */
        var plan = sessionStorage.getItem("ConveredSelectedPlanData");
        if(plan != null){
            plan = JSON.parse(plan);
            
            var option = this.state.numbers[id].toString().toLowerCase();
            Object.keys(plan.produtos).map((e, i) => {
                var selected = plan.produtos[e].nmproduto.toString().toLowerCase();
                if(selected == option){
                    sessionStorage.setItem("ConveredSelectedPlanProduct", JSON.stringify(plan.produtos[e]));
                }
            });
        }
        
        var product = sessionStorage.getItem("ConveredSelectedPlanProduct");
        if(product != null){
            product = JSON.parse(product);
            var option = this.state.numbers[id].toString().toLowerCase();
            var finded = false;
            Object.keys(product.coberturas).map((e, i) => {
                var selected = product.coberturas[e].nmcobertura.toString().toLowerCase();
                if(selected == option){
                    finded = true
                    sessionStorage.setItem("ConveredSelectedPlanCover", JSON.stringify(product.coberturas[e]));
                    this.setState({
                        coverModalShow: true,
                        chooseId: id,
                        assistenceValue: product.coberturas[e].limmaoobra,
                        partValue: product.coberturas[e].limpecas
                    });
                }
            });

            if(finded == false){
                this.props.history.push(`/Carregando`);
            }
        }else{
            this.props.history.push(`/Carregando`);
        }
    }

    verifyOpenAssistence(IdItemCoberto) {
        const urlHours =
            process.env.REACT_APP_API_URL_JUVO +
            "/assistenciaServico/segurado?idItemCoberto=" +
            IdItemCoberto +
            "&notInStatus=3&notInStatus=4&notInStatus=5&notInStatus=6&notInStatus=2"
        fetch(urlHours, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if(Object.values(response).length > 0 && response.codigo !== 999){
                    document.getElementsByClassName("modal")[0].style.display =
                        "block";
                    document.getElementsByClassName(
                        "modal-backdrop"
                    )[0].style.display = "block";
                }else{
                    sessionStorage.setItem("ConveredSelectedItem", IdItemCoberto);
                    sessionStorage.setItem("Action", "ConsultTree");
                    this.props.history.push(`/carregando`);
                }
            })
            .catch((error) => {
                console.log();
                sessionStorage.setItem("ConveredSelectedItem", IdItemCoberto);
                sessionStorage.setItem("Action", "ConsultTree");
                this.props.history.push(`/carregando`);
            });
    }
}

class MyVerticallyCenteredModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            redirectLabel: "/contatos",
        };
    }

    render() {
        return this.state.redirect === true ? (
            <Redirect to={this.state.redirectLabel} push />
        ) : (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        AVISO
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Há assistência em andamento, deseja acompanhar?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        onClick={this.changeToAccompaniment.bind(this)}
                        style={{ cursor: "pointer" }}
                        className="waves-effect waves-light btn-large btn-success"
                    >
                        <i className="fa fa-lg fa-check container__button--margin " />{" "}
                        Sim
                    </div>
                    <div
                        onClick={this.changeToQuestion}
                        className="waves-effect waves-light btn-large btn-danger"
                    >
                        <i className="fa fa-lg fa-times container__button--margin " />{" "}
                        Não
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }

    async changeToAccompaniment() {
        const retorno = await Accompaniment(this.props);
        if (retorno) {
            sessionStorage.setItem("Action", "Accompaniment");
            topFunction();
            this.setState({
                redirect: true,
                redirectLabel: "/acompanhamentos",
            });
        } else {
            console.log("");
        }
    }

    changeToQuestion() {
        document.getElementsByClassName("modal")[0].style.display = "none";
        document.getElementsByClassName("modal-backdrop")[0].style.display =
            "none";
    }
}

/** Modal de exibição dos dados de valor da cobertura */
class CoverModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }            

    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter persona-header__title" className="modal-text">
                        Limite financeiro por acionamento
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        VALOR POR ASSISTÊNCIA CONTRATADA
                    </p>
                    <p className="collection-item modal-text">
                        R$ {this.props.assistenceValue} (cada utilização)
                    </p>
                    <p>
                        VALOR DE PEÇAS/MATERIAIS
                    </p>
                    <p className="modal-text">
                        R$ {this.props.partValue}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        onClick={this.props.onHide}
                        className="waves-effect waves-light btn-large btn-danger"
                    >
                        <i className="fa fa-lg fa-times container__button--margin " />{" "}
                        VOLTAR
                    </div>
                    <div
                        onClick={this.props.redirectToNext}
                        style={{ cursor: "pointer" }}
                        className="waves-effect waves-light btn-large btn-success"
                    >
                        <i className="fa fa-lg fa-check container__button--margin " />{" "}
                        AVANÇAR
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default SingleChoice2To6;
