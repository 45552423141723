import React, { forwardRef } from 'react';

export const CustomInputHour = forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} className="undefined" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={onClick}>
        {value ? value : 'Selecione um horário'}
    </button>
))

export const CustomInputDate = forwardRef(({ value, onClick }, ref) => (
    <button className="undefined" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} onClick={onClick}>
        {value ? value : 'Selecione uma data'}
    </button>
))

