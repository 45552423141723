import React, { Component } from "react";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import { backPage, importStyle, topFunction } from "../App";
import Loader from "react-loader-spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class AddressDestiny extends Component {
    constructor(props) {
        super(props);

        var stylePerso = [];
        for (let i = 0; i < sessionStorage.length; i++) {
            if (sessionStorage.key(i).includes("--")) {
                stylePerso.push(sessionStorage.key(i));
                stylePerso.push(sessionStorage.getItem(sessionStorage.key(i)));
            }
        }
        importStyle(stylePerso);

        this.state = {
            dsfsdf: "",
            text: `${process.env.REACT_APP_PUSH_TEMPO_FRAME}AddressDestiny.html`,
            url: "AddressDestiny?c=4",
            loading: true,
            modalShow: false,
        };
        this.getMaps = this.getMaps.bind(this);
        backPage(this);
    }

    componentDidMount() {
        document.body.style.position = "fixed";
        document.body.style.width = "100%";
    }

    componentWillUnmount() {
        document.body.style.position = "unset";
        document.body.style.width = "unset";
    }

    render() {
        const { text } = this.state;
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div>
                    <div id={"loader"} className="loader__position">
                        <Loader
                            type="TailSpin"
                            color="#FFFFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                    <div id={"content"} style={{ display: "none" }}>
                        <div>
                            <iframe
                                id="iframe"
                                onLoad={this.getMaps}
                                title="Maps"
                                className="iframe"
                                src={text}
                            />
                        </div>
                        <div
                            id={"confirm-btn"}
                            onClick={this.clickEvent.bind(this)}
                            className="container__div__footer--maps row"
                            style={{ display: "none" }}
                        >
                            <div className="map__button waves-effect waves-light btn-large btn-success">
                                <i className="fa fa-lg fa-check margin__button-big" />{" "}
                                Confirmar
                            </div>
                        </div>
                    </div>
                    <MyVerticallyCenteredModal
                        show={this.state.modalShow}
                        onHide={() => this.setState({ modalShow: false })}
                    />
                </div>
            </CSSTransition>
        );
    }

    getMaps() {
        let url_string__logo = window.location.href;
        let url = new URL(url_string__logo);
        let c = url.searchParams.get("c");
        let DestinyRequired = sessionStorage.getItem("DestinyRequired");
        try {
            if (DestinyRequired === "True") {
                if (c === "1") {
                    this.setState({
                        text:
                            process.env.REACT_APP_PUSH_TEMPO_FRAME +
                            "AddressDestiny.html?c=1",
                    });
                    this.setState({ url: "AddressDestiny?c=1" });
                } else {
                    if (c === "2") {
                        this.setState({
                            text:
                                process.env.REACT_APP_PUSH_TEMPO_FRAME +
                                "AddressDestiny.html?c=2",
                        });
                        this.setState({ url: "AddressDestiny?c=2" });
                    } else {
                        if (c === "3") {
                            this.setState({
                                text:
                                    process.env.REACT_APP_PUSH_TEMPO_FRAME +
                                    "AddressDestiny.html?c=3",
                            });
                            this.setState({ url: "AddressDestiny?c=3" });
                        } else {
                            this.setState({
                                text:
                                    process.env.REACT_APP_PUSH_TEMPO_FRAME +
                                    "AddressDestiny.html",
                            });
                            this.setState({ url: "AddressDestiny" });
                        }
                    }
                }
            } else {
                if (c === "1") {
                    this.setState({
                        text:
                            process.env.REACT_APP_PUSH_TEMPO_FRAME +
                            "AddressDestiny.html?c=1",
                    });
                    this.setState({ url: "AddressOrigin?c=1" });
                } else {
                    if (c === "2") {
                        this.setState({
                            text:
                                process.env.REACT_APP_PUSH_TEMPO_FRAME +
                                "AddressDestiny.html?c=2",
                        });
                        this.setState({ url: "AddressOrigin?c=2" });
                    } else {
                        if (c === "3") {
                            this.setState({
                                text:
                                    process.env.REACT_APP_PUSH_TEMPO_FRAME +
                                    "AddressDestiny.html?c=3",
                            });
                            this.setState({ url: "AddressOrigin?c=3" });
                        } else {
                            this.setState({
                                text:
                                    process.env.REACT_APP_PUSH_TEMPO_FRAME +
                                    "AddressDestiny.html",
                            });
                            this.setState({ url: "AddressOrigin" });
                        }
                    }
                }
            }
            document.getElementById("loader").style.display = "none";
            document.getElementById("content").style.display = "block";
            setTimeout(function () {
                document.getElementById("content").style.display = "unset";
            }, 500);
        } catch (e) {
            console.log(e);
        }

        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("autocomplete")
            .addEventListener("keyup", () => {
                let valueAutoComplete = document
                    .getElementById("iframe")
                    .contentWindow.document.getElementById("autocomplete")
                    .value;
                if (valueAutoComplete === "") {
                    document.getElementById("confirm-btn").style.display =
                        "none";
                    document
                        .getElementById("iframe")
                        .contentWindow.document.getElementById(
                            "use-location"
                        ).style.display = "block";
                } else {
                    document.getElementById("confirm-btn").style.display =
                        "flex";
                }
            });

        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("not-found")
            .addEventListener("click", () => {
                document.getElementById("confirm-btn").style.display = "none";
            });

        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("use-location")
            .addEventListener("click", () => {
                this.checkText();
            });

        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("street--input")
            .addEventListener("keyup", () => {
                let valueStreet = document
                    .getElementById("iframe")
                    .contentWindow.document.getElementById("street--input")
                    .value;
                if (valueStreet === "") {
                    document.getElementById("confirm-btn").style.display =
                        "none";
                } else {
                    document.getElementById("confirm-btn").style.display =
                        "flex";
                }
            });
    }

    checkText() {
        let valueAutoComplete = document
            .getElementById("iframe")
            .contentWindow.document.getElementById("autocomplete").value;
        if (valueAutoComplete !== "") {
            document.getElementById("confirm-btn").style.display = "flex";
        } else {
            setTimeout(() => {
                this.checkText();
            }, 500);
        }
    }

    clickEvent() {
        try {
            this.SendDataResid();
        } catch (e) {
            console.log(e);
        }
    }

    SendDataResid() {
        let isAddressChecked = this.checkAddress();
        if (isAddressChecked) {
            var isOmni = sessionStorage.getItem("FlagPreAssist");
            if(isOmni == 'True'){
                this.preAssistDestiny();
            }else{
                sessionStorage.setItem("Action", "Conclude");
                this.props.history.push(`/resumo`);
            }
        } else {
            this.errorHandle();
        }
    }

    preAssistDestiny() {
        const url =
        process.env.REACT_APP_API_URL_TEMPO +
        "/pre-assistencia/endereco-destino";

        let body = {
            "preNovoServicoAssistencia":{
                "idAssistencia": sessionStorage.getItem("PreAssistId")
            },
            "enderecoCompletoDestino": {
                "uf": sessionStorage.getItem("DestinyState"),
                "cep": sessionStorage.getItem("DestinyCep"),
                "bairro": sessionStorage.getItem("DestinyNeighborhood"),
                "cidade": sessionStorage.getItem("DestinyCity"),
                "numero": this.numberValidator("Destiny"),
                "logradouro": sessionStorage.getItem("DestinyStreet"),
                "complemento": sessionStorage.getItem("DestinyComplement"),
                "isCondominio": "F",
            },
        };;
        
        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
                "Content-Type": "application/json",
                "X-JuvoTransacaoID": sessionStorage.getItem("juvoTransactionID")
            }),
            method: "put",
            body: JSON.stringify(body),
        })
            .then((response) => {
                try {
                    return response.json();
                } catch (error) {
                    return Promise.resolve({});
                }
            })
            .then((response) => {
                if (response.codigo === 999) {
                    sessionStorage.setItem("GenericError", "False");
                    if (response.descricaoAuxiliar) {
                        sessionStorage.setItem(
                            "SpecificError",
                            response.descricao +
                            "\n\r" +
                            (response.descricaoAuxiliar !==
                                undefined &&
                                response.descricaoAuxiliar != "-1"
                                ? response.descricaoAuxiliar
                                : "")
                        );
                    } else {
                        sessionStorage.setItem(
                            "SpecificError",
                            response.descricao
                        );
                    }
                } else {
                    sessionStorage.setItem("Action", "Conclude");
                    this.props.history.push(`/resumo`);
                }
            })
            .catch((error) => {
                sessionStorage.setItem("SpecificError", "");
                sessionStorage.setItem("GenericError", "True");
            });
    }

    numberValidator(type) {
        if (
            sessionStorage.getItem(type + "Number") === "" &&
            sessionStorage.getItem(type + "Latitude") !== "" &&
            sessionStorage.getItem(type + "Longitude") !== ""
        ) {
            return "Vazio";
        } else {
            return sessionStorage.getItem(type + "Number");
        }
    }

    checkAddress() {
        if (
            document
                .getElementById("iframe")
                .contentWindow.document.getElementById(
                    "administrative_area_level_1"
                ) != null
        ) {
            if (
                document
                    .getElementById("iframe")
                    .contentWindow.document.getElementById("street--input")
                    .value === ""
            ) {
                if (
                    document
                        .getElementById("iframe")
                        .contentWindow.document.getElementById(
                            "administrative_area_level_1"
                        ).value !== "" &&
                    document
                        .getElementById("iframe")
                        .contentWindow.document.getElementById("route")
                        .value !== ""
                ) {
                    sessionStorage.setItem(
                        "DestinyNumber",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "street_number"
                            ).value
                    );
                    sessionStorage.setItem(
                        "DestinyComplement",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "complement-id"
                            ).value
                    );
                    sessionStorage.setItem(
                        "DestinyReference",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "reference-id"
                            ).value
                    );
                    sessionStorage.setItem(
                        "DestinyStreet",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById("route")
                            .value
                    );
                    sessionStorage.setItem(
                        "DestinyCity",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "administrative_area_level_2"
                            ).value
                    );
                    sessionStorage.setItem(
                        "DestinyState",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "administrative_area_level_1"
                            ).value
                    );
                    sessionStorage.setItem(
                        "DestinyNeighborhood",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "sublocality_level_1"
                            ).value
                    );
                    sessionStorage.setItem(
                        "DestinyCep",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "postal_code"
                            ).value
                    );
                    topFunction();
                    console.log("");
                    return true;
                } else {
                    return false;
                }
            } else {
                if (this.isAddressFilled()) {
                    sessionStorage.setItem(
                        "DestinyNumber",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "street_number"
                            ).value
                    );
                    sessionStorage.setItem(
                        "DestinyComplement",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "complement-id"
                            ).value
                    );
                    sessionStorage.setItem(
                        "DestinyReference",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "reference-id"
                            ).value
                    );
                    sessionStorage.setItem(
                        "DestinyStreet",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "street--input"
                            ).value
                    );
                    sessionStorage.setItem(
                        "DestinyCity",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "city--input"
                            ).value
                    );
                    sessionStorage.setItem(
                        "DestinyState",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "state--input"
                            ).value
                    );
                    sessionStorage.setItem(
                        "DestinyNeighborhood",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "neighborhood--input"
                            ).value
                    );
                    sessionStorage.setItem(
                        "DestinyCep",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "postal_code"
                            ).value
                    );
                    console.log("");
                    return true;
                } else {
                    return false;
                }
            }
        }
    }

    errorHandle() {
        this.setState({
            modalShow: true,
        });
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "error-message"
            ).style.display = "block";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "locationField"
            ).style.border = "solid 1px red";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "autocomplete"
            ).style.borderBottom = "solid 1px red";
    }

    isAddressFilled() {
        if (
            document
                .getElementById("iframe")
                .contentWindow.document.getElementById("street_number")
                .value !== "" &&
            document
                .getElementById("iframe")
                .contentWindow.document.getElementById("neighborhood--input")
                .value !== "" &&
            document
                .getElementById("iframe")
                .contentWindow.document.getElementById("city--input").value !==
                "" &&
            document
                .getElementById("iframe")
                .contentWindow.document.getElementById("state--input").value !==
                ""
        ) {
            return true;
        } else {
            return false;
        }
    }
}

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Aviso
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Não localizamos as informações necessárias no endereço
                    relatado. Você poderá inserir as informações que restam ou
                    informar um novo endereço no mapa.
                </p>
            </Modal.Body>
            <Modal.Footer className={"modal-footer-breakline"}>
                <Button
                    onClick={() => closeModalNewAddress()}
                    to="carregando"
                    className="waves-effect waves-light btn-large btn-success"
                >
                    Novo endereço
                </Button>
                <Button
                    onClick={() => closeModal()}
                    to="carregando"
                    className="waves-effect waves-light btn-large btn-danger"
                >
                    Inserir as informações
                </Button>
            </Modal.Footer>
        </Modal>
    );

    function closeModal() {
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("autocomplete").value = "";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("not-found").style.display =
            "none";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "locationField"
            ).style.display = "none";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("street").style.display =
            "block";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "neighborhood"
            ).style.display = "block";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("city").style.display =
            "block";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("state").style.display =
            "block";
        props.onHide();
    }

    function closeModalNewAddress() {
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("autocomplete").value = "";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("street_number").value = "";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "street_number"
            ).placeholder = "Número";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "error-message"
            ).style.display = "none";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "locationField"
            ).style.border = "solid 1px";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "autocomplete"
            ).style.borderBottom = "unset";

        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "locationField"
            ).style.display = "block";

        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("street").style.display =
            "none";

        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "neighborhood"
            ).style.display = "none";

        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("city").style.display =
            "none";

        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("state").style.display =
            "none";

        props.onHide();
    }
}

export default AddressDestiny;
