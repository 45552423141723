import React, { Component }  from 'react';
import CSSTransition from "react-transition-group/esm/CSSTransition";

class Error400 extends Component {
    constructor(props) {
        super(props);

        var url = new URL(window.location.href);
        var c = url.searchParams.get("c");
        sessionStorage.setItem("clicorperro", c);
    }

    componentDidMount() {
        const error = sessionStorage.getItem("Erro400");
        sessionStorage.clear();
        sessionStorage.setItem("Erro400" , error);
        sessionStorage.setItem("ActualPage" , "/");
    }

    render() {
        return(
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <header className="App-header app-header-container" style={{width:"100%"}}>
                    <p className="intro__text padding-15">
                        {
                            sessionStorage.getItem("clicorperro") == 542 ? <>Este canal não está mais disponível para solicitações de Assistência, você ainda pode fazer a sua solicitação pelos demais canais de atendimento:<br/>
                            <br/>
                            Área do Cliente através deste link: <a style={{ color: "#007bff" }} href='https://security-oauth.bbseguros.com.br/'>https://security-oauth.bbseguros.com.br/</a><br/>
                            <br/>
                            APP Banco do Brasil, selecionando a opção Seguro.<br/>
                            <br/>
                            WhatsApp: <a style={{ color: "#007bff" }} href='https://wa.me/1140047000'>11 4004 7000</a><br/>
                            <br/>
                            Central de Atendimento pelo número: <a style={{ color: "#007bff" }} href='tel:08007297000'>0800 729 7000</a></>
                        :
                            sessionStorage.getItem("Erro400") !== undefined ? sessionStorage.getItem("Erro400") : "Ocorreu um erro" 
                        }
                    </p>
                </header>
            </CSSTransition>
        )
    }
}

export default Error400;