import React from 'react';
import { NavLink } from 'react-router-dom';
import { topFunction } from '../../../App';
import mechanical from "../../../img/mechanical.svg";
import battery from "../../../img/battery.svg";
import CSSTransition from "react-transition-group/esm/CSSTransition";

const Breakdown = () => {
    return(
        <CSSTransition
            in={true}
            appear={true}
            timeout={800}
            classNames="fade"
        >
            <div>
                <div className="content-header">
                    <div className="persona-header" >
                        <div className="persona-header__content">
                            <h3 className="persona-header__title">Eita! Fique tranquilo João! Pane, não precisa significar pânico. Qual tipo de pane parece ser?</h3>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="row center">
                    </div>
                    <div className="row">
                        <div className="col s6 m6 l4">
                            <NavLink onClick={function(event){topFunction()}} to="Battery">
                                <div className="card card--sant">
                                    <div className="card-image">
                                        <img alt="Elétrica" className="card__img" src={battery}/>
                                    </div>
                                    <div className="card-content">
                                        <h2>Elétrica</h2>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col s6 m6 l4">
                            <NavLink onClick={function(event){topFunction()}} to="Breakdown">
                                <div className="card card--sant">
                                    <div className="card-image">
                                        <img alt="Mecânica" className="card__img" src={mechanical}/>
                                    </div>
                                    <div className="card-content">
                                        <h2>Mecânica</h2>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default Breakdown;