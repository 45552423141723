import React, { Component } from "react";
import DrawerToggleButton from "../sideDrawer/DrawerToggleButton";
import { withRouter } from "react-router";

class Toolbar extends Component {
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        sessionStorage.setItem("ActualPage", nextProps.location.pathname);
        return true;
    }

    render() {
        return (
            <header className="toolbar">
                <nav className="toolbar__navigation">
                    <div id={"backButton"} className={"back-button"}>
                        <div onClick={this.handleClickBack.bind(this)}>
                            <i
                                id={"back-icon"}
                                style={
                                    sessionStorage.getItem("ActualPage") === "/"
                                        ? { display: "none" }
                                        : { display: "block" }
                                }
                                className="fa fa-chevron-left fa-lg toolbar-back-button"
                            />
                        </div>
                    </div>
                    <div
                        style={{ display: "none" }}
                        id={"success-back-buttom"}
                        className={"back-button"}
                    >
                        <div onClick={this.successHandleClick.bind(this)}>
                            <i
                                id={"back-icon"}
                                style={
                                    sessionStorage.getItem("ActualPage") === "/"
                                        ? { display: "none" }
                                        : { display: "block" }
                                }
                                className="fa fa-chevron-left fa-lg toolbar-back-button"
                            />
                        </div>
                    </div>
                    <div
                        onClick={() => this.goHome()}
                        className="toolbar__link"
                    >
                        <div className="navbar__div">
                            <img
                                style={
                                    sessionStorage.getItem("Logo") !== null
                                        ? { display: "block" }
                                        : { display: "none" }
                                }
                                alt=" "
                                className="navbar__logo-santander-auto"
                                src={sessionStorage.getItem("Logo")}
                            />
                        </div>
                    </div>
                    <div className={"drower-button"}>
                        <DrawerToggleButton
                            click={this.props.drawerClickHandler}
                        />
                    </div>
                </nav>
            </header>
        );
    }

    handleClickBack() {
        let back = JSON.parse(sessionStorage.getItem("BackPage"));
        if (back !== null) {
            if (back[back.length - 2] === "/carregando") {
                sessionStorage.setItem("Action", "NextQuest");
            }
            this.props.history.push(back[back.length - 2]);
            sessionStorage.setItem("ActualPage", back.pop());
            if (back.length === 0) {
                back.push("/");
            }
            sessionStorage.setItem("BackPage", JSON.stringify(back));
        }
    }

    goHome() {
        let WaletSize = parseInt(sessionStorage.getItem("WaletSize"));
        if (WaletSize > 1) {
            document.getElementById("back-icon").style.display = "none";
            this.props.history.push(`/`);
        } else {
            document.getElementById("back-icon").style.display = "none";
            this.props.history.push(`/Login`);
        }
    }

    reload() {
        document.getElementById("back-icon").style.display = "none";
    }

    successHandleClick() {
        this.props.history.push("/");
    }
}

export default withRouter(Toolbar);
