import React, { Component } from "react";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import { backPage } from "../App";

class Resume extends Component {
    constructor(props) {
        super(props);
        let dateScheduling = "";
        let dateSchedulingEnd = "";
        if (sessionStorage.getItem("ContactType") === "Agendado") {
            dateScheduling = new Date(Number(sessionStorage.getItem("ContactDateTime")));
            dateSchedulingEnd = dateScheduling.getTime() + (sessionStorage.getItem('idclientecorporativo') === '542' ? 7200000 : 10800000);
            dateSchedulingEnd = new Date(Number(dateSchedulingEnd));
            dateScheduling = dateScheduling.toLocaleDateString('pt-BR') + ' ' + dateScheduling.toLocaleTimeString('pt-BR', {hour: '2-digit', minute:'2-digit'});
            dateSchedulingEnd = dateSchedulingEnd.toLocaleDateString('pt-BR') + ' ' + dateSchedulingEnd.toLocaleTimeString('pt-BR', {hour: '2-digit', minute:'2-digit'});
        }

        let services = Object.values(JSON.parse(sessionStorage.getItem("allAnswers")));
        
        this.state = {
            dateScheduling,
            dateSchedulingEnd,
            services
        }

        try {
            props.keep();
            backPage(this);
        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount() {
        setInterval(() => {
            if (sessionStorage.getItem("Logo") === null) {
                this.props.history.push(`/`);
            }
        }, 1000);
    }

    render() {
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div>
                    <div className="content-header">
                            <div className="persona-header">
                                <div className="persona-header__content">
                                    <h3 className="persona-header__title">
                                        Confirmar abertura de assistência
                                    </h3>
                                </div>
                            </div>
                    </div>
                    <div style={{ maxWidth: 650, margin: 'auto' }}>
                        <div style={{ margin: 10, maxWidth: 'none' }} className={"content-conclude-wrapper"}>
                            <div style={{ padding: 0, maxWidth: 'none' }} className={"conclude-assist-container"}>
                                <span style={{ fontSize: 24, fontWeight: 700 }} className={"question-color-text"}>
                                    Resumo dos dados
                                </span>
                            </div>
                            <div className="spacer" />
                            <div style={{ padding: 0, maxWidth: 'none' }} className={"conclude-assist-container"}>
                                <span style={{ fontSize: 24, fontWeight: 700 }} className={"question-color-text"}>
                                    Serviços e evento informados
                                </span>

                                {this.state.services.map(options => (
                                    <>
                                        <span className={"question-color-text"}>
                                            <strong>{options.title}</strong>
                                        </span>
                                        <span className={"question-color-text"}>
                                            {options.answer}
                                        </span>  
                                    </>
                                ))}
                                <div className={"divisor-line"} />
                            </div>
                            <div className="spacer" /> 
                            <div style={{ padding: 0, maxWidth: 'none' }} className={"conclude-assist-container"}>
                                <span style={{ fontSize: 24, fontWeight: 700 }} className={"question-color-text"}>
                                    Descrição do Serviço
                                </span>
                                <span className={"question-color-text"}>
                                    {sessionStorage.getItem("descriptionService")}
                                </span>
                                <div className={"divisor-line"} />
                            </div>
                            <div className="spacer" />
                            <div style={{ padding: 0, maxWidth: 'none' }} className={"conclude-assist-container"}>
                                <span style={{ fontSize: 24, fontWeight: 700 }} className={"question-color-text"}>
                                    Dados de contato do responsável
                                </span>
                                <span className={"question-color-text"}>
                                    <strong>Nome: </strong>&nbsp;{sessionStorage.getItem("ContactName")}
                                </span> 
                                
                                <span className={"question-color-text"}>
                                    <strong>Telefone: </strong>&nbsp;{sessionStorage.getItem("ContactTel")}
                                </span>

                                {sessionStorage.getItem("ContactEmail") ? (
                                    <span className={"question-color-text"}>
                                        <strong>Email:</strong>&nbsp;{sessionStorage.getItem("ContactEmail")}
                                    </span>
                                ) : (<></>)}
                                <div className={"divisor-line"} />
                            </div> 
                            <div className="spacer" />
                            <div style={{ padding: 0, maxWidth: 'none' }} className={"conclude-assist-container"}>
                                <span style={{ fontSize: 24, fontWeight: 700 }} className={"question-color-text"}>
                                    Tipo de solicitação
                                </span>
                                <span className={"question-color-text"}>
                                    <strong>Tipo: </strong>&nbsp;{sessionStorage.getItem("ContactType")}
                                </span> 
                                
                                {sessionStorage.getItem("ContactType") == 'Agendado' ? (
                                    <>
                                        <span className={"question-color-text"}>
                                            <strong>Data Agendamento: </strong>&nbsp;{this.state.dateScheduling}
                                        </span>
                                        <span className={"question-color-text"}>
                                            <strong>Prazo máximo para chegada até o local: </strong>&nbsp;{this.state.dateSchedulingEnd}
                                        </span>
                                    </>
                                ) : (<></>)}
                                <div className={"divisor-line"} />
                            </div> 
                            <div className="spacer" />
                            <div style={{ padding: 0, maxWidth: 'none' }} className={"conclude-assist-container"}>
                                {sessionStorage.getItem("SelectedWaletId") === '1' ? (
                                    <span style={{ fontSize: 24, fontWeight: 700 }} className={"question-color-text"} >
                                        Endereço de origem
                                    </span>):
                                (
                                    <span style={{ fontSize: 24, fontWeight: 700 }} className={"question-color-text"} >
                                        Endereço de Imóvel
                                    </span>
                                )}
                                <span className={"question-color-text"}>
                                    {sessionStorage.getItem("OriginStreet") + ' | '} 
                                    {sessionStorage.getItem("OriginNumber") ? sessionStorage.getItem("OriginNumber") + ' | ' : 'Vazio | '}
                                    {sessionStorage.getItem("OriginNeighborhood") + ' | '} 
                                    {sessionStorage.getItem("OriginCity") + ' | '} 
                                    {sessionStorage.getItem("OriginState") + ' | '} 
                                    {sessionStorage.getItem("OriginCep")} 
                                    {sessionStorage.getItem("OriginComplement") ? ' | ' + sessionStorage.getItem("OriginComplement") : ''}
                                    {sessionStorage.getItem("OriginReference") ? ' | ' + sessionStorage.getItem("OriginReference") : ''}
                                </span>
                                <div className={"divisor-line"} />
                            </div>
                            <div className="spacer" />
                                
                            { sessionStorage.getItem("DestinyStreet") != null &&
                                    sessionStorage.getItem("DestinyStreet") != 'undefined' ? (
                                    <>
                                        <div style={{ padding: 0, maxWidth: 'none' }} className={"conclude-assist-container"}>
                                            <span style={{ fontSize: 24, fontWeight: 700 }} className={"question-color-text"}>
                                                Endereço de destino
                                            </span>
                                            <span className={"question-color-text"}>
                                                {sessionStorage.getItem("DestinyStreet") + ' | '} 
                                                {sessionStorage.getItem("DestinyNumber") ? sessionStorage.getItem("DestinyNumber") + ' | ' : 'Vazio'}
                                                {sessionStorage.getItem("DestinyNeighborhood") + ' | '} 
                                                {sessionStorage.getItem("DestinyCity") + ' | '} 
                                                {sessionStorage.getItem("DestinyState") + ' | '} 
                                                {sessionStorage.getItem("DestinyCep")} 
                                                {sessionStorage.getItem("DestinyComplement") ? ' | ' + sessionStorage.getItem("DestinyComplement") : ''}
                                                {sessionStorage.getItem("DestinyReference") ? ' | ' + sessionStorage.getItem("DestinyReference") : ''}
                                            </span>
                                            <div className={"divisor-line"} />
                                        </div>
                                        <div className="spacer" />
                                    </>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div style={{ margin: 20, paddingBottom: 20 }} className="question-ans">
                            <div 
                                onClick={this.handleClickBack.bind(this)}
                                className="waves-effect waves-light btn-large btn-danger"
                            >
                                Voltar
                            </div>
                            <div
                                onClick={this.chooseChoice.bind(this)}
                                className="waves-effect waves-light btn-large btn-success"
                            >
                                Confirmar
                            </div>
                        </div>
                    </div>
                </div>               
            </CSSTransition>
        );
    }

    chooseChoice() {
        this.props.history.push("/carregando");        
    }

    handleClickBack() {
        let back = JSON.parse(sessionStorage.getItem("BackPage"));
        if (back !== null) {
            if (back[back.length - 2] === "/carregando") {
                sessionStorage.setItem("Action", "NextQuest");
            }
            this.props.history.push(back[back.length - 2]);
            sessionStorage.setItem("ActualPage", back.pop());
            if (back.length === 0) {
                back.push("/");
            }
            sessionStorage.setItem("BackPage", JSON.stringify(back));
        }
    }
}

export default Resume;
