import React, { Component } from "react";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import { backPage, topFunction } from "../App";

class Contacts extends Component {
    constructor(props) {
        super(props);
        const contacts = JSON.parse(
            sessionStorage.getItem("ContactDescription")
                ? sessionStorage.getItem("ContactDescription")
                : []
        );

        this.state = {
            contacts,
        };
        props.keep();
        backPage(this);
        try {
            let ArrayQuestions = JSON.parse(
                sessionStorage.getItem("ArrayQuestions")
            );
            if (
                ArrayQuestions[ArrayQuestions.length - 1] !==
                ArrayQuestions[ArrayQuestions.length - 2]
            ) {
                ArrayQuestions.push(ArrayQuestions[ArrayQuestions.length - 1]);
                sessionStorage.setItem(
                    "ArrayQuestions",
                    JSON.stringify(ArrayQuestions)
                );
            }
            sessionStorage.setItem("NoAllowOrigin", "True");
        } catch (error) {
            console.log("");
        }
    }

    componentDidMount() {
        setInterval(() => {
            if (sessionStorage.getItem("Logo") === null) {
                this.props.history.push(`/`);
            }
        }, 1000);
    }

    render() {
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div>
                    <div className="content-header">
                        <div className="persona-header">
                            <div className="persona-header__content">
                                <h3 className="persona-header__title">
                                    Contatos
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="question">
                            <div
                                style={
                                    sessionStorage.getItem("GenericError") ===
                                    "True"
                                        ? { display: "block" }
                                        : { display: "none" }
                                }
                            >
                                <h2 className="question-color-text text-center">
                                    Ocorreu um Erro
                                </h2>
                            </div>
                            <div
                                style={
                                    sessionStorage.getItem("SpecificError") !==
                                    ""
                                        ? { display: "block" }
                                        : { display: "none" }
                                }
                            >
                                <h2 className="question-color-text text-center">
                                    {sessionStorage.getItem("SpecificError") !==
                                    "Arvore de decisão não encontrada para o Item Coberto informado"
                                        ? sessionStorage.getItem(
                                              "SpecificError"
                                          )
                                        : "Não conseguimos continuar por aqui, por favor entre em contato com a central de atendimento"}
                                </h2>
                            </div>
                            <div>
                                <h2 className="question-color-text contacts">
                                    {sessionStorage.getItem("ContactError") ===
                                    "True" || this.state.contacts.length == 0
                                        ? "A qualquer momento entre em contato com a nossa central."
                                        : "A qualquer momento entre em contato com a Assistência 24 horas:"}
                                </h2>
                            </div>
                            <div className={"contact-container"}>
                                {this.state.contacts.map((contact, cont) =>
                                    this.generateContacts(contact, cont)
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        className="container__div__footer"
                        style={
                            sessionStorage.getItem("SpecificError") === "" &&
                            sessionStorage.getItem("GenericError") === ""
                                ? { display: "none" }
                                : { display: "flex" }
                        }
                    >
                        <div
                            onClick={this.goHome.bind(this)}
                            className="waves-effect waves-light btn-large btn-success"
                        >
                            <i className="fa fa-lg fa-home margin__button-big" />{" "}
                            Voltar ao Inicio
                        </div>
                    </div>
                </div>
            </CSSTransition>
        );
    }

    goHome() {
        let WaletSize = parseInt(sessionStorage.getItem("WaletSize"));
        if (WaletSize > 1) {
            document.getElementById("back-icon").style.display = "none";
            this.props.history.push(`/`);
        } else {
            document.getElementById("back-icon").style.display = "none";
            this.props.history.push(`/Login`);
        }
        topFunction();
    }

    generateContacts(contactDescription, cont) {
        let result;
        switch (contactDescription.tpcontato) {
            case "ema":
                const mailTo = `mailto:${contactDescription.contato}`;
                result = (
                    <div className={"contact-item"} key={cont}>
                        <a
                            className={"contact-title contact-item"}
                            href={mailTo}
                        >
                            {contactDescription.contato}
                        </a>{" "}
                        <span className={"contact-description contact-item"}>
                            ( {contactDescription.legenda} )
                        </span>
                    </div>
                );
                break;
            case "tel":
                const tel = `tel:+${contactDescription.contato}`;
                result = (
                    <div className={"contact-item"} key={cont}>
                        <a className={"contact-title contact-item"} href={tel}>
                            {contactDescription.contato}
                        </a>{" "}
                        <span className={"contact-description contact-item"}>
                            ( {contactDescription.legenda} )
                        </span>
                    </div>
                );
                break;
            case "url":
                result = (
                    <div className={"contact-item"} key={cont}>
                        <a
                            href={contactDescription.contato}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={"contact-title contact-item"}
                        >
                            {contactDescription.urlimgcontato != undefined && contactDescription.urlimgcontato != null ?
                            <img src={contactDescription.urlimgcontato}/>
                            : 
                            contactDescription.contato}
                        </a>{" "}
                        <span className={"contact-description contact-item"}>
                            ( {contactDescription.legenda} )
                        </span>
                    </div>
                );
                break;
            default:
                console.log(contactDescription.tpcontato);
                break;
        }
        return result;
    }
}

export default Contacts;
