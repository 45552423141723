import React, { Component } from "react";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import { backPage, importStyle, topFunction } from "../App";
import Loader from "react-loader-spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class AddressOrigin extends Component {
    constructor(props) {
        super(props);

        var stylePerso = [];
        for (let i = 0; i < sessionStorage.length; i++) {
            if (sessionStorage.key(i).includes("--")) {
                stylePerso.push(sessionStorage.key(i));
                stylePerso.push(sessionStorage.getItem(sessionStorage.key(i)));
            }
        }
        importStyle(stylePerso);

        this.state = {
            errorMessage: false,
            text: `${process.env.REACT_APP_PUSH_TEMPO_FRAME}AddressOrigin.html`,
            url: "AddressDestiny",
            loading: true,
            modalShow: false,
        };
        this.getMaps = this.getMaps.bind(this);
        backPage(this);
    }

    componentDidMount() {
        sessionStorage.setItem("Action", "GoToPersonalInformation");
        document.body.style.position = "fixed";
        document.body.style.width = "100%";
        if (sessionStorage.getItem("NoAllowOrigin") !== "True") {
            this.props.history.push("/Login");
        }
        this.checkAssistType();
    }

    componentWillUnmount() {
        document.body.style.position = "unset";
        document.body.style.width = "unset";
    }

    checkAssistType() {
        const SelectedWaletId = sessionStorage.getItem("SelectedWaletId");
        if (SelectedWaletId === "2") {
            document.getElementById("confirm-btn").style.display = "flex";
        }
    }

    render() {
        const { text } = this.state;
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div>
                    <div id={"loader"} className="loader__position">
                        <Loader
                            type="TailSpin"
                            color="#FFFFFF"
                            height="100"
                            width="100"
                        />
                    </div>
                    <section id={"content"} style={{ display: "none" }}>
                        <div>
                            <iframe
                                id="iframe"
                                onLoad={this.getMaps}
                                title="Maps"
                                className="iframe"
                                src={text}
                            />
                        </div>
                        <div
                            id={"confirm-btn"}
                            onClick={this.clickEvent.bind(this)}
                            className="container__div__footer--maps row"
                            style={{ display: "none" }}
                        >
                            <div className="map__button waves-effect waves-light btn-large btn-success">
                                <i className="fa fa-lg fa-check margin__button-big" />{" "}
                                Confirmar
                            </div>
                        </div>
                    </section>
                    <MyVerticallyCenteredModal
                        show={this.state.modalShow}
                        onHide={() => this.setState({ modalShow: false })}
                    />
                </div>
            </CSSTransition>
        );
    }

    getMaps() {
        let url_string__logo = window.location.href;
        let url = new URL(url_string__logo);
        let c = url.searchParams.get("c");
        let DestinyRequired = sessionStorage.getItem("DestinyRequired");
        try {
            if (DestinyRequired === "True") {
                if (c === "1") {
                    this.setState({
                        text:
                            process.env.REACT_APP_PUSH_TEMPO_FRAME +
                            "AddressOrigin.html?c=1",
                    });
                    this.setState({ url: "AddressDestiny?c=1" });
                } else {
                    if (c === "2") {
                        this.setState({
                            text:
                                process.env.REACT_APP_PUSH_TEMPO_FRAME +
                                "AddressOrigin.html?c=2",
                        });
                        this.setState({ url: "AddressDestiny?c=2" });
                    } else {
                        if (c === "3") {
                            this.setState({
                                text:
                                    process.env.REACT_APP_PUSH_TEMPO_FRAME +
                                    "AddressOrigin.html?c=3",
                            });
                            this.setState({ url: "AddressDestiny?c=3" });
                        } else {
                            this.setState({
                                text:
                                    process.env.REACT_APP_PUSH_TEMPO_FRAME +
                                    "AddressOrigin.html",
                            });
                            this.setState({ url: "AddressDestiny" });
                        }
                    }
                }
            } else {
                if (c === "1") {
                    this.setState({
                        text:
                            process.env.REACT_APP_PUSH_TEMPO_FRAME +
                            "AddressOrigin.html?c=1",
                    });
                    this.setState({ url: "AddressOrigin?c=1" });
                } else {
                    if (c === "2") {
                        this.setState({
                            text:
                                process.env.REACT_APP_PUSH_TEMPO_FRAME +
                                "AddressOrigin.html?c=2",
                        });
                        this.setState({ url: "AddressOrigin?c=2" });
                    } else {
                        if (c === "3") {
                            this.setState({
                                text:
                                    process.env.REACT_APP_PUSH_TEMPO_FRAME +
                                    "AddressOrigin.html?c=3",
                            });
                            this.setState({ url: "AddressOrigin?c=3" });
                        } else {
                            this.setState({
                                text:
                                    process.env.REACT_APP_PUSH_TEMPO_FRAME +
                                    "AddressOrigin.html",
                            });
                            this.setState({ url: "AddressOrigin" });
                        }
                    }
                }
            }
        } catch (error) {
            console.log("");
        }

        try {
            document.getElementById("loader").style.display = "none";
            document.getElementById("content").style.display = "block";
            setTimeout(function () {
                document.getElementById("content").style.display = "unset";
            }, 500);
        } catch (e) {
            console.log(e);
        }

        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("autocomplete")
            .addEventListener("keyup", () => {
                let valueAutoComplete = document
                    .getElementById("iframe")
                    .contentWindow.document.getElementById("autocomplete")
                    .value;
                if (valueAutoComplete === "") {
                    document.getElementById("confirm-btn").style.display =
                        "none";
                    document
                        .getElementById("iframe")
                        .contentWindow.document.getElementById(
                            "use-location"
                        ).style.display = "block";
                } else {
                    document.getElementById("confirm-btn").style.display =
                        "flex";
                }
            });

        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("not-found")
            .addEventListener("click", () => {
                document.getElementById("confirm-btn").style.display = "none";
            });

        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("use-location")
            .addEventListener("click", () => {
                this.checkText();
            });

        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("street--input")
            .addEventListener("keyup", () => {
                let valueStreet = document
                    .getElementById("iframe")
                    .contentWindow.document.getElementById("street--input")
                    .value;
                if (valueStreet === "") {
                    document.getElementById("confirm-btn").style.display =
                        "none";
                } else {
                    document.getElementById("confirm-btn").style.display =
                        "flex";
                }
            });
    }

    checkText() {
        let valueAutoComplete = document
            .getElementById("iframe")
            .contentWindow.document.getElementById("autocomplete").value;
        if (valueAutoComplete !== "") {
            document.getElementById("confirm-btn").style.display = "flex";
        } else {
            setTimeout(() => {
                this.checkText();
            }, 500);
        }
    }

    clickEvent() {
        // let isValid = this.validateFields();
        // if (!isValid) {
        //     alert('Por favor, preencha todos os campos obrigatórios.');
        //     return;
        // }
        try {
            if (this.isWalletAuto()) {
                this.SendDataAuto();
            } else {
                this.SendDataResid();
            }
        } catch (e) {
            console.log(e);
        }
    }

    validateFields() {
        let name = this.checkField("neighborhood--input");
        if (name) {
            this.setState({
                errorMessage: false,
            });
            return true;
        } else {
            this.setState({
                errorMessage: true,
            });
            return false;
        }
    }

    checkField(field) {
        let fieldValue = document
            .getElementById("iframe")
            .contentWindow.document.getElementById(field).value;
        if (fieldValue === "") {
            return false;
        } else {
            return true;
        }
    }

    isWalletAuto() {
        let SelectedWaletId = sessionStorage.getItem("SelectedWaletId");
        if (SelectedWaletId === "1") {
            return true;
        } else {
            return false;
        }
    }

    SendDataAuto() {
        let isAddressChecked = this.checkAddress();
        let isLongLat = this.checkLongLat();
        if (isAddressChecked || isLongLat) {
            if (isAddressChecked && !isLongLat) {
                if (
                    document
                        .getElementById("iframe")
                        .contentWindow.document.getElementById("street_number")
                        .value === ""
                ) {
                    this.errorHandle();
                } else {
                    var isOmni = sessionStorage.getItem("FlagPreAssist");
                    if(isOmni == 'True'){
                       this.preAssistOrigin();
                       if (sessionStorage.getItem("DestinyRequired") === "True") {
                            sessionStorage.setItem("Action", "Assist");
                            this.props.history.push(`/Carregando`);
                        } else {
                            sessionStorage.setItem("Action", "Assist");
                            this.props.history.push(`/resumo`);
                        }
                        
                    }else{
                        if (sessionStorage.getItem("DestinyRequired") === "True") {
                            sessionStorage.setItem("Action", "Assist");
                            this.props.history.push(`/Carregando`);
                        } else {
                            sessionStorage.setItem("Action", "Assist");
                            this.props.history.push(`/resumo`);
                        }
                    }
                }
            } else {
                var isOmni = sessionStorage.getItem("FlagPreAssist");
                if(isOmni == 'True'){
                   this.preAssistOrigin();
                }
                if (sessionStorage.getItem("DestinyRequired") === "True") {
                    sessionStorage.setItem("Action", "Assist");
                    this.props.history.push(`/Carregando`);
                } else {
                    sessionStorage.setItem("Action", "Assist");
                    this.props.history.push(`/resumo`);
                }
            }
        } else {
            this.errorHandle();
        }
    }

    SendDataResid() {
        let isAddressChecked = this.checkAddress();
        if (isAddressChecked) {
            if (
                document
                    .getElementById("iframe")
                    .contentWindow.document.getElementById("street_number")
                    .value === ""
            ) {
                this.errorHandle();
            } else {
                var isOmni = sessionStorage.getItem("FlagPreAssist");
                if(isOmni == 'True'){
                    this.preAssistOrigin();
                    // if(omni == 'True'){
                        sessionStorage.setItem("Action", "Assist");
                        this.props.history.push(`/resumo`);
                        // this.props.history.push(`/Carregando`);
                    // }else{
                    //     this.errorHandle();
                    // }
                }else{
                    sessionStorage.setItem("Action", "Assist");
                    this.props.history.push(`/resumo`);
                    // this.props.history.push(`/Carregando`);
                }
            }
        } else {
            this.errorHandle();
        }
    }

    numberValidator(type) {
        if (
            sessionStorage.getItem(type + "Number") === "" &&
            sessionStorage.getItem(type + "Latitude") !== "" &&
            sessionStorage.getItem(type + "Longitude") !== ""
        ) {
            return "Vazio";
        } else {
            return sessionStorage.getItem(type + "Number");
        }
    }

    preAssistOrigin() {
        const url =
        process.env.REACT_APP_API_URL_TEMPO +
        "/pre-assistencia/endereco-origem";

        let body = {
            "preNovoServicoAssistencia":{
                "idAssistencia": sessionStorage.getItem("PreAssistId")
            },
            "enderecoCompletoOrigem": {
                "uf": sessionStorage.getItem("OriginState"),
                "cep": sessionStorage.getItem("OriginCep"),
                "bairro": sessionStorage.getItem("OriginNeighborhood"),
                "cidade": sessionStorage.getItem("OriginCity"),
                "numero": this.numberValidator("Origin"),
                "logradouro": sessionStorage.getItem("OriginStreet"),
                "complemento": sessionStorage.getItem("OriginComplement"),
                "isCondominio": "F",
            },
        };
        
        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
                "Content-Type": "application/json",
                "X-JuvoTransacaoID": sessionStorage.getItem("juvoTransactionID")
            }),
            method: "put",
            body: JSON.stringify(body),
        })
            .then((response) => {
                try {
                    return response.json();
                } catch (error) {
                    return Promise.resolve({});
                }
            })
            .then((response) => {
                if (response.idAssistencia) {
                    return 'True';
                }else{
                    return 'False';
                } 
            })
            .catch((error) => {
                return 'False';
            });
    }

    checkAddress() {
        if (
            document
                .getElementById("iframe")
                .contentWindow.document.getElementById(
                    "administrative_area_level_1"
                ) != null
        ) {
            if (
                document
                    .getElementById("iframe")
                    .contentWindow.document.getElementById("street--input")
                    .value === ""
            ) {
                if (
                    document
                        .getElementById("iframe")
                        .contentWindow.document.getElementById(
                            "administrative_area_level_1"
                        ).value !== "" &&
                    document
                        .getElementById("iframe")
                        .contentWindow.document.getElementById("route")
                        .value !== ""
                ) {
                    sessionStorage.setItem(
                        "OriginNumber",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "street_number"
                            ).value
                    );
                    sessionStorage.setItem(
                        "OriginComplement",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "complement-id"
                            ).value
                    );
                    sessionStorage.setItem(
                        "OriginReference",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "reference-id"
                            ).value
                    );
                    sessionStorage.setItem(
                        "OriginStreet",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById("route")
                            .value
                    );
                    sessionStorage.setItem(
                        "OriginCity",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "administrative_area_level_2"
                            ).value
                    );
                    sessionStorage.setItem(
                        "OriginState",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "administrative_area_level_1"
                            ).value
                    );
                    sessionStorage.setItem(
                        "OriginNeighborhood",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "sublocality_level_1"
                            ).value
                    );
                    sessionStorage.setItem(
                        "OriginCep",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "postal_code"
                            ).value
                    );
                    topFunction();
                    return true;
                } else {
                    return false;
                }
            } else {
                if (this.isAddressFilled() || this.isWalletAuto()) {
                    sessionStorage.setItem(
                        "OriginNumber",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "street_number"
                            ).value
                    );
                    sessionStorage.setItem(
                        "OriginComplement",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "complement-id"
                            ).value
                    );
                    sessionStorage.setItem(
                        "OriginReference",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "reference-id"
                            ).value
                    );
                    sessionStorage.setItem(
                        "OriginStreet",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "street--input"
                            ).value
                    );
                    sessionStorage.setItem(
                        "OriginCity",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "city--input"
                            ).value
                    );
                    sessionStorage.setItem(
                        "OriginState",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "state--input"
                            ).value
                    );
                    sessionStorage.setItem(
                        "OriginNeighborhood",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "neighborhood--input"
                            ).value
                    );
                    sessionStorage.setItem(
                        "OriginCep",
                        document
                            .getElementById("iframe")
                            .contentWindow.document.getElementById(
                                "postal_code"
                            ).value
                    );
                    return true;
                } else {
                    return false;
                }
            }
        }
    }

    checkLongLat() {
        let latitude = document
            .getElementById("iframe")
            .contentWindow.document.getElementById("latitude").value;

        let longitude = document
            .getElementById("iframe")
            .contentWindow.document.getElementById("longitude").value;

        if (latitude !== "" && longitude !== "") {
            sessionStorage.setItem("OriginLatitude", latitude);
            sessionStorage.setItem("OriginLongitude", longitude);
            return true;
        } else {
            return false;
        }
    }

    errorHandle() {
        this.setState({
            modalShow: true,
        });
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "error-message"
            ).style.display = "block";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "locationField"
            ).style.border = "solid 1px red";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "autocomplete"
            ).style.borderBottom = "solid 1px red";
    }

    isAddressFilled() {
        if (
            document
                .getElementById("iframe")
                .contentWindow.document.getElementById("neighborhood--input")
                .value !== "" &&
            document
                .getElementById("iframe")
                .contentWindow.document.getElementById("city--input").value !==
                "" &&
            document
                .getElementById("iframe")
                .contentWindow.document.getElementById("state--input").value !==
                ""
        ) {
            return true;
        } else {
            return false;
        }
    }
}

function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Aviso
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Não localizamos as informações necessárias no endereço
                    relatado. Você poderá inserir as informações que restam ou
                    informar um novo endereço no mapa.
                </p>
            </Modal.Body>
            <Modal.Footer className={"modal-footer-breakline"}>
                {sessionStorage.getItem("SelectedWaletId") !== "2" && (
                    <Button
                        onClick={() => closeModalNewAddress()}
                        to="carregando"
                        className="waves-effect waves-light btn-large btn-success"
                    >
                        Novo endereço
                    </Button>
                )}

                <Button
                    onClick={() => closeModal()}
                    to="carregando"
                    className="waves-effect waves-light btn-large btn-danger"
                >
                    Inserir as informações
                </Button>
            </Modal.Footer>
        </Modal>
    );

    function closeModal() {
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("autocomplete").value = "";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("not-found").style.display =
            "none";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "locationField"
            ).style.display = "none";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("street").style.display =
            "block";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "neighborhood"
            ).style.display = "block";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("city").style.display =
            "block";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("state").style.display =
            "block";
        props.onHide();
    }

    function closeModalNewAddress() {
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("autocomplete").value = "";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("street_number").value = "";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "street_number"
            ).placeholder = "Número";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "error-message"
            ).style.display = "none";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "locationField"
            ).style.border = "solid 1px";
        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "autocomplete"
            ).style.borderBottom = "unset";

        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "locationField"
            ).style.display = "block";

        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("street").style.display =
            "none";

        document
            .getElementById("iframe")
            .contentWindow.document.getElementById(
                "neighborhood"
            ).style.display = "none";

        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("city").style.display =
            "none";

        document
            .getElementById("iframe")
            .contentWindow.document.getElementById("state").style.display =
            "none";

        props.onHide();
    }
}

export default AddressOrigin;
