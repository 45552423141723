import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import { backPage, topFunction } from "../App";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";

class CoveredItem extends Component {
    constructor(props) {
        super(props);

        const numbers = [];

        for (var i = 0; i < sessionStorage.length; i++) {
            if (sessionStorage.key(i).includes("ConveredItem")) {
                numbers.push(sessionStorage.getItem(sessionStorage.key(i)));
            }
        }

        const SelectedWaletId = sessionStorage.getItem("SelectedWaletId");
        this.state = {
            numbers,
            SelectedWaletId
        };

        if (numbers.length <= 1) {
            this.props.history.push(`/Login`);
        }
        props.keep();
        backPage(this);
    }


    async componentDidMount() {
        document.getElementById("back-icon").style.display = "block";
        if(sessionStorage.getItem("apolicelogin") != undefined){
            this.state.numbers.map((item, index) => {
                if(sessionStorage.getItem("PolicyNumber" + index) == sessionStorage.getItem("apolicelogin")){
                    this.ConsultTree(index);
                }
            });
        }
    }

    render() {
        let modalClose = () => this.setState({ modalShow: false });
        return (
            <CSSTransition
                in={sessionStorage.getItem("cpflogin") ? false : true}
                appear={sessionStorage.getItem("cpflogin") ? false : true}
                timeout={sessionStorage.getItem("cpflogin") ? false : 800}
                classNames={sessionStorage.getItem("cpflogin") ? "" : "fade"}
            >
                <div>
                <div className="loader__position" style={{ display: sessionStorage.getItem("cpflogin") ? "block" : "none", textAlign: "center" }}>
                        <div className="lds-ring">
                            <div
                                style={
                                    this.state.bgColor !== "#FFFFFF" ||
                                        this.state.bgColor !== "#FFF" ||
                                        this.state.bgColor !== "#ffffff" ||
                                        this.state.bgColor !== "#fff"
                                        ? {
                                            borderColor:
                                                "#DDDDDD transparent transparent transparent",
                                        }
                                        : {
                                            borderColor:
                                                "#FFFFFF transparent transparent transparent",
                                        }
                                }
                            ></div>
                            <div
                                style={
                                    this.state.bgColor !== "#FFFFFF" ||
                                        this.state.bgColor !== "#FFF" ||
                                        this.state.bgColor !== "#ffffff" ||
                                        this.state.bgColor !== "#fff"
                                        ? {
                                            borderColor:
                                                "#DDDDDD transparent transparent transparent",
                                        }
                                        : {
                                            borderColor:
                                                "#FFFFFF transparent transparent transparent",
                                        }
                                }
                            ></div>
                            <div
                                style={
                                    this.state.bgColor !== "#FFFFFF" ||
                                        this.state.bgColor !== "#FFF" ||
                                        this.state.bgColor !== "#ffffff" ||
                                        this.state.bgColor !== "#fff"
                                        ? {
                                            borderColor:
                                                "#DDDDDD transparent transparent transparent",
                                        }
                                        : {
                                            borderColor:
                                                "#FFFFFF transparent transparent transparent",
                                        }
                                }
                            ></div>
                            <div
                                style={
                                    this.state.bgColor !== "#FFFFFF" ||
                                        this.state.bgColor !== "#FFF" ||
                                        this.state.bgColor !== "#ffffff" ||
                                        this.state.bgColor !== "#fff"
                                        ? {
                                            borderColor:
                                                "#DDDDDD transparent transparent transparent",
                                        }
                                        : {
                                            borderColor:
                                                "#FFFFFF transparent transparent transparent",
                                        }
                                }
                            ></div>
                        </div>
                    </div>
                <div style={{ display: sessionStorage.getItem("cpflogin") ? "none" : "block" }}>
                    <div className="content-header">
                        <div className="persona-header">
                            <div className="persona-header__content">
                                <h3 className="persona-header__title">
                                    Para solicitar ou acompanhar uma assistência, selecione a apólice:
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="row center"></div>
                        <div
                            id="home-wallet"
                            className="row row__cards--display"
                        >
                            <Row>
                                {this.state.numbers.map((number, id) => (
                                    <Col
                                        onClick={this.ConsultTree.bind(
                                            this,
                                            id
                                        )}
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        large={6}
                                        xl={6}
                                        className="col s6 m6 l4"
                                        key={number.toString()}
                                    >
                                        <NavLink
                                            to="itens-cobertos"
                                            className="card-link"
                                        >
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 16px' }} className="card card--sant">
                                                {/* <div className="card-image">
                                                    <img
                                                        alt="Veiculos"
                                                        className="card__img"
                                                        src={
                                                            this.state
                                                                .SelectedWaletId ===
                                                            "1"
                                                                ? sessionStorage.getItem(
                                                                      "HomeAutomóvel"
                                                                  )
                                                                : sessionStorage.getItem(
                                                                      "HomeResidência"
                                                                  )
                                                        }
                                                    />
                                                </div> */}
                                                <div style={{ height: 'auto', marginBottom: 0 }} className="card-content card-wallet">
                                                    <p>
                                                        {this.Capitalize(sessionStorage.getItem(
                                                            "ConveredItem" + id
                                                        ), true)}
                                                    </p>
                                                    {sessionStorage.getItem("SelectedWalet") == 'Residência' ? (
                                                        <p>
                                                            {`CEP ${sessionStorage.getItem(
                                                                "SelectedCep" + id
                                                            )}`}
                                                        </p>
                                                    ): ''}
                                                    {sessionStorage.getItem("SelectedWalet") == 'Residência' ? (
                                                    <p>
                                                        {`${this.Capitalize(sessionStorage.getItem(
                                                            "SelectedCity" + id
                                                        ), true)} - ${sessionStorage.getItem(
                                                            "SelectedUf" + id
                                                        )}`}
                                                    </p>
                                                     ): ''}
                                                    <h2
                                                        id={sessionStorage.getItem(
                                                            "ConveredId" + id
                                                        )}
                                                        className={
                                                            "itemClicked" + id
                                                        }
                                                    >
                                                        {`Nº Apólice: ${sessionStorage.getItem("PolicyNumber" + id)}`}
                                                    </h2>
                                                </div>
                                            </div>
                                            <span style={{ display: "none" }}>
                                                {(id = id + 1)}
                                            </span>
                                        </NavLink>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                    <MyVerticallyCenteredModal
                        show={this.state.modalShow}
                        onHide={modalClose}
                        destiny={this.state.destiny}
                        history={this.props.history}
                    />
                </div>
                </div>
            </CSSTransition>
        );
    }

    Capitalize(str, lower = false) {
        if(str == null){
            return '';
        }
        return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
    };

    ConsultTree(ID) {
        let fullAddress = sessionStorage.getItem("ConveredItem" + ID);
        let city = sessionStorage.getItem("SelectedCity" + ID);
        let address = sessionStorage.getItem("SelectedAddress" + ID);
        let uf = sessionStorage.getItem("SelectedUf" + ID);
        let plan = sessionStorage.getItem("ConveredPlanItem" + ID);
        var itemClicked = document.getElementsByClassName("itemClicked" + ID)[0]
            .id;
        
        var url_string__logo = window.location.href;
        var params = new URL(url_string__logo);
        let redirectTo = params.searchParams.get("rdct");
        if(redirectTo == 'extrato'){
            params.searchParams.delete("rdct");
            window.history.replaceState({}, '', params);
            sessionStorage.setItem("Action", "AssistStatements");
            this.props.history.push("/carregando");
        }else if(redirectTo == 'acompanhar'){
            params.searchParams.delete("rdct");
            window.history.replaceState({}, '', params);
            sessionStorage.setItem("Action", "Accompaniment");
            this.props.history.push("/carregando");
        }else{
            this.verifyOpenAssistence(itemClicked);
        }
        
        sessionStorage.setItem("ConveredSelectedItem", itemClicked);
        
        if(plan != null && plan.length > 0){
            var plans = sessionStorage.getItem("planos");
            plans = JSON.parse(plans);

            if(plans != null){
                Object.keys(plans).map((e, i) => {
                    if(plans[e].plano == plan){
                        sessionStorage.setItem("ConveredSelectedPlanToken", plans[e].tokenplano);
                        sessionStorage.setItem("ConveredSelectedPlanChave", plans[e].chaveplano);
                        sessionStorage.setItem("ConveredSelectedPlanData", JSON.stringify(plans[e]));
                    }
                });
            }

            sessionStorage.setItem("ConveredSelectedPlan", plan);
        }
        const policy = `Nº Apólice: ${sessionStorage.getItem("PolicyNumber" + ID)}`;
        let itemdata = '';
        if(sessionStorage.getItem("SelectedWalet") == 'Automóvel'){
            itemdata = `PLACA ${sessionStorage.getItem("ConveredItem" + ID).toUpperCase()}`;
        }else{ 
            itemdata = this.Capitalize(sessionStorage.getItem("ConveredItem" + ID), true);
        }
        sessionStorage.setItem("AssistStatementAddress", itemdata);
        sessionStorage.setItem("AssistStatementPolicy", policy);
        sessionStorage.setItem("AddressResidcidade", city);
        sessionStorage.setItem("AddressResidlogradouro", address);
        sessionStorage.setItem("AddressResiduf", uf);
        sessionStorage.setItem("AddressMapsIdentifier", fullAddress);
        const url =
            process.env.REACT_APP_API_URL_TEMPO +
            "/questionarioAssistencia/existeArvoreDecisao/" +
            itemClicked;

        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: "1",
                Authorization: sessionStorage.getItem("Authorization")
            })
        })
            .then(response => response.json())
            .then(response => {
                sessionStorage.setItem(
                    "juvoTransactionID",
                    response.juvoTransactionID
                );
                this.requestFirstQuestion(itemClicked);
            });
        topFunction();
    }

    verifyOpenAssistence(IdItemCoberto) {
        const urlHours =
            process.env.REACT_APP_API_URL_JUVO +
            "/assistenciaServico/segurado?idItemCoberto=" +
            IdItemCoberto +
            "&notInStatus=3&notInStatus=4&notInStatus=5&notInStatus=6&notInStatus=2"
        fetch(urlHours, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: "1",
                Authorization: sessionStorage.getItem("Authorization")
            })
        })
            .then(response => response.json())
            .then(response => {
                sessionStorage.setItem("Action", "ConsultTree");
                if(Object.values(response).length > 0 && response.codigo !== 999){
                    this.setState({ modalShow: true });
                }else{
                    this.props.history.push("/carregando");
                }
            })
            .catch(error => {
                sessionStorage.setItem("Action", "ConsultTree");
                this.props.history.push("/carregando");
            });
    }

    requestFirstQuestion(idItemCoberto) {
        const url =
            process.env.REACT_APP_API_URL_TEMPO +
            "/questionarioAssistencia/arvoreDecisao/" +
            idItemCoberto +
            "/1/false";

        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: "1",
                Authorization: sessionStorage.getItem("Authorization")
            })
        })
            .then(response => response.json())
            .then(response => {
                // console.log(response);
                // sessionStorage.setItem("juvoTransactionID",response.juvoTransactionID)
            });
    }
}

class MyVerticallyCenteredModal extends React.Component {
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                onHide={() => { this.onCloseModal(this.props.history) }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        AVISO
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Há assistência em andamento, deseja acompanhar?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <NavLink
                        onClick={function (event) {
                            handleClick();
                        }}
                        to="carregando"
                        className="waves-effect waves-light btn-large btn-success"
                    >
                        <i className="fa fa-lg fa-check container__button--margin " />{" "}
                        Sim
                    </NavLink>
                    <NavLink
                        onClick={function (event) {
                            topFunction();
                        }}
                        to={"carregando"}
                        className="waves-effect waves-light btn-large btn-danger"
                    >
                        <i className="fa fa-lg fa-times container__button--margin " />{" "}
                        Não
                    </NavLink>
                </Modal.Footer>
            </Modal>
        );
    }

    onCloseModal(history) {
        topFunction();
        history.push(`/carregando`);
    }
}

function handleClick() {
    sessionStorage.setItem("Action", "Accompaniment");
    topFunction();
}

export default CoveredItem;
