import React from 'react';
import CSSTransition from "react-transition-group/esm/CSSTransition";
import Carousel from "../../Carousel";

const Gas = () => {
    return(
        <CSSTransition
            in={true}
            appear={true}
            timeout={800}
            classNames="fade"
        >
            <div>
                <div className="content-header">
                    <div className="persona-header" >
                        <div className="persona-header__content">
                            <h3 className="persona-header__title">Quanto de bateria você tem?</h3>
                        </div>
                    </div>
                </div>
                <div className="spacer"></div>
                <div className="container">
                    <div className="question">
                        <div className="question-ans">
                            <Carousel></Carousel>
                        </div>
                    </div>

                </div>
            </div>
        </CSSTransition>
    );
};

export default Gas;