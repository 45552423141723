import React, { Component } from "react";
import { backPage, topFunction } from "../../App";
import CSSTransition from "react-transition-group/esm/CSSTransition";

var numbers = ["Question1"];

class SurplusValue extends Component {
    constructor(props) {
        super(props);

        let accessPage = JSON.parse(sessionStorage.getItem("BackPage"))[
            JSON.parse(sessionStorage.getItem("BackPage")).length - 1
        ];

        if (
            accessPage !== "/AddressOrigin" &&
            accessPage !== "/AddressDestiny"
        ) {
            this.props.history.push("/");
            return;
        }
        const ConveredSelectedItem = sessionStorage.getItem(
            "ConveredSelectedItem"
        );
        const url =
            process.env.REACT_APP_API_URL_JUVO +
            "/assistenciaServico/segurado?idItemCoberto=" +
            ConveredSelectedItem +
            "&notInStatus=3&notInStatus=4&notInStatus=5&notInStatus=6";

        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization")
            })
        })
            .then(response => response.json())
            .then(response => {
                this.state = {
                    idAssistServico: response[0].servicos[0].idAssistServico,
                    idPrestador: response[0].servicos[0].prestador.idPrestador
                };
            })
            .catch(error => {
                console.log(error);
            });

        backPage(this);
        props.keep();
    }
    render() {
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div>
                    {numbers.map((number, cont) => (
                        <div key={number.toString()}>
                            <div className="content-header">
                                <div className="persona-header">
                                    <div className="persona-header__content">
                                        <h3 className="persona-header__title">
                                            Atenção!
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="spacer" />
                            <div className="container">
                                <div className="question">
                                    <h3 className="persona-header__title--alert">
                                        Sua solicitação foi parcialmente
                                        realizada. Houve um valor excedente de
                                        R${" "}
                                        {sessionStorage.getItem("SurplusValue")}
                                        . Deseja aceitar a solicitação com o
                                        acréscimo ?{" "}
                                    </h3>
                                    <div className="question-ans">
                                        <div
                                            onClick={this.chooseChoice.bind(
                                                this,
                                                1
                                            )}
                                            className="waves-effect waves-light btn-large btn-danger"
                                        >
                                            <i className="fa fa-lg fa-times container__button--margin " />
                                            Não
                                        </div>
                                        <div
                                            onClick={this.chooseChoice.bind(
                                                this,
                                                0
                                            )}
                                            className="waves-effect waves-light btn-large btn-success"
                                        >
                                            <i className="fa fa-lg fa-check container__button--margin" />
                                            Sim
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </CSSTransition>
        );
    }

    chooseChoice(id) {
        if (id === 1) {
            sessionStorage.setItem("Action", "GoToContact");
            this.props.history.push("/carregando");
        } else {
            const url =
                process.env.REACT_APP_API_URL_JUVO +
                "/assistenciaServico/aceiteExcedente/" +
                this.state.idAssistServico +
                "/" +
                this.state.idPrestador;

            fetch(url, {
                method: "post",
                headers: new Headers({
                    token: sessionStorage.getItem("token"),
                    chave: sessionStorage.getItem("chave"),
                    idclientecorporativo: sessionStorage.getItem(
                        "idclientecorporativo"
                    ),
                    idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                    Authorization: sessionStorage.getItem("Authorization")
                })
            })
                .then(response => {
                    sessionStorage.setItem("Action", "Accompaniment");
                    this.props.history.push("/carregando");
                })
                .catch(error => {
                    console.log(error);
                    sessionStorage.setItem("Action", "Accompaniment");
                    this.props.history.push("/carregando");
                });
        }
        topFunction();
    }
}

export default SurplusValue;
