import React, { Component } from "react";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import Modal from "react-bootstrap/Modal";
import { Redirect } from "react-router-dom";
import { Accompaniment } from "../controller/ControllerFunctions.js";
import {
    backPageQuestions,
    topFunction,
    GoogleAnalyticsEvent,
} from "../../App";

class SingleChoice2To6 extends Component {
    constructor(props) {
        super(props);
        let Action = sessionStorage.getItem("Action");

        const numbers = [];
        const ids = [];
        const url = [];
        const next = [];
        for (var i = 0; i < sessionStorage.length; i++) {
            if (sessionStorage.key(i).includes("QuestionText")) {
                numbers.push(numbers.length);
            }
            if (sessionStorage.key(i).includes("QuestionId")) {
                ids.push(ids.length);
            }
            if (sessionStorage.key(i).includes("QuestionUrl")) {
                url.push(url.length);
            }
            if (sessionStorage.key(i).includes("NextQuestion")) {
                next.push(next.length);
            }
        }

        for (var k = 0; k < numbers.length; k++) {
            numbers[k] =
                sessionStorage.getItem("QuestionText" + k) !== null
                    ? sessionStorage.getItem("QuestionText" + k)
                    : "";
            ids[k] = sessionStorage.getItem("QuestionId" + k);
            url[k] = sessionStorage.getItem("QuestionUrl" + k);
            next[k] = sessionStorage.getItem("NextQuestion" + k);
        }

        props.keep();
        backPageQuestions(this);
        if (
            Action !== "NextQuest" &&
            Action !== "ConsultTree" &&
            Action !== "SaveAnswer"
        ) {
            sessionStorage.setItem("Action", "NextQuest");
            props.history.push("/carregando");
            return;
        }

        if (sessionStorage.getItem("FirstQuestion") === "True") {
            sessionStorage.removeItem("FirstQuestion");
            this.verifyOpenAssistence(sessionStorage.getItem("ConveredSelectedItem"));
        }
        this.state = {
            numbers,
            ids,
            url,
            next,
            imageClass: "card-content",
            modalShow: true,
        };
    }

    componentDidMount() {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "unset";
        document.body.classList.remove("modal-open");
        document.body.classList.remove("contact-list");
        document.getElementsByClassName("modal")[0].style.display = "none";
        document.getElementsByClassName("modal-backdrop")[0].style.display =
            "none";
        document.getElementById("back-icon").style.display = "block";
    }

    render() {
        let modalClose = () => this.setState({ modalShow: false });
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div>
                    <div className="content-header">
                        <div className="persona-header">
                            <div className="persona-header__content">
                                <h3 className="persona-header__title">
                                    {sessionStorage.getItem("QuestionTitle")}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="row center"></div>
                        <div
                            id="home-wallet"
                            className="row row__cards--display"
                        >
                            <div className="flex-itens">
                                {this.state.numbers.map((number, cont) => (
                                    <section
                                        className={"section-container"}
                                        key={number.toString()}
                                    >
                                        <div className="row flex-itens-card">
                                            <div
                                                onClick={this.chooseChoice.bind(
                                                    this,
                                                    cont
                                                )}
                                                className="card-link"
                                            >
                                                <div>
                                                    <div className="card-image">
                                                        <img
                                                            onError={this.erroHandler.bind(
                                                                this,
                                                                cont
                                                            )}
                                                            id={
                                                                "errorImg" +
                                                                cont
                                                            }
                                                            alt="Veiculos"
                                                            className="card__img"
                                                            src={
                                                                this.state.url[
                                                                    cont
                                                                ]
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        id={"error-text" + cont}
                                                        className={
                                                            this.state
                                                                .imageClass
                                                        }
                                                        style={{
                                                            display: "none",
                                                        }}
                                                    >
                                                        <h2
                                                            nextquestion={
                                                                this.state.next[
                                                                    cont
                                                                ]
                                                            }
                                                            id={
                                                                this.state.ids[
                                                                    cont
                                                                ]
                                                            }
                                                            className={
                                                                "itemClicked" +
                                                                cont
                                                            }
                                                        >
                                                            {number}
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id={"error-label" + cont}
                                            className={this.state.imageClass}
                                        >
                                            <h2
                                                nextquestion={
                                                    this.state.next[cont]
                                                }
                                                id={this.state.ids[cont]}
                                                className={"itemClicked" + cont}
                                            >
                                                {number}
                                            </h2>
                                        </div>
                                    </section>
                                ))}
                            </div>
                        </div>
                    </div>
                    <MyVerticallyCenteredModal
                        show={this.state.modalShow}
                        onHide={modalClose}
                        destiny={this.state.destiny}
                    />
                </div>
            </CSSTransition>
        );
    }

    erroHandler(context, cont) {
        document.getElementById("error-text" + context).style.display = "block";
        document.getElementById("error-label" + context).style.color =
            "transparent";
        cont.target.style.display = "none";
        this.setState({
            imageClass: "card-content",
        });
    }

    chooseChoice(id) {
        var itemClicked = document.getElementsByClassName("itemClicked" + id)[0]
            .id;
        var NextQuestionSelected = document
            .getElementsByClassName("itemClicked" + id)[0]
            .getAttribute("nextquestion");
        sessionStorage.setItem("IdAnswer", itemClicked);
        sessionStorage.setItem("Action", "SaveAnswer");
        sessionStorage.setItem("NextQuestionSelected", NextQuestionSelected);
        topFunction();

        let label = this.state.numbers[id] + " - " + itemClicked;
        GoogleAnalyticsEvent(
            sessionStorage.getItem("SelectedWalet"),
            sessionStorage.getItem("AtualId"),
            label
        );
        this.props.history.push(`/Carregando`);
    }

    verifyOpenAssistence(IdItemCoberto) {
        const urlHours =
            process.env.REACT_APP_API_URL_JUVO +
            "/assistenciaServico/segurado?idItemCoberto=" +
            IdItemCoberto +
            "&notInStatus=3&notInStatus=4&notInStatus=5&notInStatus=6&notInStatus=2"
        fetch(urlHours, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if(Object.values(response).length > 0 && response.codigo !== 999){
                    document.getElementsByClassName("modal")[0].style.display =
                        "block";
                    document.getElementsByClassName(
                        "modal-backdrop"
                    )[0].style.display = "block";
                }else{
                    sessionStorage.setItem("ConveredSelectedItem", IdItemCoberto);
                    sessionStorage.setItem("Action", "ConsultTree");
                    this.props.history.push(`/carregando`);
                }
            })
            .catch((error) => {
                console.log();
                sessionStorage.setItem("ConveredSelectedItem", IdItemCoberto);
                sessionStorage.setItem("Action", "ConsultTree");
                this.props.history.push(`/carregando`);
            });
    }
}

class MyVerticallyCenteredModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            redirectLabel: "/contatos",
        };
    }

    render() {
        return this.state.redirect === true ? (
            <Redirect to={this.state.redirectLabel} push />
        ) : (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        AVISO
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Há assistência em andamento, deseja acompanhar?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        onClick={this.changeToAccompaniment.bind(this)}
                        style={{ cursor: "pointer" }}
                        className="waves-effect waves-light btn-large btn-success"
                    >
                        <i className="fa fa-lg fa-check container__button--margin " />{" "}
                        Sim
                    </div>
                    <div
                        onClick={this.changeToQuestion}
                        className="waves-effect waves-light btn-large btn-danger"
                    >
                        <i className="fa fa-lg fa-times container__button--margin " />{" "}
                        Não
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }

    async changeToAccompaniment() {
        const retorno = await Accompaniment(this.props);
        if (retorno) {
            sessionStorage.setItem("Action", "Accompaniment");
            topFunction();
            this.setState({
                redirect: true,
                redirectLabel: "/acompanhamentos",
            });
        } else {
            console.log("");
        }
    }

    changeToQuestion() {
        document.getElementsByClassName("modal")[0].style.display = "none";
        document.getElementsByClassName("modal-backdrop")[0].style.display =
            "none";
    }
}

export default SingleChoice2To6;
