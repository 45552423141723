import React, { Component } from "react";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import { backPage, topFunction } from "../../App";
import { AssistDetail } from "../controller/ControllerFunctions.js";

class Accompaniment extends Component {
    constructor(props) {
        super(props);
        const numbers = [];
        const ids = [];
        const sub = [];
        const senha = [];
        const protocol = [];
        for (var i = 0; i < sessionStorage.length; i++) {
            if (sessionStorage.key(i).includes("QuestionText")) {
                numbers.push(numbers.length);
            }
            if (sessionStorage.key(i).includes("QuestionId")) {
                ids.push(ids.length);
            }
            if (sessionStorage.key(i).includes("DescriptionAccompaniment")) {
                sub.push(sub.length);
            }
            if (sessionStorage.key(i).includes("senhaValidacao")) {
                senha.push(senha.length);
            }
            if (sessionStorage.key(i).includes("protocoloCliente")) {
                protocol.push(protocol.length);
            }
            for (var k = 0; k < numbers.length; k++) {
                numbers[k] = sessionStorage.getItem("QuestionText" + k);
                var date = new Date(
                    sessionStorage.getItem("QuestionId" + k) / 1
                );
                ids[k] = date.toLocaleString();
                sub[k] = sessionStorage.getItem("DescriptionAccompaniment" + k);
                senha[k] = sessionStorage.getItem("senhaValidacao" + k);
                protocol[k] = sessionStorage.getItem("protocoloCliente" + k);
            }
        }
        
        if (numbers.length === 0) {
            numbers.push("Você não tem assistências a serem visualizadas");
            protocol.push("Você não tem assistências a serem visualizadas");
            ids.push("-");
            sessionStorage.removeItem('ContactType0');
            sessionStorage.removeItem('DateRange0');
        }

        let flagProtocol = sessionStorage.getItem("FlagProtocolCli");

        this.state = {
            numbers,
            ids,
            sub,
            senha,
            protocol,
            flagProtocol
        };
        props.keep();
        backPage(this);
        let ArrayQuestions = JSON.parse(
            sessionStorage.getItem("ArrayQuestions")
        );
        if (
            ArrayQuestions[ArrayQuestions.length - 1] !==
            ArrayQuestions[ArrayQuestions.length - 2]
        ) {
            ArrayQuestions.push(ArrayQuestions[ArrayQuestions.length - 1]);
            sessionStorage.setItem(
                "ArrayQuestions",
                JSON.stringify(ArrayQuestions)
            );
        }
    }

    render() {
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div>
                    <div className="content-header">
                        <div className="persona-header">
                            <div className="persona-header__content">
                                <h3 className="persona-header__title">
                                    {sessionStorage.getItem("QuestionTitle")}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="row center"></div>
                        <div
                            id="home-wallet"
                            className="row row__cards--display list__question--padding"
                        >
                            <ul className="remove-border collection collection--acionamento list__question with-header">
                                {this.state.numbers.map((number, cont) => (
                                    <div
                                        onClick={this.chooseChoice.bind(
                                            this,
                                            cont
                                        )}
                                        className={"list-item-space"}
                                        key={this.state.ids[cont]}
                                    >
                                        <li className="list-collection-item collection-item">
                                            <div className="list__position">
                                                <div
                                                    className={
                                                        "accompaniment__text--main"
                                                    }
                                                >
                                                    <span>
                                                        <strong>
                                                            {this.state.flagProtocol === 'True' && this.state.protocol[cont] != 'undefined' && this.state.protocol[cont] != '' && this.state.protocol[cont] != null ? this.state.protocol[cont] :  this.state.numbers[cont]}
                                                        </strong>
                                                    </span>
                                                    <span
                                                        id={
                                                            this.state.ids[cont]
                                                        }   
                                                        className={
                                                            "itemClicked" +
                                                            cont +
                                                            " title list__text--accompaniment"
                                                        }
                                                    >
                                                        <strong>
                                                            {
                                                                this.state.sub[
                                                                cont
                                                                ]
                                                            }
                                                        </strong>
                                                    </span>
                                                    {this.state.ids[cont] ===
                                                        "-" ? (
                                                            <></>
                                                        ) : (
                                                            <small className="list__text--accompaniment ">
                                                                Abertura:{" "}
                                                                {
                                                                    this.state.ids[
                                                                    cont
                                                                    ]
                                                                }
                                                            </small>
                                                        )}

                                                    {sessionStorage.getItem("ContactType"+cont) != null &&
                                                    sessionStorage.getItem("ContactType"+cont) != 'undefined' ? (
                                                        <small className="list__text--accompaniment ">
                                                            Tipo de Acionamento: {sessionStorage.getItem("ContactType"+cont)}
                                                        </small>
                                                    ): null}
                                                    {sessionStorage.getItem("DateRange"+cont) != null &&
                                                    sessionStorage.getItem("DateRange"+cont) != 'undefined' ? (
                                                        <small className="list__text--accompaniment ">
                                                            Previsão de Chegada: {sessionStorage.getItem("DateRange"+cont)}
                                                        </small>
                                                    ): null}
                                                </div>
                                            </div>
                                        </li>
                                    </div>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        );
    }

    async chooseChoice(id) {
        if (this.state.ids[0] === '-') {
            return;
        }
        var hourChoose = document.getElementsByClassName("itemClicked" + id)[0]
            .id;
        var itemClicked = this.state.numbers[id];
        sessionStorage.setItem("IdAnswer", itemClicked);
        sessionStorage.setItem("HourChoose", hourChoose);
        sessionStorage.setItem("Action", "AssistDetail");
        sessionStorage.setItem("senhaValidacao", this.state.senha[id]);
        sessionStorage.setItem("ContactType", sessionStorage.getItem('ContactType'+id));
        sessionStorage.setItem("DateRange", sessionStorage.getItem('DateRange'+id));
        topFunction();
        const response = await AssistDetail();
        if (response) {
            this.props.history.push("/detalhe-acompanhamento");
        }
    }
}

export default Accompaniment;
