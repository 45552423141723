import React, { Component } from "react";
import CSSTransition from "react-transition-group/esm/CSSTransition";

class Error400 extends Component {
    componentDidMount() {
        try {
            const error = sessionStorage.getItem("Erro400");
            sessionStorage.clear();
            sessionStorage.setItem("Erro400", error);
        } catch (error) {
            console.log(error);
            sessionStorage.clear();
        }
    }

    render() {
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <header className="App-header">
                    <p className="intro__text padding-15">
                        Você está sem internet. Conecte-se a uma rede e tente
                        novamente.
                    </p>
                </header>
            </CSSTransition>
        );
    }
}

export default Error400;
