import React, { Component } from "react";
import { topFunction, GoogleAnalyticsEvent } from "../../App";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import ReactGA from "react-ga";

var numbers = ["Question1"];

class Alert extends Component {
    constructor(props) {
        super(props);
        props.keep();
    }

    componentDidMount() {
        document.getElementById("back-icon").style.display = "block";
    }

    render() {
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div>
                    {numbers.map((number, cont) => (
                        <div key={number.toString()}>
                            <div className="content-header">
                                <div className="persona-header">
                                    <div className="persona-header__content">
                                        <h3 className="persona-header__title">
                                            Atenção!
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="spacer" />
                            <div className="container">
                                <div className="question">
                                    <img
                                        id="errorImg"
                                        onError={this.erroHandler}
                                        src={sessionStorage.getItem(
                                            "QuestionPath"
                                        )}
                                        alt="Imagem da questão"
                                        className="question__img"
                                    />
                                    <h3 className="persona-header__title--alert">
                                        {sessionStorage.getItem(
                                            "QuestionTitle"
                                        )}{" "}
                                    </h3>
                                    <div className="question-ans">
                                        <div
                                            onClick={this.chooseChoice.bind(
                                                this,
                                                1
                                            )}
                                            className="waves-effect waves-light btn-large btn-danger"
                                        >
                                            <i className="fa fa-lg fa-times container__button--margin " />
                                            {sessionStorage.getItem(
                                                "QuestionText1"
                                            )}
                                        </div>
                                        <div
                                            onClick={this.chooseChoice.bind(
                                                this,
                                                0
                                            )}
                                            className="waves-effect waves-light btn-large btn-success"
                                        >
                                            <i className="fa fa-lg fa-check container__button--margin" />
                                            {sessionStorage.getItem(
                                                "QuestionText0"
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </CSSTransition>
        );
    }

    erroHandler() {
        document.getElementById("errorImg").style.display = "none";
    }

    chooseChoice(id) {
        var itemClicked = sessionStorage.getItem("QuestionId" + id);
        var NextQuestionSelected = sessionStorage.getItem("NextQuestion" + id);
        sessionStorage.setItem("IdAnswer", itemClicked);
        sessionStorage.setItem("Action", "SaveAnswer");
        sessionStorage.setItem("NextQuestionSelected", NextQuestionSelected);
        topFunction();

        let label =
            sessionStorage.getItem("QuestionText" + 0) + " - " + itemClicked;
        GoogleAnalyticsEvent(
            sessionStorage.getItem("SelectedWalet"),
            sessionStorage.getItem("AtualId"),
            label
        );
        ReactGA.ga("set", "userId", "Essência"); // Defina o ID de usuário usando o user_id conectado
        ReactGA.ga(
            "send",
            "event",
            sessionStorage.getItem("NmCliCorporativo"),
            "Escolha Resposta Arvore",
            sessionStorage.getItem("AtualId") +
                " - " +
                sessionStorage.getItem("QuestionText0"),
            1
        );
        this.props.history.push(`/Carregando`);
    }
}

export default Alert;
