import React from 'react';
import { NavLink } from 'react-router-dom';
import { topFunction } from '../../../App';
import CSSTransition from "react-transition-group/esm/CSSTransition";

const ItensSecundary = () => {
    return(
        <CSSTransition
            in={true}
            appear={true}
            timeout={800}
            classNames="fade"
        >
            <div>
                <div className="content-header">
                    <div className="persona-header" >
                        <div className="persona-header__content">
                            <h3 className="persona-header__title">OK! Onde está o problema? </h3>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="row center">
                    </div>
                    <div className="row row__cards--display">
                        <div className="col s6 m6 l4">
                            <NavLink onClick={function(event){topFunction()}} to="/Proposta" className="card-link">
                                <div className="card card--sant">
                                    <div className="card-content proposal">
                                        <h2 className="proposal__text">Pneu furado</h2>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col s6 m6 l4">
                            <NavLink onClick={function(event){topFunction()}} to="/Proposta" className="card-link">
                                <div className="card card--sant">
                                    <div className="card-content proposal">
                                        <h2 className="proposal__text">Não dá partida</h2>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col s6 m6 l4">
                            <NavLink onClick={function(event){topFunction()}} to="/Proposta" className="card-link">
                                <div className="card card--sant">
                                    <div className="card-content proposal">
                                        <h2 className="proposal__text">Chave</h2>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col s6 m6 l4">
                            <NavLink onClick={function(event){topFunction()}} to="/Proposta" className="card-link">
                                <div className="card card--sant">
                                    <div className="card-content proposal">
                                        <h2 className="proposal__text">Barulho anormal</h2>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col s6 m6 l4">
                            <NavLink onClick={function(event){topFunction()}} to="/Proposta" className="card-link">
                                <div className="card card--sant">
                                    <div className="card-content proposal">
                                        <h2 className="proposal__text">Motor ferveu</h2>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col s6 m6 l4">
                            <NavLink onClick={function(event){topFunction()}} to="/Proposta" className="card-link">
                                <div className="card card--sant">
                                    <div className="card-content proposal">
                                        <h2 className="proposal__text">Acabou a gasolina</h2>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default ItensSecundary;