import React from 'react';
import { NavLink } from 'react-router-dom';
import electricity from "../../img/electricity.svg";
import pipe from "../../img/pipe.svg";
import key from "../../img/key.svg";
import repair from "../../img/repair.svg";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import { topFunction } from '../../App'

const House = () => {

    return(
        <CSSTransition
            in={true}
            appear={true}
            timeout={800}
            classNames="fade"
        >
            <div>
                <div className="content-header">
                    <div className="persona-header" >
                        <div className="persona-header__content">
                            <h3 className="persona-header__title">Qual serviço você precisa?</h3>
                        </div>
                    </div>
                </div>
                <div className="section">
                    <div className="row center">
                    </div>
                    <div className="row row__cards--display">
                        <div className="col s6 m6 l4">
                            <NavLink onClick={function(event){topFunction()}} to="House">
                                <div className="card card--sant">
                                    <div className="card-image">
                                        <img alt="Eletricista" className="card__img" src={electricity}/>
                                    </div>
                                    <div className="card-content">
                                        <h2>Eletricista</h2>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col s6 m6 l4">
                            <NavLink onClick={function(event){topFunction()}} to='Pipe' >
                                <div className="card card--sant">
                                    <div className="card-image">
                                        <img alt="Encanador" className="card__img" src={pipe}/>
                                    </div>
                                    <div className="card-content">
                                        <h2>Encanador</h2>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col s6 m6 l4">
                            <NavLink onClick={function(event){topFunction()}} to="Locksmith">
                                <div className="card card--sant">
                                    <div className="card-image">
                                        <img alt="Chaveiro" className="card__img" src={key}/>
                                    </div>
                                    <div className="card-content">
                                        <h2>Chaveiro</h2>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                        <div className="col s6 m6 l4">
                            <NavLink onClick={function(event){topFunction()}} to="House">
                                <div className="card card--sant">
                                    <div className="card-image">
                                        <img alt="Outros" className="card__img" src={repair}/>
                                    </div>
                                    <div className="card-content">
                                        <h2>Outros</h2>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default House;