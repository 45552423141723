export const GoToLogin = async (props) => {
    sessionStorage.setItem("ActualPage", "/Login");
    props.history.push("Login");
};

export const consultContact = async () => {
    return new Promise(async function (resolve, reject) {
        const Token = sessionStorage.getItem("Token");
        const url =
            process.env.REACT_APP_API_URL_ESSENCIA +
            "/cliente-corporativo/contatos";
        fetch(url, {
            headers: new Headers({
                Authorization: "Bearer " + Token,
                "Content-Type": "application/json",
            }),
            method: "post",
            body: JSON.stringify({
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                try {
                    sessionStorage.setItem(
                        "ContactDescription",
                        JSON.stringify(response)
                    );
                    sessionStorage.setItem("GenericError", "False");
                    sessionStorage.setItem(
                        "ContactError",
                        Array.isArray(response) && response.length
                            ? "False"
                            : "True"
                    );
                    resolve(true);
                } catch (error) {
                    console.log(error);
                    sessionStorage.setItem("ContactDescription", "[]");
                    sessionStorage.setItem("GenericError", "False");
                    sessionStorage.setItem("ContactError", "True");
                    resolve(true);
                }
            })
            .catch((error) => {
                console.log(error);
                sessionStorage.setItem("ContactDescription", "[]");
                sessionStorage.setItem("GenericError", "False");
                sessionStorage.setItem("ContactError", "True");
            });
    });
};

export const Accompaniment = async () => {
    return new Promise(async function (resolve, reject) {
        const ConveredSelectedItem = sessionStorage.getItem(
            "ConveredSelectedItem"
        );
        const url =
            process.env.REACT_APP_API_URL_JUVO +
            "/assistenciaServico/segurado?idItemCoberto=" +
            ConveredSelectedItem +
            "&notInStatus=2&notInStatus=3&notInStatus=4&notInStatus=5&notInStatus=6";

        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
            }),
        })
            .then((response) => {
                try {
                    return response.json();
                } catch (error) {
                    return Promise.resolve({});
                }
            })
            .then((response) => {
                if (response.length !== 0) {
                    makeAssistList(response);
                    resolve(true);
                } else {
                    makeAssistList([]);
                    resolve(true);
                }
            })
            .catch((error) => {
                makeAssistList([]);
                resolve(true);
            });
    });
};

async function makeAssistList(response) {
    sessionStorage.removeItem("QuestionTitle");
    sessionStorage.removeItem("AtualQuestion");
    let size = sessionStorage.length;
    for (let i = 0; i < size; i++) {
        if (sessionStorage.key(i) != null) {
            if (sessionStorage.key(i).includes("QuestionText")) {
                sessionStorage.removeItem(sessionStorage.key(i));
                i = i - 1;
                size = size - 1;
            }
        }
        if (sessionStorage.key(i) != null) {
            if (sessionStorage.key(i).includes("QuestionId")) {
                sessionStorage.removeItem(sessionStorage.key(i));
                i = i - 1;
                size = size - 1;
            }
        }
        if (sessionStorage.key(i) != null) {
            if (sessionStorage.key(i).includes("NextQuestion")) {
                sessionStorage.removeItem(sessionStorage.key(i));
                i = i - 1;
                size = size - 1;
            }
        }
        if (sessionStorage.key(i) != null) {
            if (sessionStorage.key(i).includes("DescriptionAccompaniment")) {
                sessionStorage.removeItem(sessionStorage.key(i));
                i = i - 1;
                size = size - 1;
            }
        }
    }
    sessionStorage.setItem(
        "QuestionTitle",
        "Qual assistência deseja acompanhar?"
    );
    for (let i = 0; i < response.length; i++) {
        sessionStorage.setItem("QuestionText" + i, response[i].idAssistencia);
        sessionStorage.setItem(
            "QuestionId" + i,
            response[i].dataAberturaAssistencia
        );
        sessionStorage.setItem(
            "DescriptionAccompaniment" + i,
            response[i].nomeEvento
        );
        sessionStorage.setItem("senhaValidacao" + i, response[i].senhaValidacao);
        sessionStorage.setItem("protocoloCliente" + i, response[i].servicos[0].protocoloCliente);

        sessionStorage.setItem(
            "ContactType" + i,
            response[i].servicos[0].emergencial == 'F' ? 'Agendado' : 'Imediato'
        );

        sessionStorage.setItem(
            "DateRange" + i,
            response[i].servicos[0].emergencial == 'F' ?  response[i].servicos[0].dataPrevisaoInicialStr + ' - ' +  response[i].servicos[0].dataPrevisaoFinalStr : response[i].servicos[0].dataPrevisaoChegadaStr
        );

        if(response[i].servicos[0].dataAgendamento != undefined && sessionStorage.getItem("SelectedWalet") == 'Automóvel'){
            let timestamp = response[i].servicos[0].dataAgendamento;
            let dateScheduling = new Date(timestamp);
            dateScheduling = dateScheduling.toLocaleDateString('pt-BR') + ' ' + dateScheduling.toLocaleTimeString('pt-BR', {hour: '2-digit', minute:'2-digit'});

            let timestampEnd = (response[i].servicos[0].dataAgendamento + (sessionStorage.getItem("idclientecorporativo") === '542' ? 7200000 : 10800000));
            let dateSchedulingEnd = new Date(timestampEnd);
            dateSchedulingEnd = dateSchedulingEnd.toLocaleDateString('pt-BR') + ' ' + dateSchedulingEnd.toLocaleTimeString('pt-BR', {hour: '2-digit', minute:'2-digit'});
            
            sessionStorage.setItem(
                "DateRange" + i,
                dateScheduling + ' - ' + dateSchedulingEnd
            );
        }
    }
    return true;
}

export async function AssistDetail(response) {
    return new Promise(async function (resolve, reject) {
        const ConveredSelectedItem = sessionStorage.getItem(
            "ConveredSelectedItem"
        );
        const url =
            process.env.REACT_APP_API_URL_JUVO +
            "/assistenciaServico/segurado?idItemCoberto=" +
            ConveredSelectedItem +
            "&notInStatus=3&notInStatus=4&notInStatus=5&notInStatus=6";

        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                for (let i = 0; i < response.length; i++) {
                    if (
                        sessionStorage.getItem("IdAnswer") ===
                        response[i].idAssistencia + ""
                    ) {
                        if (response[i].servicos[0].urlFotosVideos) {
                            const splittedUrl = response[i].servicos[0].urlFotosVideos.split(" ");
                            const lastSplittedWord = splittedUrl[splittedUrl.length - 1];

                            sessionStorage.setItem(
                                "urlAttachments",
                                lastSplittedWord
                            );
                        } else {
                            if (sessionStorage.getItem("urlAttachments")) {
                                sessionStorage.removeItem("urlAttachments")
                            }
                        };

                        sessionStorage.setItem(
                            "idTipoCarteira",
                            response[i].idTipoCarteira
                        );
                        sessionStorage.setItem(
                            "IdAssistencia",
                            response[i].servicos[0].idAssistServico
                        );
                        sessionStorage.setItem(
                            "HourChoose",
                            response[i].servicos[0].dataPrevisaoChegada
                        );
                        sessionStorage.setItem(
                            "ScheduledTime",
                            response[i].servicos[0].dataPrevisaoInicialStr
                        );
                        sessionStorage.setItem(
                            "ScheduledTimeEnd",
                            response[i].servicos[0].dataPrevisaoFinalStr
                        );

                        if(response[i].servicos[0].dataAgendamento != undefined && sessionStorage.getItem("SelectedWalet") == 'Automóvel'){
                            let timestamp = response[i].servicos[0].dataAgendamento;
                            let dateScheduling = new Date(timestamp);
                            dateScheduling = dateScheduling.toLocaleDateString('pt-BR') + ' ' + dateScheduling.toLocaleTimeString('pt-BR', {hour: '2-digit', minute:'2-digit'});
                            sessionStorage.setItem(
                                "ScheduledTime",
                                dateScheduling
                            );

                            let timestampEnd = (response[i].servicos[0].dataAgendamento + (sessionStorage.getItem("idclientecorporativo") === '542' ? 7200000 : 10800000));
                            let dateSchedulingEnd = new Date(timestampEnd);
                            dateSchedulingEnd = dateSchedulingEnd.toLocaleDateString('pt-BR') + ' ' + dateSchedulingEnd.toLocaleTimeString('pt-BR', {hour: '2-digit', minute:'2-digit'});
                            sessionStorage.setItem(
                                "ScheduledTimeEnd",
                                dateSchedulingEnd
                            );
                        }

                        sessionStorage.setItem(
                            "NameChoose",
                            response[i].servicos[0].nomeCobertura
                        );
                        if(response[i].servicos[0].protocoloCliente != undefined){
                            sessionStorage.setItem(
                                "protocol",
                                response[i].servicos[0].protocoloCliente
                            );
                        }
                        sessionStorage.setItem(
                            "SubNameChoose",
                            response[i].servicos[0].nome
                        );
                        sessionStorage.setItem(
                            "StateChoose",
                            response[i].servicos[0].tipoStatusServico.descricao
                        );
                        // Montagem endereços
                        let destinyStreet =
                            response[i].servicos[0].enderecoDestino
                                .logradouro === undefined
                                ? ""
                                : response[i].servicos[0].enderecoDestino
                                    .logradouro;
                        let destinyNumber =
                            response[i].servicos[0].enderecoDestino.numero ===
                                undefined
                                ? ""
                                : response[i].servicos[0].enderecoDestino
                                    .numero;
                        let destinyNeighborhood =
                            response[i].servicos[0].enderecoDestino.bairro ===
                                undefined
                                ? ""
                                : response[i].servicos[0].enderecoDestino
                                    .bairro;
                        let destinyCity =
                            response[i].servicos[0].enderecoDestino.cidade ===
                                undefined
                                ? ""
                                : response[i].servicos[0].enderecoDestino
                                    .cidade;
                        let destinyState =
                            response[i].servicos[0].enderecoDestino.uf ===
                                undefined
                                ? ""
                                : response[i].servicos[0].enderecoDestino.uf;
                        let destinyAddress =
                            destinyStreet +
                            " " +
                            destinyNumber +
                            " " +
                            destinyNeighborhood +
                            " " +
                            destinyCity +
                            " " +
                            destinyState;
                        sessionStorage.setItem(
                            "DestinyAddress",
                            destinyAddress.length === 4 ? "" : destinyAddress
                        );

                        let localStreet =
                            response[i].servicos[0].enderecoLocal.logradouro ===
                                undefined
                                ? ""
                                : response[i].servicos[0].enderecoLocal
                                    .logradouro;
                        let localNumber =
                            response[i].servicos[0].enderecoLocal.numero ===
                                undefined
                                ? ""
                                : response[i].servicos[0].enderecoLocal.numero;
                        let localNeighborhood =
                            response[i].servicos[0].enderecoLocal.bairro ===
                                undefined
                                ? ""
                                : response[i].servicos[0].enderecoLocal.bairro;
                        let localCity =
                            response[i].servicos[0].enderecoLocal.cidade ===
                                undefined
                                ? ""
                                : response[i].servicos[0].enderecoLocal.cidade;
                        let localState =
                            response[i].servicos[0].enderecoLocal.uf ===
                                undefined
                                ? ""
                                : response[i].servicos[0].enderecoLocal.uf;
                        let localAddress =
                            localStreet +
                            " " +
                            localNumber +
                            " " +
                            localNeighborhood +
                            " " +
                            localCity +
                            " " +
                            localState;
                        sessionStorage.setItem(
                            "LocalAddress",
                            localAddress.length === 4 ? "" : localAddress
                        );

                        sessionStorage.setItem("AssistCancel", "True");
                        sessionStorage.setItem(
                            "PlacaValidada",
                            response[i].servicos[0].placaValidada === undefined ? "" : response[i].servicos[0].placaValidada
                        );
                        sessionStorage.setItem(
                            "IdStatusServico",
                            response[i].servicos[0].statusServico[0].idStatusServico === undefined ? "" : response[i].servicos[0].statusServico[0].idStatusServico
                        );
                        if (response[i].servicos[0].prestador !== undefined) {
                            const retorno = searchProvider(response[i]);
                            retorno
                                ? resolve(true)
                                : console.log("KAKAKAKAKAAK");
                        } else {
                            sessionStorage.setItem("AssistMaps", "False");
                            if (response[i].servicos[0].placaValidada !== "V" || response[i].servicos[0].statusServico[0].idStatusServico !== 3) {
                                sessionStorage.setItem(
                                    "AssistRescheduling",
                                    "True"
                                );
                            } else {
                                sessionStorage.setItem(
                                    "AssistRescheduling",
                                    "False"
                                );
                            }
                            resolve(true);
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                sessionStorage.setItem("SpecificError", "");
                sessionStorage.setItem("GenericError", "True");
                this.consultContact();
                resolve(false);
            });
    });
}

export async function searchProvider(response) {
    return new Promise(async function (resolve, reject) {
        if (response.servicos[0].prestador === undefined) {
            sessionStorage.setItem("AssistMaps", "False");
            return true;
        } else {
            sessionStorage.setItem("AssistMaps", "False");
            const idAssistServico = response.servicos[0].idAssistServico;
            sessionStorage.setItem(
                "IdAssistServico",
                response.servicos[0].idAssistServico
            );
            const url =
                process.env.REACT_APP_API_URL_TEMPO +
                "/assistenciaServico/ultimaPosicaoPrestador/" +
                idAssistServico;

            fetch(url, {
                headers: new Headers({
                    token: sessionStorage.getItem("token"),
                    chave: sessionStorage.getItem("chave"),
                    idclientecorporativo: sessionStorage.getItem(
                        "idclientecorporativo"
                    ),
                    idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                    Authorization: sessionStorage.getItem("Authorization"),
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.codigo === 999) {
                        sessionStorage.setItem("AssistMaps", "False");
                        resolve(true);
                    } else {
                        sessionStorage.setItem("AssistMaps", "True");
                        sessionStorage.setItem(
                            "GoogleDestinyLon",
                            response.ultimaPosicaoPrestador.longitude
                        );
                        sessionStorage.setItem(
                            "GoogleDestinyLat",
                            response.ultimaPosicaoPrestador.latitude
                        );
                        resolve(true);
                    }
                })
                .catch((error) => {
                    console.log("error");
                });
        }
    });
}

export const FAQ = async () => {
    return new Promise(async function (resolve, reject) {
        const Token = sessionStorage.getItem("Token");
        const url = process.env.REACT_APP_API_URL_ESSENCIA + "/faq";
        fetch(url, {
            headers: new Headers({
                Authorization: "Bearer " + Token,
                "Content-Type": "application/json",
            }),
            method: "post",
            body: JSON.stringify({
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                let size = sessionStorage.length;
                for (let i = 0; i < size; i++) {
                    if (sessionStorage.key(i).includes("FaqQuestion")) {
                        sessionStorage.removeItem(sessionStorage.key(i));
                        i = i - 1;
                        size = size - 1;
                    }
                    if (sessionStorage.key(i).includes("FaqAnswer")) {
                        sessionStorage.removeItem(sessionStorage.key(i));
                        i = i - 1;
                        size = size - 1;
                    }
                }
                if (response.message === undefined) {
                    for (
                        var i = 0;
                        i < Object.keys(response.faqs).length;
                        i++
                    ) {
                        let contador = Object.keys(response.faqs)[i];
                        sessionStorage.setItem(
                            "FaqQuestion" + i,
                            response.faqs[contador].pergunta
                        );
                        sessionStorage.setItem(
                            "FaqAnswer" + i,
                            response.faqs[contador].resposta
                        );
                    }
                } else {
                    sessionStorage.setItem("NoFaqReturn", response.message);
                }
                resolve(true);
            })
            .catch((error) => {
                sessionStorage.setItem("SpecificError", "");
                sessionStorage.setItem("GenericError", "True");
                consultContact();
            });
    });
};
