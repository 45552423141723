import React from "react";
import "./App.css";
import ReactGA from "react-ga";
import "../node_modules/font-awesome/css/font-awesome.min.css";
import {
    HashRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import { isAuthenticated } from "./functions/services/Auth";
import { Offline, Online } from "react-detect-offline";
import Toolbar from "./components/toolbar/Toolbar";
import SideDrawer from "./components/sideDrawer/SideDrawer";
import Backdrop from "./components/backdrop/Backdrop";
import Login from "./components/Login";
import Home from "./components/Home";
import CoveredItem from "./components/CoveredItem";
import Car from "./components/car/Car";
import Others from "./components/layout/car/Others";
import Armored from "./components/layout/car/Armored";
import Wheel from "./components/layout/car/Wheel";
import Wrench from "./components/layout/car/Wrench";
import Map from "./components/layout/car/Map";
import Garage from "./components/layout/car/Garage";
import ReviewCar from "./components/layout/car/ReviewCar";
import AddressOrigin from "./components/AddressOrigin";
import AddressDestiny from "./components/AddressDestiny";
import SpeechCar from "./components/layout/car/SpeechCar";
import Attendance from "./components/layout/car/Attendance";
import Breakdown from "./components/layout/car/Breakdown";
import Battery from "./components/layout/car/Battery";
import Radio from "./components/layout/car/Radio";
import Appointment from "./components/layout/car/Appointment";
import House from "./components/layout/House";
import AlertWithImg from "./components/layout/AlertWithImg";
import AlertWithoutImg from "./components/layout/AlertWithoutImg";
import ItensImportants from "./components/layout/proposta/ItensImportants";
import ItensSecundary from "./components/layout/proposta/ItensSecundary";
import Horizontal from "./components/Horizontal";
import Gas from "./components/layout/car/Gas";
import Error404 from "./components/error/Error404";
import Error400 from "./components/error/Error400";
import Accompaniment from "./components/accompaniment/Accompaniment";
import AssistDetail from "./components/accompaniment/AssistDetail";
import Rescheduling from "./components/accompaniment/Rescheduling";
import NoAssist from "./components/accompaniment/NoAssist";
import ServiceLocation from "./components/ServiceLocation";
import Contacts from "./components/Contacts";
import Decision from "./components/question/Decision";
import SingleChoice2To6 from "./components/question/SingleChoice2To6";
import SingleChoice6ToN from "./components/question/SingleChoice6ToN";
import StaticScale from "./components/question/StaticScale";
import DynamicScale from "./components/question/DynamicScale";
import Alert from "./components/question/Alert";
import Controller from "./components/controller/Controller";
import FAQ from "./components/FAQ";
import ConcludeAssist from "./components/ConcludeAssist";
import ConcludeRescheduling from "./components/ConcludeRescheduling";
import PersonalInformation from "./components/PersonalInformation";
import AssistStatement from "./components/AssistStatement";
import AssistStatements from "./components/AssistStatements";
import SurplusValue from "./components/question/SurplusValue";
import NoInternet from "./components/error/NoInternet";
import Resume from "./components/Resume";

ReactGA.initialize(process.env.REACT_APP_GA_KEY);
ReactGA.pageview(window.location.pathname + window.location.search);

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            personalization: null,
            wallet: null,
            loading: true,
            teste: 0,
            reload: 0,
            isOnline: navigator.onLine
        };

        sessionStorage.setItem("checkClient", "false");
        var url = new URL(window.location.href);
        var c = url.searchParams.get("c");
        if (
            c !== sessionStorage.getItem("idclientecorporativo") &&
            sessionStorage.getItem("idclientecorporativo") !== null
        ) {
            sessionStorage.setItem("idclientecorporativo", c);
            window.location =
                process.env.REACT_APP_PUSH_TEMPO_FRAME + "?c=" + c;
            sessionStorage.removeItem("@auth-Token");
            sessionStorage.removeItem("ConveredSelectedItem");
            sessionStorage.removeItem("NoFaqReturn");
        }
    }

    state = {
        sideDrawOpen: false,
    };

    componentDidMount() {
        setInterval(() => {
            fetch('https://www.google.com', {
                    mode: 'no-cors',
                }).then(() => {
                    if(this.state.isOnline === false){
                        this.setState({ isOnline: true });
                    }
                }).catch(() => 
                    this.setState({ isOnline: false }) 
                );
        }, 5000);
}

    render() {
        return (
            <div>
                {this.state.isOnline ?
                    <Router className="App" style={{ height: "100%" }}>
                        <Toolbar
                            reload={this.state.reload}
                            function={this.reload}
                            drawerClickHandler={this.drawerToggleClickHandler}
                        />
                        <SideDrawer
                            teste={this.state.teste}
                            click={this.backdropClickHandler}
                            show={this.state.sideDrawOpen}
                        />
                        <Backdrop click={this.backdropClickHandler} />
                        <Switch>
                            {/* Raiz */}
                            <Route
                                path="/"
                                component={(props) => (
                                    <Home
                                        {...props}
                                        reloadFunc={this.reloadFunc.bind(this)}
                                    />
                                )}
                                exact
                            />
                            <Route
                                path="/Login"
                                component={(props) => (
                                    <Login
                                        {...props}
                                        keep={this.keepStyle}
                                        reloadSidebar={this.reloadSidebar}
                                    />
                                )}
                            />
                            <PrivateRoute
                                path="/itens-cobertos"
                                component={(props) => (
                                    <CoveredItem
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />

                            {/* Pagina de contatos */}
                            <Route
                                path="/contatos"
                                component={(props) => (
                                    <Contacts
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />

                            {/* Rotas para cada tipo de questão */}
                            <PrivateRoute
                                path="/decisao"
                                component={(props) => (
                                    <Decision
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />
                            <PrivateRoute
                                path="/escolha-unica-2a6"
                                component={(props) => (
                                    <SingleChoice2To6
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />
                            <PrivateRoute
                                path="/escolha-unica-6aN"
                                component={(props) => (
                                    <SingleChoice6ToN
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />
                            <PrivateRoute
                                path="/escala-estatica"
                                component={(props) => (
                                    <StaticScale
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />
                            <PrivateRoute
                                path="/escala-dinamica"
                                component={(props) => (
                                    <DynamicScale
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />
                            <PrivateRoute
                                path="/alerta"
                                component={(props) => (
                                    <Alert {...props} keep={this.keepStyle} />
                                )}
                            />
                            <PrivateRoute
                                path="/valor-excedente"
                                component={(props) => (
                                    <SurplusValue
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />

                            {/* Rota controladora da logica das questões */}
                            <Route
                                path="/Carregando"
                                component={(props) => (
                                    <Controller
                                        {...props}
                                        reload={this.state.reload}
                                        reloadFunc={this.reloadFunc.bind(this)}
                                        keep={this.keepStyle}
                                        reloadSidebar={this.reloadSidebar}
                                    />
                                )}
                            />

                            {/* Rotas para coleta de informações para solicitar uma assistencia */}
                            <PrivateRoute
                                path="/informacoes-pessoais"
                                component={(props) => (
                                    <PersonalInformation
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />
                            <PrivateRoute
                                path="/extrato-assistencia"
                                component={(props) => (
                                    <AssistStatement
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />
                            <PrivateRoute
                                path="/extrato-assistencias"
                                component={(props) => (
                                    <AssistStatements
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />
                            <PrivateRoute
                                path="/AddressOrigin"
                                component={(props) => (
                                    <AddressOrigin
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />
                            <PrivateRoute
                                path="/AddressDestiny"
                                component={(props) => (
                                    <AddressDestiny
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />

                            <PrivateRoute
                                path="/concluir-assistencia"
                                component={(props) => (
                                    <ConcludeAssist
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />

                            <PrivateRoute
                                path="/concluir-reagendamento"
                                component={(props) => (
                                    <ConcludeRescheduling
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />

                            {/* Fluxo Carro */}
                            <PrivateRoute path="/Car" component={Car} />

                            <PrivateRoute path="/Others" component={Others} />

                            <PrivateRoute path="/Gas" component={Gas} />

                            {/* Fluxo Pneu */}
                            <PrivateRoute path="/Armored" component={Armored} />
                            <PrivateRoute path="/Wheel" component={Wheel} />
                            <PrivateRoute path="/Wrench" component={Wrench} />
                            <PrivateRoute path="/Map" component={Map} />
                            <PrivateRoute path="/Garage" component={Garage} />
                            <PrivateRoute
                                path="/ReviewCar"
                                component={ReviewCar}
                            />
                            <PrivateRoute
                                path="/SpeechCar"
                                component={SpeechCar}
                            />
                            <PrivateRoute
                                path="/Attendance"
                                component={Attendance}
                            />

                            {/* Fluxo Pane */}
                            <PrivateRoute
                                path="/Breakdown"
                                component={Breakdown}
                            />
                            <PrivateRoute path="/Battery" component={Battery} />
                            <PrivateRoute path="/Radio" component={Radio} />
                            <PrivateRoute
                                path="/Appointment"
                                component={Appointment}
                            />

                            {/* End Fluxo Carro */}

                            {/* Fluxo Casa */}
                            <PrivateRoute path="/House" component={House} />

                            {/* End Fluxo Casa */}

                            <PrivateRoute
                                path="/AlertWithImg"
                                component={AlertWithImg}
                            />
                            <PrivateRoute
                                path="/AlertWithoutImg"
                                component={AlertWithoutImg}
                            />

                            {/* Rota de proposta */}
                            <Route
                                path="/Proposta"
                                exact
                                component={ItensImportants}
                            />
                            <Route
                                path="/Proposta/MecanicoChaveOuPneu"
                                component={ItensSecundary}
                            />

                            <PrivateRoute
                                path="/Horizontal"
                                component={Horizontal}
                            />

                            {/* Rotas do fluxo de Acompanhamento */}
                            <PrivateRoute
                                path="/acompanhamentos"
                                component={(props) => (
                                    <Accompaniment
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />
                            <PrivateRoute
                                path="/detalhe-acompanhamento"
                                component={(props) => (
                                    <AssistDetail
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />
                            <PrivateRoute
                                path="/reagendamento"
                                component={(props) => (
                                    <Rescheduling
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />
                            <PrivateRoute
                                path="/sem-assistencia"
                                component={(props) => (
                                    <NoAssist
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />
                            <PrivateRoute
                                path="/mapa-servico"
                                component={(props) => (
                                    <ServiceLocation
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />
                            <Route
                                path="/faq"
                                component={(props) => (
                                    <FAQ {...props} keep={this.keepStyle} />
                                )}
                            />

                            <PrivateRoute
                                path="/resumo"
                                component={(props) => (
                                    <Resume
                                        {...props}
                                        keep={this.keepStyle}
                                    />
                                )}
                            />

                            {/* Direcionamento para rotas que contenham erros */}
                            <Route path="/Erro400" component={Error400} />
                            <Route component={Error404} />
                        </Switch>
                    </Router>
                    :
                    <NoInternet />
                }
            </div>
        );
    }

    drawerToggleClickHandler = () => {
        this.setState((prevState) => {
            return { sideDrawOpen: !prevState.sideDrawOpen };
        });
    };

    backdropClickHandler = () => {
        if (document.getElementsByClassName("side-drawer")[0] !== undefined) {
            document
                .getElementsByClassName("side-drawer")[0]
                .classList.remove("open");
            document.getElementsByClassName("backdrop")[0].style.display =
                "none";
        }
    };

    reloadFunc() {
        this.setState({ reload: 1 });
    }

    reloadSidebar = () => {
        this.setState({
            teste: 1,
        });
    };

    keepStyle = () => {
        var stylePerso = [];
        for (let i = 0; i < sessionStorage.length; i++) {
            if (sessionStorage.key(i).includes("--")) {
                stylePerso.push(sessionStorage.key(i));
                stylePerso.push(sessionStorage.getItem(sessionStorage.key(i)));
            }
        }
        importStyle(stylePerso);
    };
}

export function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

export function GoogleAnalyticsEvent(selectedWallet, question, label) {
    ReactGA.ga("set", "userId", "Essência");
    ReactGA.ga(
        "send",
        "event",
        sessionStorage.getItem("NmCliCorporativo") + " - " + selectedWallet,
        "Escolha Resposta Arvore - " + question,
        label,
        1
    );
}

export function GoogleAnalyticsStatusEvent(
    selectedWallet,
    status,
    description
) {
    let NameCliCorp =
        sessionStorage.getItem("NmCliCorporativo") !== null
            ? sessionStorage.getItem("NmCliCorporativo")
            : "Tempo Assist";

    let selectedWalletValue = selectedWallet !== "null" ? selectedWallet : "";
    selectedWalletValue = selectedWallet !== null ? selectedWalletValue : "";
    ReactGA.ga("set", "userId", "Essência");
    ReactGA.ga(
        "send",
        "event",
        NameCliCorp + " - " + selectedWalletValue,
        status,
        description,
        1
    );
}

export function backPage(reference) {
    let nameClient =
        sessionStorage.getItem("NmCliCorporativo") === null ||
            sessionStorage.getItem("NmCliCorporativo") === "Tempo Assist"
            ? ""
            : sessionStorage.getItem("NmCliCorporativo");
    ReactGA.ga("set", "page", reference.props.location.pathname);
    ReactGA.ga("send", "pageview", {
        page: reference.props.location.pathname,
        title: nameClient,
    });
    if (
        sessionStorage.getItem("ActualPage") ===
        reference.props.location.pathname
    ) {
        if (sessionStorage.getItem("BackPage") != null) {
            let historic = JSON.parse(sessionStorage.getItem("BackPage"));
            if (
                historic[historic.length - 1] !==
                reference.props.location.pathname
            ) {
                historic.push(sessionStorage.getItem("ActualPage"));
                sessionStorage.setItem("BackPage", JSON.stringify(historic));
            } else {
            }
        } else {
            let historic = [];
            historic.push("/");
            sessionStorage.setItem("BackPage", JSON.stringify(historic));
        }
        sessionStorage.setItem("ActualPage", reference.props.location.pathname);
    }
}

export function backPageQuestions(reference) {
    let nameClient =
        sessionStorage.getItem("NmCliCorporativo") === null ||
            sessionStorage.getItem("NmCliCorporativo") === "Tempo Assist"
            ? ""
            : sessionStorage.getItem("NmCliCorporativo");
    ReactGA.ga("set", "page", reference.props.location.pathname);
    ReactGA.ga("send", "pageview", {
        page: reference.props.location.pathname,
        title: nameClient,
    });
    if (sessionStorage.getItem("Action") !== "NextQuest") {
        if (sessionStorage.getItem("BackPage") != null) {
            let historic = JSON.parse(sessionStorage.getItem("BackPage"));
            historic.push("/carregando");
            sessionStorage.setItem("BackPage", JSON.stringify(historic));
            sessionStorage.setItem("Action", "NextQuest");

            if (sessionStorage.getItem("ArrayQuestions") != null) {
                let ArrayQuestions = JSON.parse(
                    sessionStorage.getItem("ArrayQuestions")
                );
                ArrayQuestions.push(sessionStorage.getItem("AtualQuestion"));
                sessionStorage.setItem(
                    "ArrayQuestions",
                    JSON.stringify(ArrayQuestions)
                );
            } else {
                let ArrayQuestions = [];
                ArrayQuestions.push("1");
                sessionStorage.setItem(
                    "ArrayQuestions",
                    JSON.stringify(ArrayQuestions)
                );
            }
        } else {
            let historic = [];
            historic.push("/");
            sessionStorage.setItem("BackPage", JSON.stringify(historic));
        }
        sessionStorage.setItem("ActualPage", reference.props.location.pathname);
    }
}

export function importStyle(data) {
    for (let i = 0; i < data.length; i = i + 2) {
        document.documentElement.style.setProperty(data[i], data[i + 1]);
    }
    var favicon = document.querySelector('link[rel="shortcut icon"]');

    if (!favicon) {
        favicon = document.createElement("link");
        favicon.setAttribute("rel", "shortcut icon");
        var head = document.querySelector("head");
        head.appendChild(favicon);
    }

    document.title = sessionStorage.getItem("NmCliCorporativo");
    favicon.setAttribute("type", "image/png");
    favicon.setAttribute("href", sessionStorage.getItem("Favicon"));
}

export function eraseAddress() {
    sessionStorage.removeItem("OriginCep");
    sessionStorage.removeItem("OriginCity");
    sessionStorage.removeItem("OriginComplement");
    sessionStorage.removeItem("OriginLatitude");
    sessionStorage.removeItem("OriginLongitude");
    sessionStorage.removeItem("OriginNeighborhood");
    sessionStorage.removeItem("OriginNumber");
    sessionStorage.removeItem("OriginState");
    sessionStorage.removeItem("OriginStreet");

    sessionStorage.removeItem("DestinyCep");
    sessionStorage.removeItem("DestinyCity");
    sessionStorage.removeItem("DestinyComplement");
    sessionStorage.removeItem("DestinyLatitude");
    sessionStorage.removeItem("DestinyLongitude");
    sessionStorage.removeItem("DestinyNeighborhood");
    sessionStorage.removeItem("DestinyNumber");
    sessionStorage.removeItem("DestinyState");
    sessionStorage.removeItem("DestinyStreet");
}

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{ pathname: "/", state: { from: props.location } }}
                />
            )
        }
    />
);

window.onhashchange = function () {
    var url = new URL(window.location.href);
    var c = url.searchParams.get("c");
    if (
        c !== sessionStorage.getItem("idclientecorporativo") &&
        sessionStorage.getItem("idclientecorporativo") !== null
    ) {
        sessionStorage.setItem("idclientecorporativo", c);
        sessionStorage.removeItem("@auth-Token");
        sessionStorage.removeItem("ConveredSelectedItem");
        sessionStorage.removeItem("NoFaqReturn");
        window.location = process.env.REACT_APP_PUSH_TEMPO_FRAME + "?c=" + c;
    }
};

export default App;
