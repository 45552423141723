import React from 'react';
import CSSTransition from "react-transition-group/esm/CSSTransition";
import DictaphoneCar from "../SpeechCar";

const AddressCar = () => {
    return(
        <CSSTransition
            in={true}
            appear={true}
            timeout={800}
            classNames="fade"
        >
            <div>
                <div className="content-header">
                    <div className="persona-header" >
                        <div className="persona-header__content">
                            <h3 className="persona-header__title">Descreva o ocorrido</h3>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="space-in-speech">
                        <DictaphoneCar></DictaphoneCar>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default AddressCar;