import React from "react";
import PropTypes from "prop-types";
import SpeechRecognition from "react-speech-recognition";
import {NavLink} from "react-router-dom";
import { topFunction } from '../../App'

const propTypes = {
    // Props injected by SpeechRecognition
    transcript: PropTypes.string,
    resetTranscript: PropTypes.func,
    browserSupportsSpeechRecognition: PropTypes.bool
};

const options = {
    autoStart: false
};
var cont = 0;
const Dictaphone = ({
                        transcript,
                        resetTranscript,
                        stopListening,
                        startListening,
                        browserSupportsSpeechRecognition
                    }) => {
    if (!browserSupportsSpeechRecognition) {
        return (<div>
            <div onClick={function(event){recording()}}  className="container__div__footer row">
                <button id="btn-loader" className="waves-effect waves-light btn-large btn-stop-recording"> <i id="loader" className="fa fa-microphone-slash"/> <span id="text-loader">Gravar</span></button>
            </div>
            <br/>
            <div className="reload">
                <i onClick={resetTranscript} className="fa fa-times color-red"/>
            </div>
            <div suppressContentEditableWarning={true} contentEditable="true" className="div-input">
                {transcript}
            </div>
            <br/>
            <br/>
            <div onClick={resetTranscript} className="container__div__footer row">
                <NavLink onClick={function(event){topFunction()}} to="Attendance"  className="waves-effect waves-light btn-large btn-success"><i className="fa fa-check margin__button-big"/>Confirmar</NavLink>
            </div>
        </div>);
    }
    function recording() {
        var element = document.getElementById("loader");
        var parent = document.getElementById("btn-loader");
        var child = document.getElementById("text-loader");
        if(cont === 0){
            startListening();
            element.classList.remove("fa-microphone-slash");
            element.classList.add("fa-microphone");
            parent.classList.remove("btn-stop-recording");
            parent.classList.add("btn-success");
            child.innerHTML = "Gravando";
            cont = 1;
        }else {
            element.classList.add("fa-microphone-slash");
            element.classList.remove("fa-microphone");
            parent.classList.add("btn-stop-recording");
            parent.classList.remove("btn-success");
            child.innerHTML = "Continuar gravação ...";
            stopListening();
            cont = 0;
        }
    }

    return (
        <div>
            <div onClick={function(event){recording()}}  className="container__div__footer row">
                <button id="btn-loader" className="waves-effect waves-light btn-large btn-stop-recording"> <i id="loader" className="fa fa-microphone-slash"/> <span id="text-loader">Gravar</span></button>
            </div>
            <br/>
            <div className="reload">
                <i onClick={resetTranscript} className="fa fa-times color-red"/>
            </div>
            <div suppressContentEditableWarning={true} contentEditable="true" className="div-input">
                {transcript}
            </div>
            <br/>
            <br/>
            <div onClick={resetTranscript} className="container__div__footer row">
                <NavLink  onClick={function(event){topFunction()}} to="Attendance"  className="waves-effect waves-light btn-large btn-success"><i className="fa fa-check margin__button-big"/>Confirmar</NavLink>
            </div>
        </div>
    );
};

Dictaphone.propTypes = propTypes;

export default SpeechRecognition(options)(Dictaphone);