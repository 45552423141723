import React, { Component } from 'react'
import Slider from 'react-rangeslider'

class Horizontal extends Component {
    constructor (props, context) {
        super(props, context);

        let cont = -1;
        for (let i = 0; i < sessionStorage.length; i++) {
            if(sessionStorage.key(i).includes("QuestionText")){
                cont = cont + 1;
            }
        }

        this.state = {
            value: 0,
            text: sessionStorage.getItem("QuestionText0"),
            qtdmax: cont
        }
    }

    handleChange = value => {
        this.setState({
            value: value
        });

        for (let i = 0; i < sessionStorage.length; i++) {
            if(sessionStorage.key(i).includes("QuestionText" + value)){
                this.setState({
                        text: sessionStorage.getItem(sessionStorage.key(i))
                    }
                );
            }
        }
    };

    render () {
        const { value } = this.state;
        const { text } = this.state;
        const { qtdmax } = this.state;
        return (
            <div className='slider'>
                <div className='value slider__text'>{text}</div>
                <Slider
                    min={0}
                    tooltip={false}
                    max={qtdmax}
                    value={value}
                    onChange={this.handleChange}
                />
            </div>
        )
    }
}

export default Horizontal