import React, { Component } from "react";
import { backPage, topFunction } from "../../App";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { NavLink } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import { setMinutes, setHours, addHours, addDays, roundToNearestMinutes, differenceInHours } from 'date-fns';
import { CustomInputDate, CustomInputHour } from "../CustomInput";
import { AssistDetail } from "../controller/ControllerFunctions.js";
registerLocale("pt", pt);

class Rescheduling extends Component {
    constructor(props) {
        super(props);
        
        let date = sessionStorage.getItem("HourChoose");
        let feedbackAssistCancel = sessionStorage.getItem("FeedbackAssistCancel");
        let name = sessionStorage.getItem("NameChoose");
        let subName = sessionStorage.getItem("SubNameChoose");
        let stateChoose = sessionStorage.getItem("StateChoose");
        let destinyAddress = sessionStorage.getItem("DestinyAddress");
        let localAddress = sessionStorage.getItem("LocalAddress");
        let idTipoCarteira = sessionStorage.getItem("idTipoCarteira");
        let idclientecorporativo = sessionStorage.getItem("idclientecorporativo");
        let scheduledTime = sessionStorage.getItem("ScheduledTime");
        let scheduledTimeEnd = sessionStorage.getItem("ScheduledTimeEnd");
        const hourString = idclientecorporativo === '542' ? '2:00h' : '3:00h';

        var dt = new Date();
        dt.setHours(dt.getHours() + 2);

        this.state = {
            errorMessage: false,
            hourString,
            text: process.env.REACT_APP_PUSH_TEMPO_FRAME + "MapDirections.html",
            modalShow: (feedbackAssistCancel != undefined && feedbackAssistCancel != '' ? true : false),
            date: date,
            type: feedbackAssistCancel !== undefined && feedbackAssistCancel !== '' ? (feedbackAssistCancel === 'true' ? 'feedback-success' : 'feedback-error') : "cancel",
            name,
            subName,
            stateChoose,
            destinyAddress,
            localAddress,
            idTipoCarteira,
            minDate: dt,
            startDate: null,
            idclientecorporativo,
            showSelectedDate: false,
            scheduledTime,
            scheduledTimeEnd
        };

        sessionStorage.removeItem("FeedbackAssistCancel");
        props.keep();
        backPage(this);

        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    async componentDidMount(){
        const response = await AssistDetail();
        let scheduledTime = sessionStorage.getItem("ScheduledTime");
        let scheduledTimeEnd = sessionStorage.getItem("ScheduledTimeEnd");
        this.setState({scheduledTime:scheduledTime, scheduledTimeEnd:scheduledTimeEnd});
    }

    render() {
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                < div style={{ maxWidth: 750, margin: '16px auto' }}>
                    <div style={{ margin: 'auto', maxWidth: 'none', display: 'flex', flexDirection: 'column', padding: 16 }} className={"content-conclude-wrapper"}>
                        <div style={{ paddingBottom: 0, maxWidth: 'initial' }} className="content-header">
                            <div style={{ paddingLeft: 0, paddingRight: 0 }} className="persona-header">
                                <div style={{ textAlign: 'left' }} className="persona-header__content">
                                    <h3 style={{ padding: 0 }} className="persona-header__title">
                                        Para reagendar seu atendimento, basta escolher a nova data e horário:
                                </h3>
                                </div>
                            </div>
                        </div>
                        <div className="datebox">
                            <div className="datepicker-wrapper">
                                <span className="main-text-color">Data e hora do agendamento atual</span>
                                <DatePicker
                                    onChange={this.handleChange}
                                    locale="pt"
                                    dateFormat="Pp"
                                    withPortal
                                    disabled
                                    placeholderText={this.state.scheduledTime && this.state.scheduledTime}
                                />
                            </div>

                            <div className="datepicker-wrapper">
                                <span className="main-text-color">Prazo para chegada até o local</span>
                                <DatePicker
                                    onChange={this.handleChange}
                                    locale="pt"
                                    dateFormat="Pp"
                                    withPortal
                                    disabled
                                    placeholderText={this.state.scheduledTimeEnd && this.state.scheduledTimeEnd}
                                />
                            </div>
                        </div>
                        {this.state.errorMessage && (
                            <div className="info-error-container">
                                <label className="text-action-button-color error__text">
                                    <small className={"error-size-text"}>
                                        Preencha todos os dados obrigatórios
                                     </small>
                                </label>
                            </div>
                        )}

                        <div className="datebox">
                            <div className="datepicker-wrapper date">
                                <span className="main-text-color">Para qual data deseja reagendar?</span>
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={this.handleChange}
                                    locale="pt"
                                    minDate={this.state.minDate}
                                    maxDate={addDays(new Date(), 30)}
                                    dateFormat="dd/MM/yyyy"
                                    customInput={<CustomInputDate />}
                                />
                            </div>

                            <div className="datepicker-wrapper time">
                                <span className="main-text-color">Melhor horário</span>
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={this.handleChange}
                                    locale="pt"
                                    minTime={differenceInHours(this.state.startDate, new Date()) <= 3
                                        ? addHours(new Date(Math.ceil(new Date().getTime() / 900000) * 900000), 3).getTime()
                                        : setHours(setMinutes(new Date(), 0), 0)
                                    }
                                    maxTime={setHours(setMinutes(new Date(), 45), 23)}
                                    dateFormat="HH:mm"
                                    timeIntervals={15}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    customInput={<CustomInputHour />}
                                />
                            </div>
                        </div>

                        {this.state.showSelectedDate && (
                            <div className="datebox">
                                <div className="datepicker-wrapper">
                                    <span className="main-text-color">Data e hora do agendamento</span>
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.handleChange}
                                        locale="pt"
                                        minDate={this.state.minDate}
                                        dateFormat="Pp"
                                        withPortal
                                        disabled
                                    />
                                </div>

                                <div className="datepicker-wrapper">
                                    <span className="main-text-color">Prazo máximo para chegada até o local</span>
                                    <DatePicker
                                        selected={new Date(this.state.startDate).getTime() + (this.state.idclientecorporativo === '542' ? 7200000 : 10800000)}
                                        onChange={this.handleChange}
                                        locale="pt"
                                        minDate={this.state.minDate}
                                        dateFormat="Pp"
                                        withPortal
                                        disabled
                                    />
                                </div>
                            </div>
                        )}

                        <small
                            style={{ display: "none", marginTop: 24 }}
                            id="warning-low-time"
                        >
                            {`Agendamentos para o mesmo dia devem ter ao menos de ${this.state.hourString} de diferença da data da solicitação`}
                        </small>

                        <button
                            style={
                                this.props.type === "feedback-error" || this.props.type === "feedback-success"
                                    ? { display: "none" }
                                    : { display: "block", maxWidth: 200, textTransform: 'uppercase', alignSelf: 'center', marginTop: 24 }
                            }
                            onClick={this.handleClick}
                            className="waves-effect waves-light btn-large btn-success"
                        >
                            Avançar
                        </button>
                    </div>
                </div>
            </CSSTransition >
        );
    }

    opemMap() {
        this.props.history.push("/mapa-servico");
    }

    handleChange(date) {
        if (!(date instanceof Date)) {
            return;
        }
        sessionStorage.setItem('ReschedulingDate', date.getTime());

        if (!this.state.showSelectedDate) {
            this.setState({
                showSelectedDate: true
            })
        }

        var today = new Date();

        const diffTime = date.getTime() - today.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60));
        if (diffDays <= 180) {
            document.getElementById("warning-low-time").style.display = "block";
            this.setState({
                startDate: addHours(new Date(Math.ceil(new Date().getTime() / 900000) * 900000), 3).getTime()
            });
        } else {
            document.getElementById("warning-low-time").style.display = "none";
            this.setState({
                startDate: date.getTime(),
            });
        }
    };

    handleClick() {
        let date = document.querySelector('.datepicker-wrapper.date .react-datepicker__input-container button').textContent;
        let time = document.querySelector('.datepicker-wrapper.time .react-datepicker__input-container button').textContent;

        if (date === "Selecione uma data" || time === "Selecione um horário") {
            this.setState({
                errorMessage: true,
            });
            return;
        }
        this.setState({
            errorMessage: false,
        });
        this.props.history.push("/carregando");
        sessionStorage.setItem(
            "ReschedulingDate",
            this.state.startDate
        );
        sessionStorage.setItem("Action", "Rescheduling");
    }
}

export default Rescheduling;
