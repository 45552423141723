import React from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import wheel from "../../img/wheel.svg";
import breakdown from "../../img/breakdown.svg";
import crash from "../../img/crash.svg";
import key from "../../img/key.svg";
import fuel from "../../img/fuel.svg";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import { topFunction } from '../../App'
import {isAuthenticated} from "../../functions/services/Auth";

class Car extends React.Component {
    render() {
        if ( isAuthenticated){
            return(
                <CSSTransition
                    in={true}
                    appear={true}
                    timeout={800}
                    classNames="fade"
                >
                    <div>
                        <div className="content-header">
                            <div className="persona-header" >
                                <div className="persona-header__content">
                                    <h3 className="persona-header__title">O que aconteceu?</h3>
                                </div>
                            </div>
                        </div>
                        <div className="section">
                            <div className="row center">
                            </div>
                            <div className="row">
                                <div className="col s6 m6 l4">
                                    <NavLink onClick={function(event){topFunction()}} to="Armored">
                                        <div className="card card--sant">
                                            <div className="card-image">
                                                <img alt="Pneu" className="card__img" src={wheel}/>
                                            </div>
                                            <div className="card-content">
                                                <h2>Pneu</h2>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div className="col s6 m6 l4">
                                    <NavLink onClick={function(event){topFunction()}} to="Gas">
                                        <div className="card card--sant">
                                            <div className="card-image">
                                                <img alt="Combustível" className="card__img" src={fuel}/>
                                            </div>
                                            <div className="card-content">
                                                <h2>Combustível</h2>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div className="col s6 m6 l4">
                                    <NavLink onClick={function(event){topFunction()}} to="Car" className="card-link">
                                        <div className="card card--sant">
                                            <div className="card-image">
                                                <img alt="Chaveiro" className="card__img" src={key}/>
                                            </div>
                                            <div className="card-content">
                                                <h2>Chaveiro</h2>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div className="col s6 m6 l4">
                                    <NavLink onClick={function(event){topFunction()}} to="Breakdown">
                                        <div className="card card--sant">
                                            <div className="card-image">
                                                <img alt="Pane" className="card__img" src={breakdown}/>
                                            </div>
                                            <div className="card-content">
                                                <h2>Pane</h2>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                                <div className="col s6 m6 l4">
                                    <NavLink onClick={function(event){topFunction()}} to="Others" className="card-link">
                                        <div className="card card--sant">
                                            <div className="card-image">
                                                <img alt="Outros" className="card__img" src={crash}/>
                                            </div>
                                            <div className="card-content">
                                                <h2>Outros</h2>
                                            </div>
                                        </div>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            );
        }else{
            return(
                <Redirect to={{ pathname: "/" }} />
            );
        }
    }
}

export default Car;