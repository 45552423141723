import React from 'react';
import { NavLink } from 'react-router-dom';
import garage from "../../../img/garage.svg";
import CSSTransition from "react-transition-group/esm/CSSTransition";

const Garage = () => {
    return(
        <CSSTransition
            in={true}
            appear={true}
            timeout={800}
            classNames="fade"
        >
            <div>
                <div className="content-header">
                    <div className="persona-header" >
                        <div className="persona-header__content">
                            <h3 className="persona-header__title">O veículo está em garagem subsolo ou elevada?</h3>
                        </div>
                    </div>
                </div>
                <div className="spacer"></div>
                <div className="container">
                    <div className="question">
                        <img src={garage} alt="Garagem" className="question__img"/>
                        <div className="question-ans">
                            <NavLink to="AddressCar" className="waves-effect waves-light btn-large btn-danger"><i className="fa fa-lg fa-times container__button--margin "/>  Não</NavLink>
                            <NavLink to="AddressCar" className="waves-effect waves-light btn-large btn-success" ><i className="fa fa-lg fa-check container__button--margin"/>  Sim</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default Garage;