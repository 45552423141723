import React from 'react';
import { NavLink } from 'react-router-dom';
import { topFunction } from '../../../App';
import CSSTransition from "react-transition-group/esm/CSSTransition";

const ReviewCar = () => {
    return(
        <CSSTransition
            in={true}
            appear={true}
            timeout={800}
            classNames="fade"
        >
            <div>
                <div className="content-header">
                    <div className="persona-header" >
                        <div className="persona-header__content">
                            <h3 className="persona-header__title">Por favor, confirme as informações que nos forneceu:</h3>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="card card--no-pad">
                        <div className="card-content">
                            <ul className="collection collection--acionamento with-header">
                                <li className="collection-item">
                                    <div className=" hspace-between">
                                        <div className="">
                                            <span className="title"><strong> O que aconteceu? </strong></span>
                                        </div>
                                        <div>Pneu</div>
                                    </div>
                                </li>
                                <li className="collection-item">
                                    <div className=" hspace-between">
                                        <div className="">
                                            <span className="title"><strong> O veículo é blindado? </strong></span>
                                        </div>
                                        <div>Sim</div>
                                    </div>
                                </li>
                                <li className="collection-item">
                                    <div className=" hspace-between">
                                        <div className="">
                                            <span className="title"><strong> Mais de 1 pneu está danificado? </strong></span>
                                        </div>
                                        <div>Não</div>
                                    </div>
                                </li>
                                <li className="collection-item">
                                    <div className=" hspace-between">
                                        <div className="">
                                            <span className="title"><strong> Você está em um lugar seguro? </strong></span>
                                        </div>
                                        <div>Não</div>
                                    </div>
                                </li>
                                <li className="collection-item">
                                    <div className=" hspace-between">
                                        <div className="">
                                            <span className="title"><strong> O veículo está em garagem subsolo ou elevada?</strong></span>
                                        </div>
                                        <div>Sim</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="question-ans row">
                        <NavLink onClick={function(event){topFunction()}} to="Car" className="waves-effect waves-light btn-large btn-danger"> Responder Novamente</NavLink>
                        <NavLink onClick={function(event){topFunction()}} to="AddressCar" className="waves-effect waves-light btn-large btn-success"> Confirmar</NavLink>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default ReviewCar;