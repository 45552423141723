import React from "react";
import {
    backPage,
    topFunction,
    eraseAddress,
    GoogleAnalyticsStatusEvent,
} from "../App";
import { login } from "../functions/services/Auth";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import MaskedInput from "react-text-mask";
import { Redirect } from "react-router-dom";

import { Accompaniment } from "./controller/ControllerFunctions.js";

class Login extends React.Component {
    constructor(props) {
        super(props);

        const SelectedWaletId = sessionStorage.getItem("SelectedWaletId");
        let loginType;
        try {
            loginType = JSON.parse(
                sessionStorage.getItem("HomeLogin" + SelectedWaletId)
            );
        } catch (error) {
            loginType = null;
        }

        let cpfCnpjExist;
        let placaExist;
        let chassiExist;
        let loginTypePhrase = [];
        let phrase;
        let MessageChassi = false;
        if (loginType !== null) {
            cpfCnpjExist = loginType.indexOf("cpfcnpj");
            placaExist = loginType.indexOf("placa");
            chassiExist = loginType.indexOf("chassi");
            loginTypePhrase = this.makePhraseArray(loginType);
            phrase = this.makePhrase(loginTypePhrase);
            if (loginType[0] === "chassi") MessageChassi = true;
        }

        var url_string__logo = window.location.href;
        var corporate_client = new URL(url_string__logo);
        let corporateClientId = corporate_client.searchParams.get("c");
        let flagClerk = corporate_client.searchParams.get("atendente");
        if(flagClerk !== 'sim'){
            sessionStorage.removeItem('clerkName');
        }

        let clerkName = sessionStorage.getItem('clerkName')
        var document = '';
        if(sessionStorage.getItem("cpflogin") != null){
            document = sessionStorage.getItem("cpflogin");
        }

        this.state = {
            corporateClientId,
            flagClerk,
            clerkName,
            contacts: [],
            cpfCnpjExist,
            placaExist,
            chassiExist,
            phrase,
            MessageChassi,
            personalization: null,
            wallet: null,
            document: document,
            loading: true,
            modalShow: true,
            destiny: "carregando",
            mask: [
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/
            ],
        };
        this.checkAddressNumber();
        eraseAddress();
        backPage(this);
        props.keep();
    }

    componentDidMount() {
        document.getElementsByClassName("modal")[0].style.display = "none";
        document.getElementsByClassName("modal-backdrop")[0].style.display =
            "none";

        if (sessionStorage.getItem("HomeId1") === null) {
            document.getElementById("back-icon").style.display = "none";
        }

        if(sessionStorage.getItem("cpflogin") != null &&  sessionStorage.getItem("hasLogged") != "true"){
            // this.setState({document: sessionStorage.getItem("cpflogin")});
            if(document.getElementById("documents").value != ''){
                sessionStorage.setItem("hasLogged", "true");
                document.getElementById("div-submit").click();
            }
        }
        setInterval(() => {
            if (sessionStorage.getItem("Logo") === null) {
                this.props.history.push(`/`);
            }
        }, 13000);

        sessionStorage.removeItem("allAnswers");
    }

    render() {
        let modalClose = () => this.setState({ modalShow: false });
        return (
            <div>
                <div id="div-loader" className="loader__position" style={{ display: sessionStorage.getItem("cpflogin") ? "block" : "none", textAlign: "center" }}>
                    <div className="lds-ring">
                        <div
                            style={
                                this.state.bgColor !== "#FFFFFF" ||
                                    this.state.bgColor !== "#FFF" ||
                                    this.state.bgColor !== "#ffffff" ||
                                    this.state.bgColor !== "#fff"
                                    ? {
                                        borderColor:
                                            "#DDDDDD transparent transparent transparent",
                                    }
                                    : {
                                        borderColor:
                                            "#FFFFFF transparent transparent transparent",
                                    }
                            }
                        ></div>
                        <div
                            style={
                                this.state.bgColor !== "#FFFFFF" ||
                                    this.state.bgColor !== "#FFF" ||
                                    this.state.bgColor !== "#ffffff" ||
                                    this.state.bgColor !== "#fff"
                                    ? {
                                        borderColor:
                                            "#DDDDDD transparent transparent transparent",
                                    }
                                    : {
                                        borderColor:
                                            "#FFFFFF transparent transparent transparent",
                                    }
                            }
                        ></div>
                        <div
                            style={
                                this.state.bgColor !== "#FFFFFF" ||
                                    this.state.bgColor !== "#FFF" ||
                                    this.state.bgColor !== "#ffffff" ||
                                    this.state.bgColor !== "#fff"
                                    ? {
                                        borderColor:
                                            "#DDDDDD transparent transparent transparent",
                                    }
                                    : {
                                        borderColor:
                                            "#FFFFFF transparent transparent transparent",
                                    }
                            }
                        ></div>
                        <div
                            style={
                                this.state.bgColor !== "#FFFFFF" ||
                                    this.state.bgColor !== "#FFF" ||
                                    this.state.bgColor !== "#ffffff" ||
                                    this.state.bgColor !== "#fff"
                                    ? {
                                        borderColor:
                                            "#DDDDDD transparent transparent transparent",
                                    }
                                    : {
                                        borderColor:
                                            "#FFFFFF transparent transparent transparent",
                                    }
                            }
                        ></div>
                    </div>
                </div>
            <div id="div-content" className={"login-container"} style={{ display: sessionStorage.getItem("cpflogin") ? "none" : "block" }}>
                <header className="app-header--login">
                    <p className="intro__text">
                        {this.state.corporateClientId === "542"
                            ? "Para começar, preencha o CPF ou CNPJ do segurado:"
                            : `Pra começar, preencha ${this.state.phrase}.`}
                    </p>
                    {this.state.MessageChassi ? (
                        <p className="chassi-message intro__text">
                            Você pode preencher o chassi completo ou apenas os 8
                            últimos dígitos
                        </p>
                    ) : (
                            <></>
                        )}

                    <label
                        id="error-text"
                        className="error__text"
                        style={{ display: "none" }}
                    >
                        <small>
                            Usuário não encontrado, preencha os campos novamente
                            e caso o problema persista, por favor entre em
                            contato com a Central de Atendimento{" "}
                            <div>
                                {this.state.contacts.map((contact, cont) =>
                                    this.generateContacts(contact, cont)
                                )}
                            </div>
                        </small>
                    </label>

                    <label
                        id="client-error-text"
                        className="error__text"
                        style={{ display: "none" }}
                    >
                        <small id={"client-error-text-label"}>
                            O cliente a ser acessado ainda não esta cadastrado
                            nas nossas diretrizes
                        </small>
                    </label>

                    {this.state.flagClerk === 'sim' ? (
                        <label
                            id="clerk-error-text"
                            className="error__text"
                            style={{ display: "none" }}
                        >
                            <small id="clerk-error-small"></small>
                        </label>
                    ) : (
                        <></>
                    )}
                    {this.state.flagClerk === 'sim' ? (
                        <div className="list-ios input ion-input">
                            <input
                                id="atendente"
                                maxLength="30"
                                className="text-input text-input-md"
                                placeholder="Nome atendente"
                                value={this.state.clerkName}
                                defaultValue={this.state.clerkName}
                            />
                        </div>
                    ) : (
                        <></>
                    )}

                    {this.state.cpfCnpjExist !== -1 ? (
                        <div className="list-ios input ion-input">
                            <MaskedInput
                                mask={this.state.mask}
                                className="form-control text-input text-input-md"
                                placeholder="CPF/CNPJ"
                                guide={false}
                                type="tel"
                                id="documents"
                                value={this.state.document}
                                onFocus={() =>this.clearMask()}
                                onBlur={() => this.formatInput()}
                            />
                        </div>
                    ) : (
                            <></>
                        )}

                    {this.state.placaExist !== -1 ? (
                        <div className="list-ios input ion-input">
                            <input
                                id="board"
                                maxLength="7"
                                className="text-input text-input-md"
                                placeholder="Placa"
                            />
                        </div>
                    ) : (
                            <></>
                        )}

                    {this.state.chassiExist !== -1 ? (
                        <div className="list-ios input ion-input">
                            <input
                                id="chassi"
                                maxLength="17"
                                className="text-input text-input-md"
                                placeholder="Chassi"
                            />
                        </div>
                    ) : (
                            <></>
                        )}

                    <div
                        id="div-submit"
                        onClick={() => {
                            this.validateLogin();
                        }}
                    >
                        <button
                            color="light"
                            type="submit"
                            className="disable-hover button button-ios button-default button-default-ios button-ios-light"
                        >
                            <span className="button-inner">Entrar</span>
                            <div className="button-effect" />
                        </button>
                    </div>
                    <div className="spacer" />
                </header>
                <MyVerticallyCenteredModal
                    show={this.state.modalShow}
                    onHide={modalClose}
                    destiny={this.state.destiny}
                    history={this.props.history}
                />
            </div>
            </div>
        );
    }

    clearMask() {
        this.setState({
            mask: [
                /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/
            ],
        });
    }

    formatInput() {
        if (document.getElementById("documents").value.length < 12) {
            this.setState({
                mask: [
                    /\d/,
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                    /\d/,
                ],
                document: document.getElementById("documents").value,
            });
        } else {
            this.setState({
                mask: [
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    /\d/,
                    ".",
                    /\d/,
                    /\d/,
                    /\d/,
                    "/",
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    "-",
                    /\d/,
                    /\d/,
                ],
                document: document.getElementById("documents").value,
            });
        }
    }

    validateLogin() {
        document.body.classList.remove("modal-open");
        document.body.classList.add("contact-list");
        let documents =
            this.state.cpfCnpjExist !== -1
                ? document.getElementById("documents").value
                : "";
        let board =
            this.state.placaExist !== -1
                ? document.getElementById("board").value
                : "";
        let chassi =
            this.state.chassiExist !== -1
                ? document.getElementById("chassi").value
                : "";

        if(this.state.flagClerk === 'sim'){
            let clerk = document.getElementById("atendente").value;

            var alphaExp = /^[a-zA-Z.]+$/;

            if(clerk.length === 0 || clerk.length > 30 || !clerk.match(alphaExp)){
                document.getElementById("div-loader").style.display = "none";
                document.getElementById("div-content").style.display = "block";
                document.getElementById("error-text").style.display = "none";
                document.getElementById("client-error-text").style.display = "none";
                document.getElementById("clerk-error-text").style.display = "block";
                if(clerk.length === 0){
                    document.getElementById("clerk-error-small").innerText = "Nome atendente é obrigatório";
                }else if(!clerk.match(alphaExp)){
                    document.getElementById("clerk-error-small").innerText = "Nome atendente deve ser apenas letras e ponto";
                }else{
                    document.getElementById("clerk-error-small").innerText = "Nome atendente deve ser menor ou igual a 30 caracteres";
                }
                
                if ((documents + board + chassi).length > 0) {
                    return;
                }
            }else{
                sessionStorage.setItem("clerkName", clerk);
                document.getElementById("clerk-error-text").style.display = "none";
            }
        }

        if ((documents + board + chassi).length > 0) {
            if (chassi.length < 8 && chassi.length !== 0) {
                document.getElementById("client-error-text").style.display =
                    "block";
                document.getElementById("client-error-text-label").innerHTML =
                    "Chassi deve ter ao menos 8 últimos caracteres";
                document.getElementById("error-text").style.display = "none";
                return;
            }
            if (documents.length === 14) {
                documents = documents.replace(" ", "");
                documents = documents.replace(".", "");
                documents = documents.replace(".", "");
                documents = documents.replace("-", "");
            } else {
                if (documents.length === 18) {
                    documents = documents.replace(" ", "");
                    documents = documents.replace(".", "");
                    documents = documents.replace(".", "");
                    documents = documents.replace("-", "");
                    documents = documents.replace("/", "");
                }
            }
            documents === ""
                ? (documents = "")
                : (documents = "cpfCnpj=" + documents + "&");
            board === "" ? (board = "") : (board = "Placa=" + board + "&");
            chassi === "" ? (chassi = "") : (chassi = "Chassi=" + chassi + "&");
            const url =
                process.env.REACT_APP_API_URL_TEMPO +
                "/titular?" +
                documents +
                board +
                chassi;

            fetch(url, {
                headers: new Headers({
                    token: sessionStorage.getItem("token"),
                    chave: sessionStorage.getItem("chave"),
                    idclientecorporativo: sessionStorage.getItem(
                        "idclientecorporativo"
                    ),
                    idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                    Authorization: sessionStorage.getItem("Authorization"),
                }),
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.codigo !== 999) {
                        if (response.itemCoberto) {
                            login("F9A777F6C2550A281D01D162C40C2588");
                            this.setCoveredItems(response.itemCoberto);
                            GoogleAnalyticsStatusEvent(
                                sessionStorage.getItem("SelectedWalet"),
                                "Autenticação - Sucesso",
                                response.descricao
                            );
                        }
                        if (document.getElementById("error-text") !== null) {
                            document.getElementById(
                                "error-text"
                            ).style.display = "none";
                        }
                    } else {
                        if (response.descricao === "Token inválido.") {
                            document.getElementById(
                                "client-error-text"
                            ).style.display = "block";
                            document.getElementById(
                                "error-text"
                            ).style.display = "none";
                            GoogleAnalyticsStatusEvent(
                                sessionStorage.getItem("SelectedWalet"),
                                "Autenticação - Erro",
                                response.descricao
                            );
                        } else {
                            document.getElementById(
                                "error-text"
                            ).style.display = "block";
                            document.getElementById(
                                "client-error-text"
                            ).style.display = "none";
                            GoogleAnalyticsStatusEvent(
                                sessionStorage.getItem("SelectedWalet"),
                                "Autenticação - Erro",
                                response.descricao
                            );
                        }

                        document.getElementById("div-loader").style.display = "none";
                        document.getElementById("div-content").style.display = "block";
                    }
                    redirect();
                })
                .catch((error) => {
                    console.log(error);
                    GoogleAnalyticsStatusEvent(
                        sessionStorage.getItem("SelectedWalet"),
                        "Autenticação - Erro",
                        "Erro de conexão ao consumo dos serviços JUVO"
                    );
                    if (document.getElementById("client-error-text") !== null) {
                        document.getElementById(
                            "client-error-text"
                        ).style.display = "block";
                        document.getElementById(
                            "client-error-text-label"
                        ).innerHTML =
                            "Estamos com dificuldades no momento tente novamente mais tarde";
                        document.getElementById("error-text").style.display =
                            "none";
                    }
                });
        } else {
            document.getElementById("div-loader").style.display = "none";
            document.getElementById("div-content").style.display = "block";
            document.getElementById("error-text").style.display = "block";
            document.getElementById("client-error-text").style.display = "none";
        }
    }

    setCoveredItems(data) {
        data.map((item, index) => {
            sessionStorage.setItem("PolicyNumber" + index, item.apolice);
        })

        try {
            sessionStorage.setItem(
                "ClientPhoneNumber",
                data[0].titular.telefone.numero
            );
        } catch (error) {
            sessionStorage.setItem("ClientPhoneNumber", "");
        }

        try {
            sessionStorage.setItem(
                "cnpjCpfTitular",
                data[0].titular.cpfCnpj
            );
        } catch (error) {
            sessionStorage.setItem("cnpjCpfTitular", "");
        }

        let cont = 0;
        let size = sessionStorage.length;
        for (let i = 0; i < size; i++) {
            if (sessionStorage.key(i).includes("ConveredItem")) {
                sessionStorage.removeItem(sessionStorage.key(i));
                i = i - 1;
                size = size - 1;
            }
            if (sessionStorage.key(i).includes("ConveredId")) {
                sessionStorage.removeItem(sessionStorage.key(i));
                i = i - 1;
                size = size - 1;
            }
        }

        let currentTimestamp = parseInt(Date.now() / 1000);
        let address;
        for (let i = 0; i < data.length; i++) {
            if (
                data[i].ativo === "V" &&
                data[i].dataHoraFimVigencia / 1000 >= currentTimestamp
            ) {
                if (sessionStorage.getItem("SelectedWalet") === "Automóvel") {
                    if (data[i].veiculo.placa === undefined) {
                        sessionStorage.setItem(
                            "ConveredItem" + i,
                            "Placa não informada"
                        );
                    } else {
                        sessionStorage.setItem(
                            "ConveredItem" + i,
                            data[i].veiculo.placa
                        );
                    }

                    sessionStorage.setItem("ConveredId" + i, data[i].id);
                } else {
                    address = "";
                    address = this.setWalletAddress(
                        address,
                        data[i].enderecoCobertura.endereco
                    );

                    sessionStorage.setItem("ConveredItem" + i, address);
                    if(data[i].idPlano.length > 0){
                        sessionStorage.setItem("ConveredPlanItem" + i, data[i].idPlano);
                    }
                    sessionStorage.setItem("ConveredId" + i, data[i].id);
                    sessionStorage.setItem("SelectedCity" + i, data[i].enderecoCobertura.endereco.cidade);
                    sessionStorage.setItem("SelectedAddress" + i, data[i].enderecoCobertura.endereco.logradouro);
                    sessionStorage.setItem("SelectedUf" + i, data[i].enderecoCobertura.endereco.uf);
                    sessionStorage.setItem("SelectedCep" + i, data[i].enderecoCobertura.endereco.cep);
                    this.handleAddressData(data[i].enderecoCobertura.endereco);
                    const mapsAddress = `
                        ${!!data[i].enderecoCobertura.endereco.logradouro && data[i].enderecoCobertura.endereco.logradouro}
                         ${!!data[i].enderecoCobertura.endereco.numero && data[i].enderecoCobertura.endereco.numero}
                         ${!!data[i].enderecoCobertura.endereco.bairro && data[i].enderecoCobertura.endereco.bairro}
                         ${!!data[i].enderecoCobertura.endereco.complemento && data[i].enderecoCobertura.endereco.complemento}
                         ${!!data[i].enderecoCobertura.endereco.cidade && data[i].enderecoCobertura.endereco.cidade}
                         ${!!data[i].enderecoCobertura.endereco.uf && data[i].enderecoCobertura.endereco.uf}
                    `
                    sessionStorage.setItem(
                        "AddressMapsIdentifier",
                        mapsAddress
                    );
                }
                cont += 1;
            }
        }
        if (cont === 1) {
            this.setState({ destiny: "carregando" });
            // sessionStorage.setItem("ConveredSelectedPlan", data[0].idPlano);
            sessionStorage.setItem("ConveredSelectedItem", data[0].id);

            /** Limpa os dados de planos (Para evitar erro ao clicar em voltar e logar com outro dado (cpf, placa, chassi)) */
            for (var i = 0; i < sessionStorage.length; i++) {
                if (sessionStorage.key(i).includes("ConveredPlanItem")) {
                    sessionStorage.removeItem(sessionStorage.key(i));
                }
            }
            sessionStorage.removeItem("ConveredSelectedPlanToken");
            sessionStorage.removeItem("ConveredSelectedPlanChave");
            sessionStorage.removeItem("ConveredSelectedPlanData");
            sessionStorage.removeItem("ConveredSelectedPlan");

            /** fim */
            if(data[0].idPlano.length > 0){
                sessionStorage.setItem("ConveredSelectedPlan", data[0].idPlano);
                sessionStorage.setItem("ConveredPlanItem0", data[0].idPlano);

                var plans = sessionStorage.getItem("planos");
                plans = JSON.parse(plans);
    
                if(plans != null){
                    Object.keys(plans).map((e, i) => {
                        if(plans[e].plano == data[0].idPlano){
                            sessionStorage.setItem("ConveredSelectedPlanToken", plans[e].tokenplano);
                            sessionStorage.setItem("ConveredSelectedPlanChave", plans[e].chaveplano);
                            sessionStorage.setItem("ConveredSelectedPlanData", JSON.stringify(plans[e]));
                        }
                    });
                }
            }
            const policy = `Nº Apólice: ${sessionStorage.getItem("PolicyNumber0")}`;
            let itemdata = '';
            if(sessionStorage.getItem("SelectedWalet") == 'Automóvel'){
                itemdata = `PLACA ${sessionStorage.getItem("ConveredItem0").toUpperCase()}`;
            }else{ 
                itemdata = this.Capitalize(sessionStorage.getItem("ConveredItem0"), true);
            }
            sessionStorage.setItem("AssistStatementAddress", itemdata);
            sessionStorage.setItem("AssistStatementPolicy", policy);
            this.props.reloadSidebar(this);

            var url_string__logo = window.location.href;
            var params = new URL(url_string__logo);
            let redirectTo = params.searchParams.get("rdct");
            if(redirectTo == 'extrato'){
                params.searchParams.delete("rdct");
                window.history.replaceState({}, '', params);
                sessionStorage.setItem("Action", "AssistStatements");
                this.props.history.push("/carregando");
            }else if(redirectTo == 'acompanhar'){
                params.searchParams.delete("rdct");
                window.history.replaceState({}, '', params);
                sessionStorage.setItem("Action", "Accompaniment");
                this.props.history.push("/carregando");
            }else{
                this.verifyOpenAssistence(data[0].id);
            }
        } else {
            if (cont >= 2) {
                sessionStorage.setItem("Action", "Covered");
                this.props.history.push(`/itens-cobertos`);
                this.props.reloadSidebar(this);
            } else {
                sessionStorage.setItem("SpecificError", "");
                sessionStorage.setItem("GenericError", "false");
                this.consultContact("Error");
            }
        }
    }

    handleAddressData(address) {
        let size = sessionStorage.length;
        for (let i = 0; i < size; i++) {
            if (sessionStorage.key(i) != null) {
                if (sessionStorage.key(i).includes("AddressResid")) {
                    sessionStorage.removeItem(sessionStorage.key(i));
                    i = i - 1;
                    size = size - 1;
                }
            }
        }
        for (var key of Object.keys(address)) {
            sessionStorage.setItem(`AddressResid${key}`, address[key]);
        }
    }

    setWalletAddress(address, type) {
        let logradouro =
            type["logradouro"] !== undefined ? type["logradouro"] + " " : "";
        address = address + logradouro;
        let complemento =
            type["complemento"] !== undefined ? type["complemento"] + " " : "";
        address = address + complemento;
        let bairro = type["bairro"] !== undefined ? type["bairro"] + " " : "";
        address = address + bairro;
        let cidade = type["cidade"] !== undefined ? type["cidade"] + " " : "";
        address = address + cidade;
        let uf = type["uf"] !== undefined ? type["uf"] + " " : "";
        address = address + uf;
        return address;
    }

    verifyOpenAssistence(IdItemCoberto) {
        const urlHours =
            process.env.REACT_APP_API_URL_JUVO +
            "/assistenciaServico/segurado?idItemCoberto=" +
            IdItemCoberto +
            "&notInStatus=3&notInStatus=4&notInStatus=5&notInStatus=6&notInStatus=2"
        fetch(urlHours, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if(Object.values(response).length > 0 && response.codigo !== 999){
                    document.getElementsByClassName("modal")[0].style.display =
                    "block";
                    document.getElementsByClassName(
                        "modal-backdrop"
                    )[0].style.display = "block";
                }else{
                    sessionStorage.setItem("ConveredSelectedItem", IdItemCoberto);
                    sessionStorage.setItem("Action", "ConsultTree");
                    this.props.history.push(`/carregando`);
                }
            })
            .catch((error) => {
                sessionStorage.setItem("ConveredSelectedItem", IdItemCoberto);
                sessionStorage.setItem("Action", "ConsultTree");
                this.props.history.push(`/carregando`);
            });
    }

    consultContact(type) {
        const Token = sessionStorage.getItem("Token");
        const url =
            process.env.REACT_APP_API_URL_ESSENCIA +
            "/cliente-corporativo/contatos";

        fetch(url, {
            headers: new Headers({
                Authorization: "Bearer " + Token,
                "Content-Type": "application/json",
            }),
            method: "post",
            body: JSON.stringify({
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (type === "Login") {
                    this.setState({
                        contacts: response,
                    });
                } else {
                    try {
                        sessionStorage.setItem(
                            "ContactDescription",
                            JSON.stringify(response)
                        );
                        sessionStorage.setItem("GenericError", "False");
                        sessionStorage.setItem(
                            "ContactError",
                            Array.isArray(response) && response.length
                                ? "False"
                                : "True"
                        );
                    } catch (error) {
                        console.log(error);
                        sessionStorage.setItem("ContactDescription", "[]");
                        sessionStorage.setItem("GenericError", "False");
                        sessionStorage.setItem("ContactError", "True");
                    }
                    this.setState({ destiny: "contatos" });
                    this.props.history.push(`/contatos`);
                }
            })
            .catch((error) => {
                if (type === "Login") {
                    this.setState({
                        contacts: [],
                    });
                } else {
                    sessionStorage.setItem("ContactDescription", "[]");
                    sessionStorage.setItem("GenericError", "False");
                    sessionStorage.setItem("ContactError", "True");
                    this.setState({ destiny: "contatos" });
                    this.props.history.push(`/contatos`);
                }
            });
    }

    makePhraseArray(loginType) {
        let loginTypePhrase = [];
        if (loginType.indexOf("cpfcnpj") !== -1)
            loginTypePhrase.push("seu CPF ou CNPJ");
        if (loginType.indexOf("placa") !== -1)
            loginTypePhrase.push("a " + loginType[loginType.indexOf("placa")]);
        if (loginType.indexOf("chassi") !== -1)
            loginTypePhrase.push(
                " " + loginType[loginType.indexOf("chassi")] + " do seu carro"
            );
        return loginTypePhrase;
    }

    makePhrase(loginType) {
        let Phrase;
        for (let i = 0; i < loginType.length; i++) {
            switch (i) {
                case 0:
                    Phrase = this.PhraseInitial(loginType[i]);
                    break;
                default:
                    Phrase = this.PhraseMidle(Phrase, loginType[i]);
                    break;
            }
        }
        return Phrase;
    }

    Capitalize(str, lower = false) {
        if(str == null){
            return '';
        }
        return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
    };


    PhraseInitial(loginType) {
        return loginType;
    }

    PhraseMidle(Phrase, loginType) {
        return Phrase + " ou " + loginType;
    }

    checkAddressNumber() {
        this.consultContact("Login");
    }

    generateContacts(contactDescription, cont) {
        if (contactDescription.flexibefalha !== "sim") {
            return true;
        }
        let result;
        switch (contactDescription.tpcontato) {
            case "ema":
                const mailTo = `mailto:${contactDescription.contato}`;
                result = (
                    <div className={"contact-item"} key={cont}>
                        <a
                            className={"contact-title contact-item"}
                            href={mailTo}
                        >
                            {contactDescription.contato}
                        </a>{" "}
                        <span className={"contact-description contact-item"}>
                            ( {contactDescription.legenda} )
                        </span>
                    </div>
                );
                break;
            case "tel":
                const tel = `tel:+${contactDescription.contato}`;
                result = (
                    <div className={"contact-item"} key={cont}>
                        <a className={"contact-title contact-item"} href={tel}>
                            {contactDescription.contato}
                        </a>{" "}
                        <span className={"contact-description contact-item"}>
                            ( {contactDescription.legenda} )
                        </span>
                    </div>
                );
                break;
            case "url":
                result = (
                    <div className={"contact-item"} key={cont}>
                        <a
                            href={contactDescription.contato}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={"contact-title contact-item"}
                        >
                            {contactDescription.urlimgcontato != undefined && contactDescription.urlimgcontato != null ?
                            <img src={contactDescription.urlimgcontato}/>
                            : 
                            contactDescription.contato}
                        </a>{" "}
                        <span className={"contact-description contact-item"}>
                            ( {contactDescription.legenda} )
                        </span>
                    </div>
                );
                break;
            default:
                console.log(contactDescription.tpcontato);
                break;
        }
        return result;
    }
}

class MyVerticallyCenteredModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            redirectLabel: "/contatos",
        };
        document.body.classList.add("modal-open");
        document.body.classList.remove("contact-list");
    }

    render() {
        return this.state.redirect === true ? (
            <Redirect to={this.state.redirectLabel} push />
        ) : (
                <Modal
                    {...this.props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => { this.onCloseModal(this.props.history) }}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            AVISO
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            Há assistência em andamento, deseja acompanhar?
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div
                            onClick={this.changeToAccompaniment.bind(this)}
                            style={{ cursor: "pointer" }}
                            className="waves-effect waves-light btn-large btn-success"
                        >
                            <i className="fa fa-lg fa-check container__button--margin " />{" "}
                        Sim
                    </div>
                        <NavLink
                            onClick={this.changeToQuestion}
                            to="carregando"
                            className="waves-effect waves-light btn-large btn-danger"
                        >
                            <i className="fa fa-lg fa-times container__button--margin " />{" "}
                        Não
                    </NavLink>
                    </Modal.Footer>
                </Modal>
            );
    }

    async changeToAccompaniment() {
        const retorno = await Accompaniment(this.props);
        if (retorno) {
            sessionStorage.setItem("Action", "Accompaniment");
            topFunction();
            this.setState({
                redirect: true,
                redirectLabel: "/acompanhamentos",
            });
        } else {
            console.log("");
        }
    }

    changeToQuestion() {
        sessionStorage.setItem("Action", "ConsultTree");
    }

    onCloseModal(history) {
        sessionStorage.setItem("Action", "ConsultTree");
        history.push(`/carregando`);
    }
}

function redirect() {
    topFunction();
}

export default Login;
