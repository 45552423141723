import React, { Component } from "react";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import { backPage, topFunction, eraseAddress } from "../App";
import MaskedInput from "react-text-mask";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import { setMinutes, setHours, addHours, addDays, roundToNearestMinutes, differenceInHours } from 'date-fns';
import { CustomInputDate, CustomInputHour } from "../components/CustomInput";
import Modal from "react-bootstrap/Modal";

registerLocale("pt", pt);
var numbers = [];
for (var i = 0; i < sessionStorage.length; i++) {
    if (sessionStorage.key(i).includes("Contact")) {
        numbers.push(sessionStorage.getItem(sessionStorage.key(i)));
    }
}

// const socket = require("socket.io-client").connect(
//     process.env.REACT_APP_URL_WEBSOCKET_SPEECH,
//     {
//         /*, 'reconnectionAttempts' : 5*/
//     }
// );

// //================= CONFIG =================
// let bufferSize = 2048,
//     AudioContext,
//     context,
//     processor,
//     input,
//     globalStream;

// //audioStream constraints
// const constraints = {
//     audio: true,
//     video: false,
// };

class PersonalInformation extends Component {
    constructor(props) {
        super(props);

        var dt = new Date();
        dt.setHours(dt.getHours() + 3);

        // @todo fazer validação para texto aparecer quando for a carteira 'casa'
        if (sessionStorage.getItem("FAZERVALIDAÇÃOAPOSAPI")) {
            document.getElementById("warning-house").style.display = "block";
        }

        const homeCustomField = sessionStorage.getItem("HomeCustomField" + sessionStorage.getItem("SelectedWaletId"));
        let idclientecorporativo = sessionStorage.getItem("idclientecorporativo");
        const hourString = idclientecorporativo === '542' ? '2:00h' : '3:00h';

        this.state = {
            hourString,
            homeCustomField,
            errorMessage: false,
            date: new Date(),
            minDate: dt,
            startDate: null,
            mask: [
                "(",
                /\d/,
                /\d/,
                ")",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
            ],
            email: "",
            number: "",
            recordText: "Gravar...",
            showSelectedDate: false,
            idclientecorporativo,
            modalShow: false,
            endDateStr: '',
            startDateStr: '',
            startDateClick: '',
            assistenceNumber: ''
        };

        props.keep();
        eraseAddress();
        backPage(this);
        let ArrayQuestions = JSON.parse(
            sessionStorage.getItem("ArrayQuestions")
        );
        if (
            ArrayQuestions[ArrayQuestions.length - 1] !==
            ArrayQuestions[ArrayQuestions.length - 2]
        ) {
            ArrayQuestions.push(ArrayQuestions[ArrayQuestions.length - 1]);
            sessionStorage.setItem(
                "ArrayQuestions",
                JSON.stringify(ArrayQuestions)
            );
        }
    }

    onChange = (date) => this.setState({ date });

    componentDidMount() {
        sessionStorage.setItem("Action", "NextQuest");
        sessionStorage.setItem("NoAllowOrigin", "True");
        document.getElementsByClassName("undefined")[0].readOnly = "true";
        let foo = document.getElementsByClassName("undefined")[0];

        function maxNumber(max) {
            let running = false;
            return function () {
                if (running) return;
                running = true;
                if (parseFloat(this.value) > max) {
                    this.value = 59;
                }
                running = false;
            };
        }

        let f = maxNumber(59);

        foo.addEventListener("keyup", f);
        foo.addEventListener("blur", f);
    }

    handleChange = (date) => {
        if (!this.state.showSelectedDate) {
            this.setState({
                showSelectedDate: true
            })
        }

        var today = new Date();
        if (!(date instanceof Date)) {
            return;
        }
        const diffTime = date.getTime() - today.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60));
        if (diffDays <= 180) {
            document.getElementById("warning-low-time").style.display = "block";
            this.setState({
                startDate: addHours(new Date(Math.ceil(new Date().getTime() / 900000) * 900000), 3).getTime()
            });
        } else {
            document.getElementById("warning-low-time").style.display = "none";
            this.setState({
                startDate: date,
            });
        }
    };

    render() {
        let modalClose = () => this.setState({ modalShow: false }); 
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div>
                    <div className="content-header">
                        <div className="persona-header">
                            <div className="persona-header__content">
                                <h3 className="persona-header__title">
                                    Dados do responsável no local
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="personal-info section">
                        {this.state.errorMessage ? (
                            <div className="info-error-container">
                                <label className="text-action-button-color error__text">
                                    <small className={"error-size-text"}>
                                        Preencha todos os dados obrigatórios
                                    </small>
                                </label>
                            </div>
                        ) : (
                                <></>
                            )}
                        <div className={"form-container"}>
                            <label className={"question-color-text"}>
                                Nome *
                            </label>
                            <div className="input ion-input personal-info--input">
                                <input
                                    id="nome"
                                    type="text"
                                    className="text-input text-input-md"
                                    placeholder="Nome"
                                    required
                                />
                            </div>
                            <label
                                id={"nome-label"}
                                style={{ display: "none" }}
                                className={"error-label"}
                            >
                                Campo Nome é obrigatório
                            </label>
                        </div>

                        <div className={"form-container"}>
                            <label className={"question-color-text"}>
                                Telefone com DDD *
                            </label>
                            <div className="input ion-input personal-info--input">
                                <MaskedInput
                                    required
                                    mask={this.state.mask}
                                    className="text-input text-input-md"
                                    placeholder="(11) 99999-9999"
                                    guide={false}
                                    type="tel"
                                    id="telefone"
                                    value={this.state.number}
                                    onBlur={() => { }}
                                    onKeyUp={() => {
                                        if (
                                            document.getElementById("telefone")
                                                .value.length > 13
                                        ) {
                                            this.setState({
                                                mask: [
                                                    "(",
                                                    /\d/,
                                                    /\d/,
                                                    ")",
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    "-",
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                ],
                                                number: document.getElementById(
                                                    "telefone"
                                                ).value,
                                            });
                                        } else {
                                            this.setState({
                                                mask: [
                                                    "(",
                                                    /\d/,
                                                    /\d/,
                                                    ")",
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    "-",
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                    /\d/,
                                                ],
                                                number: document.getElementById(
                                                    "telefone"
                                                ).value,
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <label
                                id={"telefone-label"}
                                style={{ display: "none" }}
                                className={"error-label"}
                            >
                                Campo Telefone é obrigatório
                            </label>
                        </div>

                        {!!this.state.homeCustomField && (
                            <div className={"form-container"}>
                                <label className={"question-color-text"}>
                                    {`E-mail ${this.state.homeCustomField === "sim" ? "*" : ""}`}
                                </label>
                                <div className="input ion-input personal-info--input">
                                    <input
                                        required
                                        mask={false}
                                        className="text-input text-input-md"
                                        placeholder="Informe seu e-mail"
                                        guide={false}
                                        type="mail"
                                        id="email"
                                        onChange={(e) => {
                                            this.setState({ email: e.target.value })
                                        }}
                                        value={this.state.email}
                                        onBlur={() => { }}
                                    />
                                </div>
                                <label
                                    id={"email-label"}
                                    style={{ display: "none" }}
                                    className={"error-label"}
                                >
                                    Campo E-mail é obrigatório
                                </label>
                            </div>
                        )}

                        <div className={"form-container"}>
                            <label className={"question-color-text"}>
                                Quando você gostaria de usar o serviço? *
                            </label>
                            <div>
                                <select
                                    id="combo-box"
                                    onChange={this.showDatePicker.bind(this)}
                                    defaultValue={""}
                                    className="input ion-input personal-info--select"
                                    required
                                >
                                    <option value="" disabled>
                                        Escolha um ...
                                    </option>
                                    <option value="Imediato">Imediato</option>
                                    <option value="Agendado">Agendado</option>
                                </select>
                            </div>
                            <label
                                id={"combo-box-label"}
                                style={{ display: "none" }}
                                className={"error-label"}
                            >
                                Campo de seleção de serviço é obrigatório
                            </label>
                        </div>

                        <div
                            className={"form-container"}
                            id="date-picker"
                            style={{ display: "none" }}
                        >
                            <div className="datebox">
                                <div className="datepicker-wrapper date">
                                    <span className="main-text-color">Melhor data *</span>
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.handleChange}
                                        locale="pt"
                                        minDate={this.state.minDate}
                                        maxDate={addDays(new Date(), 30)}
                                        dateFormat="dd/MM/yyyy"
                                        customInput={<CustomInputDate />}
                                    />
                                </div>
                                <div className="datepicker-wrapper time">
                                    <span className="main-text-color">Melhor horário *</span>
                                    <DatePicker
                                        selected={this.state.startDate}
                                        onChange={this.handleChange}
                                        locale="pt"
                                        minTime={differenceInHours(this.state.startDate, new Date()) <= 3
                                            ? addHours(new Date(Math.ceil(new Date().getTime() / 900000) * 900000), 3)
                                            : setHours(setMinutes(new Date(), 0), 0)
                                        }
                                        maxTime={setHours(setMinutes(new Date(), 45), 23)}
                                        dateFormat="HH:mm"
                                        timeIntervals={15}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        customInput={<CustomInputHour />}
                                    />
                                </div>
                            </div>

                            {this.state.showSelectedDate && (
                                <div className="datebox">
                                    <div className="datepicker-wrapper">
                                        <span className="main-text-color">Data e hora do agendamento</span>
                                        <DatePicker
                                            selected={this.state.startDate}
                                            onChange={this.handleChange}
                                            locale="pt"
                                            minDate={this.state.minDate}
                                            dateFormat="Pp"
                                            disabled
                                        />
                                    </div>

                                    <div className="datepicker-wrapper">
                                        <span className="main-text-color">Prazo máximo para chegada até o local</span>
                                        <DatePicker
                                            selected={new Date(this.state.startDate).getTime() + (this.state.idclientecorporativo === '542' ? 7200000 : 10800000)}
                                            onChange={this.handleChange}
                                            locale="pt"
                                            minDate={this.state.minDate}
                                            dateFormat="Pp"
                                            disabled
                                        />
                                    </div>
                                </div>
                            )}

                            <small
                                style={{ display: "none" }}
                                id="warning-house"
                                className={"question-color-text"}
                            >
                                Alertarmos que o profissional tem uma tolerancia
                                de ate 3 horas para chegar no local do
                                atendimento
                            </small>
                            <small
                                style={{ display: "none", marginTop: 24 }}
                                id="warning-low-time"
                                className={"question-color-text"}
                            >
                                {`Agendamentos para o mesmo dia devem ter ao menos
                                de ${this.state.hourString} de diferença da data da solicitação`}
                            </small>
                        </div>

                        <div className={"form-container"}>
                            <label className={"question-color-text"}>
                                Detalhe aqui o problema ocorrido *:
                            </label>
                            {/* <button
                                type={"button"}
                                className={"record-btn"}
                                id="buttonMicrophone"
                                style={{ display: "block" }}
                                // onClick={startRecording}
                            >
                                <span id={"record-text"}>Iniciar Gravação</span>{" "}
                                <i className="fa fa-microphone " />
                            </button> */}
                            <audio></audio>

                            <div>
                                <textarea
                                    id="observacao"
                                    className="personal-info--select"
                                    rows="3"
                                    maxLength="200"
                                />
                            </div>
                            <label
                                id={"observacao-label"}
                                style={{ display: "none" }}
                                className={"error-label"}
                            >
                                Campo de descrição do problema ocorrido é obrigatório
                            </label>
                        </div>
                        <div className="text-center">
                            <button
                                className={"around-btn-light"}
                                onClick={this.clickEvent.bind(this)}
                            >
                                <div
                                    color="light"
                                    className="disable-hover button button-ios button-default button-default-ios button-ios-light"
                                >
                                    <span className="button-inner">
                                        Prosseguir
                                    </span>
                                    <div className="button-effect" />
                                </div>
                            </button>
                        </div>
                    </div>
                    <MyVerticallyCenteredModal
                        show={this.state.modalShow}
                        onHide={modalClose}
                        handleChange={this.handleChange}
                        assistenceNumber={this.state.assistenceNumber}
                        startDateClick={this.state.startDateClick}
                        startDateStr={this.state.startDateStr}
                        endDateStr={this.state.endDateStr}
                    />
                </div>
            </CSSTransition>
        );
    }

    preAssistScheduling() {
        const url =
        process.env.REACT_APP_API_URL_TEMPO +
        "/pre-assistencia/agendamento";

        let str = document.getElementById("telefone").value
        let ddd = str.substr(1, 2);
        var number = "";
        if (str.length !== 14) {
            number = str.substr(4, 4) + str.substr(9, 4);
            for (let i = 0; i < number.length; i++) {
                if (number[i] === "_") {
                    number = number.replace("_", "");
                    i--;
                }
            }
        } else {
            number = str.substr(4, 5) + str.substr(10, 4);
            for (let i = 0; i < number.length; i++) {
                if (number[i] === "_") {
                    number = number.replace("_", "");
                    i--;
                }
            }
        }

        let body = {
            "preNovoServicoAssistencia":{
                "idAssistencia": sessionStorage.getItem("PreAssistId")
            },
            "nomeContato": document.getElementById("nome").value,
            "telefoneContato": {
                "ddi": '55',
                "ddd": ddd,
                "numero": number
            },
            "email": document.getElementById("email") ? document.getElementById("email").value : '',
            "grauParentesco": "",
            "cnpjCpfTitular":sessionStorage.getItem("cnpjCpfTitular"),
            "descricao": document.getElementById("observacao").value
        };

        let dateScheduling = "";
        let dateFinish = "";
        if (document.getElementById("combo-box").value === "Agendado") {
            if (typeof this.state.startDate == "number") {
                dateScheduling = this.state.startDate;
                dateFinish = this.state.startDate + (this.state.idclientecorporativo === '542' ? 7200000 : 10800000);
            } else {
                dateScheduling = this.state.startDate.getTime();
                dateFinish = this.state.startDate.getTime() + (this.state.idclientecorporativo === '542' ? 7200000 : 10800000);
            }

            if(sessionStorage.getItem("SelectedWalet") == "Automóvel"){
                body.dataAgendamento = dateScheduling;
            }else{
                body.dataPrevisaoInicio = dateScheduling;
                body.dataPrevisaoFim = dateFinish;
            }
        }
        
        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
                "Content-Type": "application/json",
                "X-JuvoTransacaoID": sessionStorage.getItem("juvoTransactionID")
            }),
            method: "put",
            body: JSON.stringify(body),
        })
            .then((response) => {
                try {
                    return response.json();
                } catch (error) {
                    return Promise.resolve({});
                }
            })
            .then((response) => {
                if (response.idAssistencia) {
                    this.props.history.push("/AddressOrigin");
                } 
            })
            .catch((error) => {
                console.log(error);
            });
    }

    clickEvent() {
        let isValid = this.validateFields();
        if (!isValid) return;
        sessionStorage.setItem(
            "ContactName",
            document.getElementById("nome").value
        );
        sessionStorage.setItem(
            "ContactTel",
            document.getElementById("telefone").value
        );
        sessionStorage.setItem(
            "ContactType",
            document.getElementById("combo-box").value
        );
        sessionStorage.setItem(
            "descriptionService",
            document.getElementById("observacao").value
        );
        if (document.getElementById("email")) {
            sessionStorage.setItem(
                "ContactEmail",
                document.getElementById("email").value
            )
        } else {
            sessionStorage.setItem(
                "ContactEmail",
                ""
            )
        }
        var isOmni = sessionStorage.getItem("FlagPreAssist");

        if (sessionStorage.getItem("ContactType") === "Imediato") {
            sessionStorage.setItem("ContactDateTime", "");
            sessionStorage.setItem("AssistTimeType", "Imediato");
            if(isOmni != 'True'){
                this.props.history.push("/AddressOrigin");
            }
        } else {
            if (typeof this.state.startDate == "number") {
                sessionStorage.setItem("ContactDateTime", this.state.startDate);
                sessionStorage.setItem("AssistTimeType", "Agendado");
            } else {
                sessionStorage.setItem("AssistTimeType", "Agendado");
                sessionStorage.setItem(
                    "ContactDateTime",
                    this.state.startDate.getTime()
                );
            }
            if(isOmni != 'True'){
                this.props.history.push("/AddressOrigin");
            }
        }
        
        if(isOmni == 'True'){
            this.preAssistScheduling();
        }
        
        sessionStorage.setItem(
            "ContactDescription",
            document.getElementById("observacao").value
        );
        topFunction();
    }

    validateFields() {
        let name = this.checkField("nome");
        let tel = this.checkField("telefone");
        let type = this.checkField("combo-box");
        let description = this.checkField("observacao");
        let email = true
        if (this.state.homeCustomField === "sim") {
            email = this.checkField("email");
        }
        let date = document.querySelector('.datepicker-wrapper.date .react-datepicker__input-container button').textContent;
        let time = document.querySelector('.datepicker-wrapper.time .react-datepicker__input-container button').textContent;

        if ((date === "Selecione uma data" || time === "Selecione um horário") && document.getElementById("combo-box").value !== "Imediato") {
            this.setState({
                errorMessage: true,
            });
            return false;
        }
        if (document.getElementById("combo-box").value === "Imediato") {
            date = true;
            time = true;
        }



        if (name && tel && type && description && !!date && !!time && !!email) {
            this.setState({
                errorMessage: false,
            });
            return true;
        } else {
            this.setState({
                errorMessage: true,
            });
            return false;
        }
    }

    checkField(field) {
        let fieldValue = document.getElementById(field).value;
        if (fieldValue === "") {
            this.throwError(field);
            return false;
        } else {
            this.fieldCorrect(field);
            return true;
        }
    }

    throwError(field) {
        if (field === "combo-box") {
            document.getElementById("combo-box").style.borderColor =
                "var(--danger--color)";
        } else {
            document.getElementById(field).parentElement.style.borderColor =
                "var(--danger--color)";
        }

        document.getElementById(field + "-label").style.display = "block";
    }

    fieldCorrect(field) {
        document.getElementById(field).parentElement.style.borderColor =
            "unset";
        document.getElementById(field + "-label").style.display = "none";
    }

    async showDatePicker() {
        if (document.getElementById("combo-box").value === "Imediato") {
            document.getElementById("date-picker").style.display = "none";
        } else {
            if (document.getElementById("combo-box").value === "Agendado") {
                document.getElementById("date-picker").style.display = "block";
                await this.getAssistData();
            }
        }
    }

    async getAssistData() {
        const url =
            process.env.REACT_APP_API_URL_JUVO +
            "/assistenciaServico/segurado?idItemCoberto=" +
            sessionStorage.getItem("ConveredSelectedItem") +
            "&notInStatus=2&notInStatus=3&notInStatus=4&notInStatus=5&notInStatus=6";

        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
            }),
        })
            .then((response) => {
                try {
                    return response.json();
                } catch (error) {
                    return Promise.resolve({});
                }
            })
            .then((response) => {
                if (response.length !== 0) {
                    for (let k in response) {
                        if (response[k].servicos[0].dataPrevisaoInicial) {
                            this.setState({ modalShow: true, assistenceNumber: response[k].idAssistencia, startDateClick: response[k].servicos[0].dataPrevisaoInicial, startDateStr: response[k].servicos[0].dataPrevisaoInicialStr, endDateStr: response[k].servicos[0].dataPrevisaoFinalStr });
                            break;
                        }
                    }
                }
            });
    }
}

class MyVerticallyCenteredModal extends React.Component {
    handleChange = (date) => {
        if (!this.state.showSelectedDate) {
            this.setState({
                showSelectedDate: true
            })
        }

        var today = new Date();
        if (!(date instanceof Date)) {
            return;
        }
        const diffTime = date.getTime() - today.getTime();
        const diffDays = Math.ceil(diffTime / (1000 * 60));
        if (diffDays <= 180) {
            document.getElementById("warning-low-time").style.display = "block";
            this.setState({
                startDate: addHours(new Date(Math.ceil(new Date().getTime() / 900000) * 900000), 3).getTime()
            });
        } else {
            document.getElementById("warning-low-time").style.display = "none";
            this.setState({
                startDate: date,
            });
        }
    };

    handleClick = () => {
        var dt = new Date(this.props.startDateClick);
        this.props.handleChange(dt);
        this.props.onHide();    
    }

    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        AVISO
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Encontramos a assistência {this.props.assistenceNumber} aberta, solicitado de forma agendada, para {this.props.startDateStr}, prazo de chegada até {this.props.endDateStr} . Deseja agendar para o mesmo dia e horário?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={this.handleClick}
                        className="waves-effect waves-light btn-large btn-success"
                    >
                        <i className="fa fa-lg fa-check container__button--margin " />{" "}
                        Sim
                    </button>
                    <button
                        onClick={this.props.onHide}
                        className="waves-effect waves-light btn-large btn-danger"
                    >
                        <i className="fa fa-lg fa-times container__button--margin " />{" "}
                        Não
                    </button>
                </Modal.Footer>
            </Modal>
        );
    }
}

// let streamStreaming = false;

// //================= RECORDING =================
// function startRecording() {
//     if (socket.connected === true) {
//         if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
//             if (streamStreaming === false) {
//                 document.getElementById("buttonMicrophone").style.background =
//                     "var(--danger--color)";
//                 document.getElementById("record-text").innerText =
//                     "Finalizar Gravação ";
//                 socket.emit("startGoogleCloudStream", ""); //init socket Google Speech Connection
//                 streamStreaming = true;
//                 AudioContext = window.AudioContext || window.webkitAudioContext;
//                 context = new AudioContext({
//                     latencyHint: "interactive",
//                 });
//                 processor = context.createScriptProcessor(bufferSize, 1, 1);
//                 processor.connect(context.destination);
//                 context.resume();

//                 var handleSuccess = function (stream) {
//                     globalStream = stream;
//                     input = context.createMediaStreamSource(stream);
//                     input.connect(processor);

//                     processor.onaudioprocess = function (e) {
//                         var left = e.inputBuffer.getChannelData(0);
//                         var left16 = downsampleBuffer(left, 44100, 16000);
//                         socket.emit("binaryData", left16);
//                     };
//                 };

//                 navigator.mediaDevices
//                     .getUserMedia(constraints)
//                     .then(handleSuccess);
//             } else {
//                 document.getElementById("buttonMicrophone").style.background =
//                     "var(--success--color)   ";
//                 document.getElementById("record-text").innerText =
//                     "Iniciar Gravação ";
//                 streamStreaming = false;
//                 socket.emit("endGoogleCloudStream", "");

//                 let track = globalStream.getTracks()[0];
//                 track.stop();

//                 input.disconnect(processor);
//                 processor.disconnect(context.destination);
//                 context.close().then(function () {
//                     input = null;
//                     processor = null;
//                     context = null;
//                     AudioContext = null;
//                 });
//             }
//         }
//     }
// }

// //================= SOCKET IO =================
// socket.on("connect", function (data) {
//     try {
//         document.getElementById("buttonMicrophone").style.display = "block";
//     } catch (Exception) {}
// });

// socket.on("disconnect", function (data) {
//     try {
//         document.getElementById("buttonMicrophone").style.display = "none";
//     } catch (Exception) {}
// });

// socket.on("reconnecting", function (data) {
//     try {
//         if (socket.connected === false) {
//             document.getElementById("buttonMicrophone").style.display = "none";
//         } else {
//             document.getElementById("buttonMicrophone").style.display = "block";
//         }
//     } catch (Exception) {}
// });

// socket.on("speechData", function (data) {
//     var dataFinal = undefined || data.results[0].isFinal;

//     if (dataFinal === false) {
//         if (this.observacaoFinal === undefined) {
//             document.getElementById("observacao").value =
//                 data.results[0].alternatives[0].transcript;
//         } else {
//             document.getElementById("observacao").value =
//                 this.observacaoFinal +
//                 " " +
//                 data.results[0].alternatives[0].transcript;
//         }
//     } else if (dataFinal === true) {
//         if (this.observacaoFinal === undefined) {
//             this.observacaoFinal = data.results[0].alternatives[0].transcript;
//             document.getElementById("observacao").value = this.observacaoFinal;
//         } else {
//             this.observacaoFinal =
//                 this.observacaoFinal +
//                 " " +
//                 data.results[0].alternatives[0].transcript;
//             document.getElementById("observacao").value = this.observacaoFinal;
//         }
//     }
// });

// var downsampleBuffer = function (buffer, sampleRate, outSampleRate) {
//     if (outSampleRate === sampleRate) {
//         return buffer;
//     }
//     if (outSampleRate > sampleRate) {
//         throw new Error(
//             "downsampling rate show be smaller than original sample rate"
//         );
//     }

//     var sampleRateRatio = sampleRate / outSampleRate;
//     var newLength = Math.round(buffer.length / sampleRateRatio);
//     var result = new Int16Array(newLength);
//     var offsetResult = 0;
//     var offsetBuffer = 0;

//     while (offsetResult < result.length) {
//         var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
//         var accum = 0,
//             count = 0;
//         for (
//             var i = offsetBuffer;
//             i < nextOffsetBuffer && i < buffer.length;
//             i++
//         ) {
//             accum += buffer[i];
//             count++;
//         }

//         result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
//         offsetResult++;
//         offsetBuffer = nextOffsetBuffer;
//     }

//     return result.buffer;
// };

export default PersonalInformation;
