import React, { Component } from "react";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import { backPage, importStyle, topFunction } from "../App";
import { GoToLogin } from "./controller/ControllerFunctions.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
require("dotenv").config();

class Home extends Component {
    constructor(props) {
        super(props);
        const numbers = [];
        const url = [];
        const id = [];

        var url_string__logo = window.location.href;
        var corporate_client = new URL(url_string__logo);
        let paramUrl = corporate_client.searchParams.get("parametro");
        
        for (var i = 0; i < sessionStorage.length; i++) {
            if (sessionStorage.key(i).includes("HomeName")) {
                numbers.push(numbers.length);
            }
        }

        for (var k = 0; k < numbers.length; k++) {
            id[k] = sessionStorage.getItem("HomeId" + k);
            numbers[k] = sessionStorage.getItem("HomeName" + k);
            url[k] = sessionStorage.getItem("Home" + numbers[k]);
        }

        this.state = {
            numbers,
            url,
            id,
            paramUrl
        };
        if (
            sessionStorage.length <= 6 ||
            sessionStorage.getItem("checkClient") === "false"
        ) {
            sessionStorage.setItem("Action", "Home");
            sessionStorage.removeItem("Logo");
            sessionStorage.removeItem("Favicon");
            var url_string__logo = window.location.href;
            var corporate_client = new URL(url_string__logo);
            let c = corporate_client.searchParams.get("c");
            let idCarteira = corporate_client.searchParams.get("idCarteira");
            let idItem = corporate_client.searchParams.get("idItem");
            let urlRedirect = this.makeUrlRedirect(c, idCarteira, idItem);
            this.props.history.push(urlRedirect);
        } else {
            var stylePerso = [];
            for (let i = 0; i < sessionStorage.length; i++) {
                if (sessionStorage.key(i).includes("--")) {
                    stylePerso.push(sessionStorage.key(i));
                    stylePerso.push(
                        sessionStorage.getItem(sessionStorage.key(i))
                    );
                }
            }
            importStyle(stylePerso);
        }
        backPage(this);

        sessionStorage.setItem("BackPage", '["/"]');
        sessionStorage.setItem("ArrayQuestions", "[]");

        setTimeout(() => {
            if (sessionStorage.getItem("HomeId1") === null) {
                sessionStorage.setItem(
                    "SelectedWalet",
                    sessionStorage.getItem("HomeName0")
                );
                sessionStorage.setItem(
                    "SelectedWaletId",
                    sessionStorage.getItem("HomeId0")
                );
                topFunction();
                var url_string__logo = window.location.href;
                var corporate_client = new URL(url_string__logo);
                let paramUrl = corporate_client.searchParams.get("parametro");
                const Token = sessionStorage.getItem("Token");
                if (sessionStorage.getItem("Action") !== null && paramUrl === null) {
                    sessionStorage.setItem("Action", "GoToLogin");
                    this.props.history.push("/carregando");
                }
            }
        });
    }

    async componentDidMount(){
        /** Caso exista parametro para login automático */
        var url_string__logo = window.location.href;
        var corporate_client = new URL(url_string__logo);
        let paramUrl = corporate_client.searchParams.get("parametro");
        const Token = sessionStorage.getItem("Token");
        if(paramUrl !== null && Token !== null){
            let response = await this.decryptParam(paramUrl);
            
            if(response !== undefined && response.cpfcnpj !== null && response.apolice !== null && response.idcarteira !== null && response.idclientecorporativo !== null){
                this.setData(response);
                this.handleClick(response.idcarteira);
            }
        }
    }

    /** Realiza a decriptação dos dados */
    async decryptParam (param){
        const Token = sessionStorage.getItem("Token");
        const url = process.env.REACT_APP_API_URL_ESSENCIA + "/cliente-corporativo/url-descriptografar";
        let response = await fetch(url, {
            headers: new Headers({
                Authorization: "Bearer " + Token,
                "Content-Type": "application/json",
            }),
            method: "post",
            body: JSON.stringify({
                parametro: param
            }),
        })
        .then((response) => response.json())
        .then((response) => {
            return response;
        })
        .catch((error) => {
            sessionStorage.setItem("SpecificError", "");
            sessionStorage.setItem("GenericError", "True");
        });

        return response;
    }

    /** Salva os dados necessários no storage */
    setData(params){
        sessionStorage.setItem("cpflogin", params.cpfcnpj);
        sessionStorage.setItem("apolicelogin", params.apolice);
    }

    render() {
        return (
            <CSSTransition
            in={this.state.paramUrl != null ? false : true}
            appear={this.state.paramUrl != null ? false : true}
            timeout={this.state.paramUrl != null ? false : 800}
            classNames={this.state.paramUrl != null ? "" : "fade"}
            >
                <div>
                    <div className="loader__position" style={{ display: this.state.paramUrl ? "block" : "none", textAlign: "center" }}>
                        <div className="lds-ring">
                            <div
                                style={
                                    this.state.bgColor !== "#FFFFFF" ||
                                        this.state.bgColor !== "#FFF" ||
                                        this.state.bgColor !== "#ffffff" ||
                                        this.state.bgColor !== "#fff"
                                        ? {
                                            borderColor:
                                                "#DDDDDD transparent transparent transparent",
                                        }
                                        : {
                                            borderColor:
                                                "#FFFFFF transparent transparent transparent",
                                        }
                                }
                            ></div>
                            <div
                                style={
                                    this.state.bgColor !== "#FFFFFF" ||
                                        this.state.bgColor !== "#FFF" ||
                                        this.state.bgColor !== "#ffffff" ||
                                        this.state.bgColor !== "#fff"
                                        ? {
                                            borderColor:
                                                "#DDDDDD transparent transparent transparent",
                                        }
                                        : {
                                            borderColor:
                                                "#FFFFFF transparent transparent transparent",
                                        }
                                }
                            ></div>
                            <div
                                style={
                                    this.state.bgColor !== "#FFFFFF" ||
                                        this.state.bgColor !== "#FFF" ||
                                        this.state.bgColor !== "#ffffff" ||
                                        this.state.bgColor !== "#fff"
                                        ? {
                                            borderColor:
                                                "#DDDDDD transparent transparent transparent",
                                        }
                                        : {
                                            borderColor:
                                                "#FFFFFF transparent transparent transparent",
                                        }
                                }
                            ></div>
                            <div
                                style={
                                    this.state.bgColor !== "#FFFFFF" ||
                                        this.state.bgColor !== "#FFF" ||
                                        this.state.bgColor !== "#ffffff" ||
                                        this.state.bgColor !== "#fff"
                                        ? {
                                            borderColor:
                                                "#DDDDDD transparent transparent transparent",
                                        }
                                        : {
                                            borderColor:
                                                "#FFFFFF transparent transparent transparent",
                                        }
                                }
                            ></div>
                        </div>
                    </div>
                    <div style={{ display: this.state.paramUrl ? "none" : "block" }}>
                    <div
                        onLoad={this.props.reloadFunc}
                        className="content-header"
                    >
                        <div className="persona-header">
                            <div className="persona-header__content">
                                <h3 className="persona-header__title">
                                    Para qual produto você precisa de
                                    assistência?
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="row center"></div>
                        <div
                            id="home-wallet"
                            className="row row__cards--display"
                        >
                            <Row>
                                {this.state.numbers.map((number, cont) => (
                                    <Col
                                        xs={6}
                                        sm={6}
                                        md={6}
                                        large={6}
                                        xl={6}
                                        className="col s6 m6 l4"
                                        key={sessionStorage.getItem(
                                            "HomeId" + cont
                                        )}
                                    >
                                        <div
                                            id={this.state.id[cont]}
                                            onClick={this.handleClick.bind(
                                                this,
                                                this.state.id[cont]
                                            )}
                                            className={"card-link " + number}
                                        >
                                            <div className="card card--sant home-wallet--card">
                                                <div className="card-image">
                                                    <img
                                                        alt={
                                                            this.state.numbers[
                                                                cont
                                                            ]
                                                        }
                                                        className="card__img"
                                                        src={
                                                            this.state.url[cont]
                                                        }
                                                    />
                                                </div>
                                                <div className="card-content">
                                                    <h2 id="title1">
                                                        {
                                                            this.state.numbers[
                                                                cont
                                                            ]
                                                        }
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                </div>
                </div>
            </CSSTransition>
        );
    }

    handleClick(id) {
        try{
            sessionStorage.setItem(
                "SelectedWalet",
                document.getElementById(id).classList[1]
            );
            sessionStorage.setItem("SelectedWaletId", id);
            topFunction();
            GoToLogin(this.props);
            this.props.reloadFunc();
        }catch(error){}
    }

    makeUrlRedirect(c, idCarteira, idItem) {
        let urlRedirect = "/carregando?c=" + c;
        idCarteira !== null
            ? (urlRedirect += "&idCarteira=" + idCarteira)
            : (urlRedirect += "");
        idItem !== null
            ? (urlRedirect += "&idItem=" + idItem)
            : (urlRedirect += "");

        return urlRedirect;
    }
}

export default Home;
