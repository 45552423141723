import React, { Component } from "react";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import { backPage, topFunction, eraseAddress } from "../App";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";

registerLocale("pt", pt);
var numbers = [];
for (var i = 0; i < sessionStorage.length; i++) {
    if (sessionStorage.key(i).includes("Contact")) {
        numbers.push(sessionStorage.getItem(sessionStorage.key(i)));
    }
}

class AssistStatement extends Component {
    constructor(props) {
        super(props);

        var dt = new Date();
        dt.setHours(dt.getHours() + 2);

        const bgColor = sessionStorage.getItem("--bg-color");
        const borderColor = bgColor !== "#FFFFFF" ||
            bgColor !== "#FFF" ||
            bgColor !== "#ffffff" ||
            bgColor !== "#fff" ? '#DDDDDD' : '#FFFFFF'
        const headerColor = bgColor !== "#FFFFFF" ||
            bgColor !== "#FFF" ||
            bgColor !== "#ffffff" ||
            bgColor !== "#fff" ? '#fff' : 'var(--text-action-button-color)';

        this.state = {
            headerColor,
            borderColor,
            bgColor,
            coverageId: -1,
            assistStatement: null,
            errorMessage: false,
            noCoverage: false,
            date: new Date(),
            minDate: dt,
            startDate: new Date().getTime() + 60000 * 130,
            mask: [
                "(",
                /\d/,
                /\d/,
                ")",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
            ],
            number: "",
            recordText: "Gravar...",
            contacts: [],
            coverIds: [
                7400,
                10600,
                10601,
                10602,
                10603,
                10700,
                11300,
                11301,
                11302,
                11303,
                11304,
                11305,
                11600
            ]
        };

        props.keep();
        eraseAddress();
        this.checkContact();
        backPage(this);
        let ArrayQuestions = JSON.parse(
            sessionStorage.getItem("ArrayQuestions")
        );
        if (
            ArrayQuestions[ArrayQuestions.length - 1] !==
            ArrayQuestions[ArrayQuestions.length - 2]
        ) {
            ArrayQuestions.push(ArrayQuestions[ArrayQuestions.length - 1]);
            sessionStorage.setItem(
                "ArrayQuestions",
                JSON.stringify(ArrayQuestions)
            );
        }
    }

    onChange = (date) => this.setState({ date });

    async componentDidMount() {
        sessionStorage.setItem("Action", "NextQuest");
        sessionStorage.setItem("NoAllowOrigin", "True");

        const idItemCoberto = sessionStorage.getItem("ConveredSelectedItem");
        const accessToken = await this.getAccessToken();

        const assist = await this.getAssistStatement(idItemCoberto, accessToken);

        let indisponivel;
        let noCoverage;
    
        if (assist) {
            if(sessionStorage.getItem("EventSelectedId")){
                indisponivel = assist.coberturas.filter(cobertura => cobertura.id_cobertura === Number(sessionStorage.getItem("CoverageSelectedId")) && cobertura.eventos.filter(evento => evento.id_evento === Number(sessionStorage.getItem("EventSelectedId"))).length > 0).filter(cobertura => (cobertura.quantidade_disponivel !== 0 || cobertura.quantidade_limite_ocorrencias == '-1'));
                noCoverage = assist.coberturas.filter(cobertura => cobertura.id_cobertura === Number(sessionStorage.getItem("CoverageSelectedId")) && cobertura.eventos.filter(evento => evento.id_evento === Number(sessionStorage.getItem("EventSelectedId"))).length > 0).filter(cobertura => (cobertura.quantidade_disponivel !== 0 || cobertura.quantidade_limite_ocorrencias == '-1')).length > 0 ? false : true;
            }else{
                indisponivel = assist.coberturas.filter(cobertura => cobertura.id_cobertura === Number(sessionStorage.getItem("CoverageSelectedId"))).filter(cobertura => (cobertura.quantidade_disponivel !== 0 || cobertura.quantidade_limite_ocorrencias == '-1'));
                noCoverage = assist.coberturas.filter(cobertura => cobertura.id_cobertura === Number(sessionStorage.getItem("CoverageSelectedId"))).length > 0 ? false : true;
            }
        }

        this.setState({
            assistStatement: assist,
            indisponivel,
            noCoverage,
            coverageId: Number(sessionStorage.getItem("CoverageSelectedId")),
        })
    }

    getAccessToken() {
        const url = process.env.REACT_APP_API_URL_ASSIST_TOKEN;

        const res = fetch(url, {
            method: 'post',
            headers: new Headers({
                Authorization: 'Basic YmI1YjM3MjktMTFjYi0zZTEwLWI5Y2QtNzI5ODhlNjMzNjZmOjQyNjI5Njg1LTk0NWYtMzAzZi05MTJjLWZjZTAyY2ZkMmJlNA==',
                "Content-Type": "application/json",
            }),
            body: JSON.stringify({
                grant_type: 'client_credentials'
            })
        })
            .then((response) => response.json())
            .then((response) => {
                return response.access_token;
            });
        return res;
    }

    getAssistStatement(idItemCoberto, accessToken) {
        const url =  process.env.REACT_APP_API_URL_ASSIST_LIST + idItemCoberto;

        const res = fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem('SelectedWaletId'),
                access_token: accessToken,
                client_id: 'bb5b3729-11cb-3e10-b9cd-72988e63366f'
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                return response;
            });

        return res;
    }

    render() {
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div>
                    <div className="content-header">
                        <div className="persona-header">
                            <div className="persona-header__content">
                                <h3 className="persona-header__title">
                                    Extrato de assistências:
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div style={{ maxWidth: 750 }} className="personal-info section text-center">

                        {((this.state.indisponivel && this.state.indisponivel.length === 0) || this.state.noCoverage === true) && (
                            <label
                                id="error-text"
                                className="error__text contact-item"
                            >
                                Não há limite de intervenções disponíveis para esse evento, caso desejar entre em contato com a Central de Atendimento{" "}
                                <small>
                                    <div>
                                        {this.state.contacts.map((contact, cont) =>
                                            this.generateContacts(contact, cont)
                                        )}
                                    </div>
                                </small>
                            </label>
                        )}

                        {sessionStorage.getItem("EventSelectedId") ? (
                            <div style={{ overflow: 'auto' }} className="text-center">
                                {this.state.assistStatement && !this.state.noCoverage && (
                                    <table
                                        style={{ borderColor: this.state.borderColor }}
                                        className="assist-statement">
                                        <thead style={{ color: this.state.headerColor }}>
                                            <tr>
                                                <td>Cobertura</td>
                                                <td>Limite</td>
                                                <td>Disponível</td>
                                                <td>Evento</td>
                                                <td>Uso</td>
                                            </tr>
                                        </thead>
                                        {this.state.assistStatement.coberturas.filter(cobertura => (cobertura.id_cobertura === this.state.coverageId && cobertura.eventos.filter(evento => evento.id_evento === Number(sessionStorage.getItem("EventSelectedId"))).length > 0)).map(cobertura => (
                                            ((!(cobertura.eventos[0].id_evento == 2718 && cobertura.eventos.length == 1) && !this.state.coverIds.includes(cobertura.id_cobertura)) && (
                                                <tbody key={cobertura.id_cobertura}>
                                                    <tr>
                                                        <td style={{ borderColor: this.state.borderColor }} rowSpan={cobertura.eventos.length}>{cobertura.descricao_cobertura}</td>
                                                        <td style={{ borderColor: this.state.borderColor }} rowSpan={cobertura.eventos.length}>{cobertura.quantidade_limite_ocorrencias == '-1' ? 'Ilimitado': cobertura.quantidade_limite_ocorrencias}</td>
                                                        <td style={{ borderColor: this.state.borderColor }} rowSpan={cobertura.eventos.length}>{cobertura.quantidade_limite_ocorrencias == '-1' ? 'Ilimitado': cobertura.quantidade_disponivel}</td>
                                                        {(cobertura.eventos[0].id_evento != 2718 && (
                                                            <>
                                                                <td style={{ borderColor: this.state.borderColor }} className="assist-statement-event">{cobertura.eventos[0].descricao_evento}</td>
                                                                <td style={{ borderColor: this.state.borderColor }} className="assist-statement-event">{cobertura.eventos[0].quantidade_ocorrencias_utilizadas}</td>
                                                            </>
                                                        ))}
                                                    </tr>
                                                    {cobertura.eventos.filter((evento, index) => index !== 0).map(evento => (
                                                        (evento.id_evento != 2718 && (
                                                            <tr key={evento.id_evento} >
                                                                <td style={{ borderColor: this.state.borderColor }} className="assist-statement-event">{evento.descricao_evento}</td>
                                                                <td style={{ borderColor: this.state.borderColor }} className="assist-statement-event">{evento.quantidade_ocorrencias_utilizadas}</td>
                                                            </tr>
                                                        ))
                                                    ))}
                                                </tbody>
                                            ))
                                        ))}
                                    </table>
                                )}
                            </div>) : (
                            <div style={{ overflow: 'auto' }} className="text-center">
                                {this.state.assistStatement && !this.state.noCoverage && (
                                    <table
                                        style={{ borderColor: this.state.borderColor }}
                                        className="assist-statement">
                                        <thead style={{ color: this.state.headerColor }}>
                                            <tr>
                                                <td>Cobertura</td>
                                                <td>Limite</td>
                                                <td>Disponível</td>
                                                <td>Evento</td>
                                                <td>Uso</td>
                                            </tr>
                                        </thead>
                                        {this.state.assistStatement.coberturas.filter(cobertura => cobertura.id_cobertura === this.state.coverageId).map(cobertura => (
                                            ((!(cobertura.eventos[0].id_evento == 2718 && cobertura.eventos.length == 1) && !this.state.coverIds.includes(cobertura.id_cobertura)) && (
                                                <tbody key={cobertura.id_cobertura}>
                                                    <tr>
                                                        <td style={{ borderColor: this.state.borderColor }} rowSpan={cobertura.eventos.length}>{cobertura.descricao_cobertura}</td>
                                                        <td style={{ borderColor: this.state.borderColor }} rowSpan={cobertura.eventos.length}>{cobertura.quantidade_limite_ocorrencias == '-1' ? 'Ilimitado': cobertura.quantidade_limite_ocorrencias}</td>
                                                        <td style={{ borderColor: this.state.borderColor }} rowSpan={cobertura.eventos.length}>{cobertura.quantidade_limite_ocorrencias == '-1' ? 'Ilimitado': cobertura.quantidade_disponivel}</td>
                                                        {(cobertura.eventos[0].id_evento != 2718 && (
                                                            <>
                                                                <td style={{ borderColor: this.state.borderColor }} className="assist-statement-event">{cobertura.eventos[0].descricao_evento}</td>
                                                                <td style={{ borderColor: this.state.borderColor }} className="assist-statement-event">{cobertura.eventos[0].quantidade_ocorrencias_utilizadas}</td>
                                                            </>
                                                        ))}
                                                    </tr>
                                                    {cobertura.eventos.filter((evento, index) => index !== 0).map(evento => (
                                                        (evento.id_evento != 2718 && (
                                                            <tr key={evento.id_evento} >
                                                                <td style={{ borderColor: this.state.borderColor }} className="assist-statement-event">{ evento.descricao_evento}</td>
                                                                <td style={{ borderColor: this.state.borderColor }} className="assist-statement-event">{evento.quantidade_ocorrencias_utilizadas}</td>
                                                            </tr>
                                                        ))
                                                    ))}
                                                </tbody>
                                            ))
                                        ))}
                                    </table>
                                )}
                            </div>
                        )}

                        {this.state.indisponivel && this.state.indisponivel.length !== 0 && (
                            <button
                                className={"around-btn-light"}
                                onClick={this.clickEvent.bind(this)}
                            >
                                <div
                                    color="light"
                                    className="disable-hover button button-ios button-default button-default-ios button-ios-light"
                                >
                                    <span className="button-inner">
                                        Prosseguir
                                    </span>
                                    <div className="button-effect" />
                                </div>
                            </button>
                        )}
                    </div>
                </div>
            </CSSTransition>
        );
    }

    clickEvent() {
        var isOmni = sessionStorage.getItem("FlagPreAssist");
        if(isOmni == 'True'){
            this.preAssistCreate();  
        }else{
            this.props.history.push("/informacoes-pessoais");
        
            topFunction();
        }
    }

    generateContacts(contactDescription, cont) {
        if (contactDescription.flexibefalha !== "sim") {
            return true;
        }
        let result;
        switch (contactDescription.tpcontato) {
            case "ema":
                const mailTo = `mailto:${contactDescription.contato}`;
                result = (
                    <div className={"contact-item"} key={cont}>
                        <a
                            className={"contact-title contact-item"}
                            href={mailTo}
                        >
                            {contactDescription.contato}
                        </a>{" "}
                        <span className={"contact-description contact-item"}>
                            ( {contactDescription.legenda} )
                        </span>
                    </div>
                );
                break;
            case "tel":
                const tel = `tel:+${contactDescription.contato}`;
                result = (
                    <div className={"contact-item"} key={cont}>
                        <a className={"contact-title contact-item"} href={tel}>
                            {contactDescription.contato}
                        </a>{" "}
                        <span className={"contact-description contact-item"}>
                            ( {contactDescription.legenda} )
                        </span>
                    </div>
                );
                break;
            case "url":
                result = (
                    <div className={"contact-item"} key={cont}>
                        <a
                            href={contactDescription.contato}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={"contact-title contact-item"}
                        >
                            {contactDescription.urlimgcontato != undefined && contactDescription.urlimgcontato != null ?
                            <img style={{display:"block"}} src={contactDescription.urlimgcontato}/>
                            : 
                            contactDescription.contato}
                        </a>{" "}
                        <span className={"contact-description contact-item"}>
                            ( {contactDescription.legenda} )
                        </span>
                    </div>
                );
                break;
            default:
                console.log(contactDescription.tpcontato);
                break;
        }
        return result;
    }

    consultContact(type) {
        const Token = sessionStorage.getItem("Token");
        const url =
            process.env.REACT_APP_API_URL_ESSENCIA +
            "/cliente-corporativo/contatos";

        fetch(url, {
            headers: new Headers({
                Authorization: "Bearer " + Token,
                "Content-Type": "application/json",
            }),
            method: "post",
            body: JSON.stringify({
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if (type === "Login") {
                    this.setState({
                        contacts: response,
                    });
                }
            })
            .catch((error) => {
                if (type === "Login") {
                    this.setState({
                        contacts: [],
                    });
                }
            });
    }

    checkContact() {
        this.consultContact("Login");
    }

    preAssistCreate() {
        const url =
        process.env.REACT_APP_API_URL_TEMPO +
        "/pre-assistencia/criar";

        fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
                "Content-Type": "application/json",
                "X-JuvoTransacaoID": sessionStorage.getItem("juvoTransactionID")
            }),
            method: "post",
            body: JSON.stringify({
                idItemCoberto: sessionStorage.getItem("ConveredSelectedItem"),
                idChaveCombo: sessionStorage.getItem("ComboId"),
                usuarioAbertura: sessionStorage.getItem("clerkName") === null
                            ? ""
                            : sessionStorage.getItem("clerkName")
            }),
        })
            .then((response) => {
                try {
                    return response.json();
                } catch (error) {
                    return Promise.resolve({});
                }
            })
            .then((response) => {
                if (response.idAssistencia) {
                    sessionStorage.setItem("PreAssistId", response.idAssistencia);
                    this.props.history.push("/informacoes-pessoais");
        
                    topFunction();
                } 
            })
            .catch((error) => {
                console.log(error);
            });
    }
}

export default AssistStatement;
