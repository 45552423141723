import React from 'react';
import { NavLink } from 'react-router-dom';
import { topFunction } from '../../../App';
import wheels from "../../../img/wheels.svg";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import Horizontal from "../../Horizontal";

const Wheel = () => {
    return(
        <CSSTransition
            in={true}
            appear={true}
            timeout={800}
            classNames="fade"
        >
            <div>
                <div className="content-header">
                    <div className="persona-header" >
                        <div className="persona-header__content">
                            <h3 className="persona-header__title">Quantos pneus estão danificados?</h3>
                        </div>
                    </div>
                </div>
                <div className="spacer"/>
                <div className="container">
                    <div className="question">
                        <img src={wheels} alt="Rodas?" className="question__img"/>
                        <div className="question-ans">
                            <Horizontal></Horizontal>
                        </div>
                        <div className="container__div__footer row">
                            <NavLink onClick={function(event){topFunction()}} to="Wrench" className="waves-effect waves-light btn-large btn-success" ><i className="fa fa-lg fa-check margin__button-big"/>  Confirmar</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default Wheel;