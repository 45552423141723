import React, { Component } from "react";
import {
    backPageQuestions,
    topFunction,
    GoogleAnalyticsEvent,
} from "../../App";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import Modal from "react-bootstrap/Modal";
import { Redirect } from "react-router-dom";
import { Accompaniment } from "../controller/ControllerFunctions.js";

var numbers = ["Question1"];

class Decision extends Component {
    constructor(props) {
        super(props);
        let Action = sessionStorage.getItem("Action");

        try {
            let accessPage = JSON.parse(sessionStorage.getItem("BackPage"))[
                JSON.parse(sessionStorage.getItem("BackPage")).length - 1
            ];

            if (
                accessPage !== "/" &&
                accessPage !== "/carregando" &&
                accessPage !== "/itens-cobertos" &&
                accessPage !== "/Login"
            ) {
                this.props.history.push("/");
                return;
            }
        } catch (error) {
            console.log(error);
        }

        props.keep();
        backPageQuestions(this);
        if (
            Action !== "NextQuest" &&
            Action !== "ConsultTree" &&
            Action !== "SaveAnswer"
        ) {
            sessionStorage.setItem("Action", "NextQuest");
            props.history.push("/carregando");
            return;
        }

        if (sessionStorage.getItem("FirstQuestion") === "True") {
            sessionStorage.removeItem("FirstQuestion");
            this.verifyOpenAssistence(sessionStorage.getItem("ConveredSelectedItem"));
        }

        this.state = {
            url: sessionStorage.getItem("QuestionPath"),
            modalShow: true,
        };
    }

    componentDidMount() {
        document.body.style.overflow = "auto";
        document.body.style.paddingRight = "unset";
        document.body.classList.remove("modal-open");
        document.body.classList.remove("contact-list");
        document.getElementsByClassName("modal")[0].style.display = "none";
        document.getElementsByClassName("modal-backdrop")[0].style.display =
            "none";
        document.getElementById("back-icon").style.display = "block";
    }

    render() {
        let modalClose = () =>
            (document.getElementsByClassName("modal")[0].style.display =
                "none");
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div>
                    {numbers.map((number, cont) => (
                        <div key={number.toString()}>
                            <div className="content-header">
                                <div className="persona-header">
                                    <div className="persona-header__content">
                                        <h3 className="persona-header__title">
                                            {sessionStorage.getItem(
                                                "QuestionTitle"
                                            )}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="spacer" />
                            <div className="container">
                                <div className="question">
                                    <img
                                        onError={this.erroHandler}
                                        id={"errorImg"}
                                        src={this.state.url}
                                        alt="Blindado"
                                        className="question__img"
                                    />
                                    <div className="question-ans">
                                        <div
                                            onClick={this.chooseChoice.bind(
                                                this,
                                                1
                                            )}
                                            className="waves-effect waves-light btn-large btn-danger"
                                        >
                                            <i className="fa fa-lg fa-times container__button--margin " />
                                            {sessionStorage.getItem(
                                                "QuestionText1"
                                            )}
                                        </div>
                                        <div
                                            onClick={this.chooseChoice.bind(
                                                this,
                                                0
                                            )}
                                            className="waves-effect waves-light btn-large btn-success"
                                        >
                                            <i className="fa fa-lg fa-check container__button--margin" />
                                            {sessionStorage.getItem(
                                                "QuestionText0"
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <MyVerticallyCenteredModal
                        show={this.state.modalShow}
                        onHide={modalClose}
                        destiny={this.state.destiny}
                    />
                </div>
            </CSSTransition>
        );
    }

    erroHandler() {
        document.getElementById("errorImg").style.display = "none";
    }

    chooseChoice(id) {
        var itemClicked = sessionStorage.getItem("QuestionId" + id);
        var NextQuestionSelected = sessionStorage.getItem("NextQuestion" + id);
        sessionStorage.setItem("IdAnswer", itemClicked);
        sessionStorage.setItem("Action", "SaveAnswer");
        sessionStorage.setItem("NextQuestionSelected", NextQuestionSelected);
        topFunction();

        let label =
            sessionStorage.getItem("QuestionText" + id) + " - " + itemClicked;
        GoogleAnalyticsEvent(
            sessionStorage.getItem("SelectedWalet"),
            sessionStorage.getItem("AtualId"),
            label
        );

        this.props.history.push(`/Carregando`);
    }

    verifyOpenAssistence(IdItemCoberto) {
        const urlHours =
            process.env.REACT_APP_API_URL_JUVO +
            "/assistenciaServico/segurado?idItemCoberto=" +
            IdItemCoberto +
            "&notInStatus=3&notInStatus=4&notInStatus=5&notInStatus=6&notInStatus=2";
        fetch(urlHours, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem("SelectedWaletId"),
                Authorization: sessionStorage.getItem("Authorization"),
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                if(Object.values(response).length > 0 && response.codigo !== 999){
                    document.getElementsByClassName("modal")[0].style.display =
                        "block";
                    document.getElementsByClassName(
                        "modal-backdrop"
                    )[0].style.display = "block";
                }else{
                    sessionStorage.setItem("ConveredSelectedItem", IdItemCoberto);
                    sessionStorage.setItem("Action", "ConsultTree");
                    this.props.history.push(`/carregando`);
                }
            })
            .catch((error) => {
                console.log();
                sessionStorage.setItem("ConveredSelectedItem", IdItemCoberto);
                sessionStorage.setItem("Action", "ConsultTree");
                this.props.history.push(`/carregando`);
            });
    }
}

class MyVerticallyCenteredModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            redirectLabel: "/contatos",
        };
    }

    render() {
        return this.state.redirect === true ? (
            <Redirect to={this.state.redirectLabel} push />
        ) : (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        AVISO
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Há assistência em andamento, deseja acompanhar?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <div
                        onClick={this.changeToAccompaniment.bind(this)}
                        style={{ cursor: "pointer" }}
                        className="waves-effect waves-light btn-large btn-success"
                    >
                        <i className="fa fa-lg fa-check container__button--margin " />{" "}
                        Sim
                    </div>
                    <div
                        onClick={this.changeToQuestion}
                        className="waves-effect waves-light btn-large btn-danger"
                    >
                        <i className="fa fa-lg fa-times container__button--margin " />{" "}
                        Não
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }

    async changeToAccompaniment() {
        const retorno = await Accompaniment(this.props);
        if (retorno) {
            sessionStorage.setItem("Action", "Accompaniment");
            topFunction();
            this.setState({
                redirect: true,
                redirectLabel: "/acompanhamentos",
            });
        } else {
            console.log("");
        }
    }

    changeToQuestion() {
        document.getElementsByClassName("modal")[0].style.display = "none";
        document.getElementsByClassName("modal-backdrop")[0].style.display =
            "none";
    }
}

export default Decision;
