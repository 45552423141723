import React, { Component } from "react";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import { backPage, topFunction, eraseAddress } from "../App";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import pt from "date-fns/locale/pt";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { NavLink } from "react-router-dom";

registerLocale("pt", pt);
var numbers = [];
for (var i = 0; i < sessionStorage.length; i++) {
    if (sessionStorage.key(i).includes("Contact")) {
        numbers.push(sessionStorage.getItem(sessionStorage.key(i)));
    }
}

class AssistStatement extends Component {
    constructor(props) {
        super(props);

        var dt = new Date();
        dt.setHours(dt.getHours() + 2);

        const bgColor = sessionStorage.getItem("--bg-color");
        const borderColor = bgColor !== "#FFFFFF" ||
            bgColor !== "#FFF" ||
            bgColor !== "#ffffff" ||
            bgColor !== "#fff" ? '#DDDDDD' : '#FFFFFF'
        const headerColor = bgColor !== "#FFFFFF" ||
            bgColor !== "#FFF" ||
            bgColor !== "#ffffff" ||
            bgColor !== "#fff" ? 'var(--text-action-button-color)' : 'fff';

        const assistStatementCoveredId = sessionStorage.getItem("AssistStatementCoveredId");
        const assistStatementSelectedId = sessionStorage.getItem("AssistStatementSelectedId");
        const assistStatementAddress = sessionStorage.getItem("AssistStatementAddress");
        const idItemCoberto = sessionStorage.getItem("ConveredSelectedItem");
        
        if (!!assistStatementCoveredId === false) {
            sessionStorage.setItem("AssistStatementCoveredId", idItemCoberto);
        }

        this.state = {
            assistStatementCoveredId,
            assistStatementAddress,
            isModalOpen: false,
            headerColor,
            borderColor,
            coverageId: -1,
            assistStatement: null,
            errorMessage: false,
            date: new Date(),
            minDate: dt,
            startDate: new Date().getTime() + 60000 * 130,
            mask: [
                "(",
                /\d/,
                /\d/,
                ")",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                "-",
                /\d/,
                /\d/,
                /\d/,
                /\d/,
            ],
            number: "",
            recordText: "Gravar...",
            coverIds: [
                7400,
                10600,
                10601,
                10602,
                10603,
                10700,
                11300,
                11301,
                11302,
                11303,
                11304,
                11305,
                11600
            ]
        };

        props.keep();
        eraseAddress();
        backPage(this);
        let ArrayQuestions = JSON.parse(
            sessionStorage.getItem("ArrayQuestions")
        );
        if (
            ArrayQuestions[ArrayQuestions.length - 1] !==
            ArrayQuestions[ArrayQuestions.length - 2]
        ) {
            ArrayQuestions.push(ArrayQuestions[ArrayQuestions.length - 1]);
            sessionStorage.setItem(
                "ArrayQuestions",
                JSON.stringify(ArrayQuestions)
            );
        }
    }

    onChange = (date) => this.setState({ date });

    async componentDidMount() {
        sessionStorage.setItem("Action", "NextQuest");
        sessionStorage.setItem("NoAllowOrigin", "True");

       this.getData();
    }

    async getData(){
        const idItemCoberto = sessionStorage.getItem("AssistStatementCoveredId") ? sessionStorage.getItem("AssistStatementCoveredId") : sessionStorage.getItem("ConveredSelectedItem");

        const accessToken = await this.getAccessToken();

        const assist = await this.getAssistStatement(idItemCoberto, accessToken);

        this.setState({
            assistStatement: assist,
            coverageId: Number(sessionStorage.getItem("CoverageSelectedId")),
        });
    }

    getAccessToken() {
        const url = process.env.REACT_APP_API_URL_ASSIST_TOKEN;

        const res = fetch(url, {
            method: 'post',
            headers: new Headers({
                Authorization: 'Basic YmI1YjM3MjktMTFjYi0zZTEwLWI5Y2QtNzI5ODhlNjMzNjZmOjQyNjI5Njg1LTk0NWYtMzAzZi05MTJjLWZjZTAyY2ZkMmJlNA==',
                "Content-Type": "application/json",
            }),
            body: JSON.stringify({
                grant_type: 'client_credentials'
            })
        })
            .then((response) => response.json())
            .then((response) => {
                return response.access_token;
            });
        return res;
    }

    getAssistStatement(idItemCoberto, accessToken) {
        const url =  process.env.REACT_APP_API_URL_ASSIST_LIST + idItemCoberto;

        const res = fetch(url, {
            headers: new Headers({
                token: sessionStorage.getItem("token"),
                chave: sessionStorage.getItem("chave"),
                idclientecorporativo: sessionStorage.getItem(
                    "idclientecorporativo"
                ),
                idtipocarteira: sessionStorage.getItem('SelectedWaletId'),
                access_token: accessToken,
                client_id: 'bb5b3729-11cb-3e10-b9cd-72988e63366f'
            }),
        })
            .then((response) => response.json())
            .then((response) => {
                return response;
            });

        return res;
    }

    render() {
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div>
                <div className="loader__position" style={{ display: this.state.coverageId == -1 ? "block" : "none", textAlign: "center" }}>
                        <div className="lds-ring">
                            <div
                                style={
                                    this.state.bgColor !== "#FFFFFF" ||
                                        this.state.bgColor !== "#FFF" ||
                                        this.state.bgColor !== "#ffffff" ||
                                        this.state.bgColor !== "#fff"
                                        ? {
                                            borderColor:
                                                "#DDDDDD transparent transparent transparent",
                                        }
                                        : {
                                            borderColor:
                                                "#FFFFFF transparent transparent transparent",
                                        }
                                }
                            ></div>
                            <div
                                style={
                                    this.state.bgColor !== "#FFFFFF" ||
                                        this.state.bgColor !== "#FFF" ||
                                        this.state.bgColor !== "#ffffff" ||
                                        this.state.bgColor !== "#fff"
                                        ? {
                                            borderColor:
                                                "#DDDDDD transparent transparent transparent",
                                        }
                                        : {
                                            borderColor:
                                                "#FFFFFF transparent transparent transparent",
                                        }
                                }
                            ></div>
                            <div
                                style={
                                    this.state.bgColor !== "#FFFFFF" ||
                                        this.state.bgColor !== "#FFF" ||
                                        this.state.bgColor !== "#ffffff" ||
                                        this.state.bgColor !== "#fff"
                                        ? {
                                            borderColor:
                                                "#DDDDDD transparent transparent transparent",
                                        }
                                        : {
                                            borderColor:
                                                "#FFFFFF transparent transparent transparent",
                                        }
                                }
                            ></div>
                            <div
                                style={
                                    this.state.bgColor !== "#FFFFFF" ||
                                        this.state.bgColor !== "#FFF" ||
                                        this.state.bgColor !== "#ffffff" ||
                                        this.state.bgColor !== "#fff"
                                        ? {
                                            borderColor:
                                                "#DDDDDD transparent transparent transparent",
                                        }
                                        : {
                                            borderColor:
                                                "#FFFFFF transparent transparent transparent",
                                        }
                                }
                            ></div>
                        </div>
                    </div>
                <div style={{ display: this.state.coverageId != -1? "block" : "none" }}>
                    <MyVerticallyCenteredModal
                        show={this.state.isModalOpen}
                        onHide={() => this.setState({
                            isModalOpen: false,
                        })}
                    />
                    <div className="content-header">
                        <div className="persona-header">
                            <div className="persona-header__content">
                                <h3 className="persona-header__title">
                                    Extrato de assistências:
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: 750 }} className="personal-info section">
                        <div className="item-info">
                        <span className="question-color-text conclude-label">{sessionStorage.getItem("AssistStatementAddress")}</span>
                            <div className="divisor-line"></div>
                            <span className="question-color-text conclude-label">{sessionStorage.getItem("AssistStatementPolicy")}</span>
                        </div>
                        <div
                            className="btn-success btn-large"
                            onClick={() => this.setState({
                                isModalOpen: true,
                            })}
                        >
                            Trocar item
                        </div>
                    </div>
                    <div style={{ maxWidth: 750 }} className="personal-info section">
                        {this.state.errorMessage ? (
                            <div className="info-error-container">
                                <label className="text-action-button-color error__text">
                                    <small className={"error-size-text"}>
                                        Preencha todos os dados obrigatórios
                                    </small>
                                </label>
                            </div>
                        ) : (
                                <></>
                            )}

                        <div style={{ overflow: 'auto' }} className="text-center">
                            {this.state.assistStatement && (
                                <table
                                    style={{ borderColor: this.state.borderColor }}
                                    className="assist-statement">
                                    <thead style={{ color: this.state.headerColor }}>
                                        <tr>
                                            <td>Cobertura</td>
                                            <td>Limite</td>
                                            <td>Disponível</td>
                                            <td>Evento</td>
                                            <td>Uso</td>
                                        </tr>
                                    </thead>
                                    {this.state.assistStatement.coberturas.map(cobertura => (
                                        ((!(cobertura.eventos[0].id_evento == 2718 && cobertura.eventos.length == 1) && !this.state.coverIds.includes(cobertura.id_cobertura)) && (
                                            <tbody key={cobertura.id_cobertura}>
                                                <tr>
                                                    <td style={{ borderColor: this.state.borderColor }} rowSpan={cobertura.eventos.length}>{cobertura.descricao_cobertura}</td>
                                                    <td style={{ borderColor: this.state.borderColor }} rowSpan={cobertura.eventos.length}>{cobertura.quantidade_limite_ocorrencias == '-1' ? 'Ilimitado': cobertura.quantidade_limite_ocorrencias}</td>
                                                    <td style={{ borderColor: this.state.borderColor }} rowSpan={cobertura.eventos.length}>{cobertura.quantidade_limite_ocorrencias == '-1' ? 'Ilimitado': cobertura.quantidade_disponivel}</td>
                                                    {(cobertura.eventos[0].id_evento != 2718 && (
                                                        <>
                                                            <td style={{ borderColor: this.state.borderColor }} className="assist-statement-event">{cobertura.eventos[0].descricao_evento}</td>
                                                            <td style={{ borderColor: this.state.borderColor }} className="assist-statement-event">{cobertura.eventos[0].quantidade_ocorrencias_utilizadas}</td>
                                                        </>
                                                    ))}
                                                </tr>
                                                
                                                {cobertura.eventos.filter((evento, index) => index !== 0).map(evento => (
                                                    (evento.id_evento != 2718 && (
                                                        <tr key={evento.id_evento} >
                                                            <td style={{ borderColor: this.state.borderColor }} className="assist-statement-event">{evento.descricao_evento}</td>
                                                            <td style={{ borderColor: this.state.borderColor }} className="assist-statement-event">{evento.quantidade_ocorrencias_utilizadas}</td>
                                                        </tr>
                                                    ))
                                                ))}
                                            </tbody>
                                        ))
                                    ))}
                                </table>
                            )}
                        </div>
                    </div>
                </div>
                </div>
            </CSSTransition>
        );
    }

    clickEvent() {
        this.props.history.push("/informacoes-pessoais");
        topFunction();
    }
}

class MyVerticallyCenteredModal extends React.Component {
    constructor(props) {
        super(props);

        const numbers = [];

        for (var i = 0; i < sessionStorage.length; i++) {
            if (sessionStorage.key(i).includes("ConveredItem")) {
                numbers.push(sessionStorage.getItem(sessionStorage.key(i)));
            }
        }

        this.state = {
            numbers,
        }


    }

    Capitalize(str, lower = false) {
        if(str == null){
            return '';
        }
        return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
    };

    clickMe = (state) => {
        this.props.setModalState(state);
    }

    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Por favor, escolha qual apólice deseja visualizar:
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {this.state.numbers.map((number, id) => (
                        <Col
                            xs={12}
                            sm={6}
                            md={6}
                            large={6}
                            xl={6}
                            className="col s6 m6 l4"
                            key={number.toString()}
                        >
                            <div
                                onClick={() => {
                                    const coveredId = sessionStorage.getItem("ConveredId" + id);
                                    
                                    sessionStorage.setItem("AssistStatementCoveredId", coveredId);
                                    let address = '';
                                    if(sessionStorage.getItem("SelectedWalet") == 'Automóvel'){
                                        address = `PLACA ${sessionStorage.getItem(
                                                "ConveredItem" + id
                                            ).toUpperCase()}`;
                                    }else{ 
                                        address = this.Capitalize(sessionStorage.getItem(
                                            "ConveredItem" + id
                                        ), true);
                                    }
                                    
                                    const policy = `Nº Apólice: ${sessionStorage.getItem("PolicyNumber" + id)}`;
                                    sessionStorage.setItem("AssistStatementAddress", address);
                                    sessionStorage.setItem("AssistStatementPolicy", policy);
                                    sessionStorage.setItem("AssistStatementSelectedId", id);
                                    window.location.reload();
                                    this.props.onHide()
                                }}
                                className="card-link"
                            >
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0 16px' }} className="card card--sant">
                                    <div style={{ height: 'auto', marginBottom: 0 }} className="card-content card-wallet">
                                        {sessionStorage.getItem("SelectedWalet") == 'Automóvel' ? (
                                            <p>
                                                {`PLACA ${sessionStorage.getItem(
                                                    "ConveredItem" + id
                                                ).toUpperCase()}`}

                                                {}
                                            </p>
                                        ) : (
                                            <p>
                                                {this.Capitalize(sessionStorage.getItem(
                                                    "ConveredItem" + id
                                                ), true)}
                                            </p>
                                        )}
                                        
                                        {sessionStorage.getItem("SelectedWalet") == 'Residência' ? (
                                            <p>
                                                {`CEP ${sessionStorage.getItem(
                                                    "AddressResidcep"
                                                )}`}
                                            </p>
                                         ): ''}
                                         {sessionStorage.getItem("SelectedWalet") == 'Residência' ? (
                                            <p>
                                                {`${this.Capitalize(sessionStorage.getItem(
                                                    "AddressResidcidade"
                                                ), true)} - ${sessionStorage.getItem(
                                                    "AddressResiduf"
                                                )}`}
                                            </p>
                                        ): ''}
                                        <h2
                                            id={sessionStorage.getItem(
                                                "ConveredId" + id
                                            )}
                                            className={
                                                "itemClicked" + id
                                            }
                                        >
                                            {`Nº Apólice: ${sessionStorage.getItem("PolicyNumber" + id)}`}
                                        </h2>
                                    </div>
                                </div>
                                {/* <span style={{ display: "none" }}>
                                    {(id = id + 1)}
                                </span> */}
                            </div>
                        </Col>
                    ))}
                </Modal.Body>
            </Modal>
        );
    }
}

export default AssistStatement;
