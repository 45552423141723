import React, { Component } from 'react'
import Slider from 'react-rangeslider'
import battery10 from "../img/battery10.svg";
import CSSTransition from "react-transition-group/esm/CSSTransition";

class Carousel extends Component {
    constructor (props, context) {
        super(props, context);

        let cont = -1;
        const url = [];

        for (let i = 0; i < sessionStorage.length; i++) {
            if(sessionStorage.key(i).includes("QuestionText")){
                cont = cont + 1;
            }
            if(sessionStorage.key(i).includes("QuestionUrl")){
                url.push(url.length);
            }
        }

        let inicialClass = [];
        for (let i = 0; i <= cont; i++) {
            if(i === 0){
                inicialClass[i] = "gas__img--active"
            }else{
                inicialClass[i] = "gas__img"
            }
        }


        for (var k = 0; k < url.length; k++) {
            url[k] = (sessionStorage.getItem("QuestionUrl"+k));
        }


        this.state = {
            value: 0,
            contador: 0,
            img: battery10,
            url,
            text: sessionStorage.getItem("QuestionText0"),
            classe1: 'gas__img--active',
            qtdmax: cont,
            classe: inicialClass
        }
    }

    render () {
        const { value } = this.state;
        const { text } = this.state;
        const { qtdmax } = this.state;
        const { classe } = this.state;
        const { url } = this.state;
        return (
            <div className='slider'>
                <CSSTransition
                    in={true}
                    appear={true}
                    timeout={800}
                    classNames="fade"
                >
                    <div className="slider__image">
                        {classe.map((number, cont) =>
                            <img onError={this.erroHandler.bind(cont, this)} id={"errorImg" + cont} key={cont.toString()} alt="Combustível" className={classe[cont]} src={url[value]}/>
                        )}

                    </div>
                </CSSTransition>
                <div className='value slider__text'>{text}</div>
                <Slider
                    min={0}
                    max={qtdmax}
                    value={value}
                    tooltip={false}
                    onChange={this.handleChange}
                />
                <br/>
                <div className="container__div__footer row">
                    <div onClick={this.props.action} className="waves-effect waves-light btn-large btn-success" ><i className="fa fa-lg fa-check margin__button-big"/>  Confirmar</div>
                </div>
            </div>
        )
    }

    handleChange = value => {
        this.setState({
            value: value
        });

        let changeClass = [];

        for (let i = 0; i < sessionStorage.length; i++) {
            if(sessionStorage.key(i).includes("QuestionText" + value)){
                for (let cont = 0 ; cont < this.state.classe.length ; cont++){
                    if(cont === value){
                        changeClass[cont] = "gas__img--active";
                    }else{
                        changeClass[cont] = "gas__img";
                    }
                }
                this.setState({
                        text: sessionStorage.getItem(sessionStorage.key(i)),
                        classe: changeClass
                    }
                );
            }
        }
    };

    erroHandler(instantiation , cont){
        cont.target.style.display = "none";
        instantiation.setState({
            contador: instantiation.state.contador + 1,
        });
        if(instantiation.state.contador === instantiation.state.url.length - 1){
            document.getElementsByClassName("slider__image")[0].style.display = "none";
        }
    }
}

export default Carousel;