import React, { Component }  from 'react';
import CSSTransition from "react-transition-group/esm/CSSTransition";
import {backPage, importStyle} from "../App";

class ServiceLocation extends Component {
    constructor(props) {
        super(props);
        var stylePerso = [];
        for (let i = 0; i < sessionStorage.length ; i++) {
            if(sessionStorage.key(i).includes("--")){
                stylePerso.push(sessionStorage.key(i));
                stylePerso.push(sessionStorage.getItem(sessionStorage.key(i)));
            }
        }
        importStyle(stylePerso);

        this.state = {
            text: `${process.env.REACT_APP_PUSH_TEMPO_FRAME}MapDirections.html`,
            url: "MapDirections"
        };
        this.getMaps = this.getMaps.bind(this);
        backPage(this);
    }

    componentDidMount() {
        document.body.style.position = "fixed";
        document.body.style.width = "100%";
    }

    componentWillUnmount() {
        document.body.style.position = "unset";
        document.body.style.width = "unset";
    }

    render() {
        const { text } = this.state;
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div>
                    <div>

                    </div>
                    <div>
                        <iframe id="iframe" onLoad={this.getMaps} title="Maps" className="iframe" src={text}/>
                    </div>
                </div>
            </CSSTransition>
        )
    }

    getMaps(){
        this.setState({ text: process.env.REACT_APP_PUSH_TEMPO_FRAME + "MapDirections.html" });
        try {
            document.getElementById('iframe').contentWindow.geolocate();
        }
        catch (e) {

        }
    }
}

export default ServiceLocation;