import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { topFunction } from "../../App";
import {
    consultContact,
    Accompaniment,
    FAQ,
} from "../controller/ControllerFunctions.js";
import { Redirect } from "react-router-dom";

class Toolbar extends Component {
    constructor(props) {
        super(props);
        let drawerClasses = ["side-drawer"];
        if (props.show) {
            drawerClasses = ["side-drawer", "open"];
        }

        this.state = {
            drawerClasses,
            redirect: false,
            redirectLabel: "/contatos",
        };

    }

    render() {
        return this.state.redirect === true ? (
            <Redirect to={this.state.redirectLabel} push />
        ) : (
                <nav className={this.state.drawerClasses.join(" ")}>
                    <div>
                        <NavLink
                            id="sidebar-btn-home"
                            className={"sidebar-btn"}
                            style={
                                sessionStorage.getItem("@auth-Token") !== null
                                    ? { display: "block" }
                                    : { display: "none" }
                            }
                            onClick={this.props.click}
                            to="/"
                        >
                            <h6 className="persona-header__title__sidebar">Home</h6>
                        </NavLink>
                        <div
                            id="sidebar-btn-acompanhamento"
                            style={
                                sessionStorage.getItem("ConveredSelectedItem") !==
                                    null
                                    ? { display: "block" }
                                    : { display: "none" }
                            }
                            className={"sidebar-btn"}
                            onClick={this.handleClickAcompanhamento.bind(this)}
                        >
                            <h6 className="persona-header__title__sidebar">
                                Acompanhamento
                        </h6>
                        </div>
                        <div
                            id="sidebar-btn-acompanhamento"
                            style={
                                sessionStorage.getItem("ConveredSelectedItem") !==
                                    null
                                    ? { display: "block" }
                                    : { display: "none" }
                            }
                            className={"sidebar-btn"}
                            onClick={this.handleClickExtratoAssistencias.bind(this)}
                        >
                            <h6 className="persona-header__title__sidebar">
                                Extrato de assistências
                        </h6>
                        </div>
                        <div
                            className={"sidebar-btn"}
                            onClick={this.handleClickConsult.bind(this)}
                        >
                            <h6 className="persona-header__title__sidebar">
                                Contato
                        </h6>
                        </div>

                        <div
                            id={"faq-sidebar"}
                            style={
                                sessionStorage.getItem("NoFaqReturn") !== null
                                    ? { display: "block" }
                                    : { display: "none" }
                            }
                            className={"sidebar-btn"}
                            onClick={this.handleClickFAQ.bind(this)}
                        >
                            <h6 className="persona-header__title__sidebar">FAQ</h6>
                        </div>

                        <NavLink
                            id="sidebar-btn-logout"
                            className={"sidebar-btn"}
                            style={
                                sessionStorage.getItem("@auth-Token") !== null
                                    ? { display: "block" }
                                    : { display: "none" }
                            }
                            onClick={() => Logout(this.props)}
                            to="/carregando"
                        >
                            <h6 className="persona-header__title__sidebar">Sair</h6>
                        </NavLink>
                    </div>
                </nav>
            );
    }

    async handleClickConsult() {
        const retorno = await consultContact(this.props);

        if (retorno) {
            sessionStorage.setItem("Action", "consultContact");
            sessionStorage.setItem("GenericError", "");
            sessionStorage.setItem("SpecificError", "");
            topFunction();
            Contact(this.props);
            this.setState({
                redirect: true,
                redirectLabel: "/contatos",
            });
            this.setState({
                redirect: false,
            });
        } else {
            console.log("");
        }
    }

    async handleClickAcompanhamento() {
        const retorno = await Accompaniment(this.props);
        if (retorno) {
            sessionStorage.setItem("Action", "Accompaniment");
            topFunction();
            handleClick(this.props);
            this.setState({
                redirect: true,
                redirectLabel: "/acompanhamentos",
            });
            this.setState({
                redirect: false,
            });
        } else {
            console.log("");
        }
    }

    async handleClickExtratoAssistencias() {
        const retorno = await Accompaniment(this.props);
        if (retorno) {
            sessionStorage.setItem("Action", "Accompaniment");
            topFunction();
            handleClick(this.props);
            this.setState({
                redirect: true,
                redirectLabel: "/extrato-assistencias",
            });
            this.setState({
                redirect: false,
            });
        } else {
            console.log("");
        }
    }

    async handleClickFAQ() {
        const retorno = await FAQ(this.props);
        if (retorno) {
            sessionStorage.setItem("Action", "FAQ");
            topFunction();
            handleClick(this.props);
            this.setState({
                redirect: true,
                redirectLabel: "/faq",
            });
            this.setState({
                redirect: false,
            });
        } else {
            console.log("");
        }
    }
}

function handleClick(props) {
    props.click();
}

function Logout(props) {
    sessionStorage.setItem("Action", "Logout");
    topFunction();
    props.click();
}

function Contact(props) {
    props.click();
}

export default Toolbar;
