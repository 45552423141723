import React , {Component} from 'react';

class DrawerToggleButton extends Component {

    render() {
        return (
            <button style={ sessionStorage.getItem("SelectedWaletId") !== null ? { display : "flex"} : { display : "none"} } className="toggle-button" onClick={this.openSide}>
                <div className="toggle-button__line"/>
                <div className="toggle-button__line"/>
                <div className="toggle-button__line"/>
            </button>
        )
    }

    openSide = () => {
        if(document.getElementsByClassName("side-drawer")[0].classList.length === 1){
            document.getElementsByClassName("side-drawer")[0].classList.add("open");
            document.getElementsByClassName("backdrop")[0].style.display = "block";
        }else{
            document.getElementsByClassName("side-drawer")[0].classList.remove("open");
            document.getElementsByClassName("backdrop")[0].style.display = "none";
        }
    }
}

export default DrawerToggleButton;