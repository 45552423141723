import React, { Component } from "react";
import { addHours, format } from "date-fns";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import { backPage } from "../App";
import { Accompaniment } from "../components/controller/ControllerFunctions";

class ConcludeAssist extends Component {
    constructor(props) {
        super(props);
        try {
            let ConcludeAssitParams = sessionStorage.getItem(
                "ConcludeAssitParams"
            );
            let reschedulingDate = sessionStorage.getItem("ReschedulingDate");
            let idAssist = sessionStorage.getItem("IdAnswer");

            const idclientecorporativo = sessionStorage.getItem("idclientecorporativo");
            const hourString = idclientecorporativo === '542' ? 'duas' : 'três';
            const hour = idclientecorporativo === '542' ? 2 : 3;

            let reschedulingDateIni = format(new Date(Number(reschedulingDate)), 'dd/MM/yyyy HH:mm');
            reschedulingDate = format(addHours(new Date(Number(reschedulingDate)), hour), 'dd/MM/yyyy HH:mm');

            this.state = {
                hourString,
                reschedulingDate,
                reschedulingDateIni,
                idAssist,
                ConcludeAssitParams,
                hideMessagePassword: false
            };

            props.keep();
            this.closeMessagePassword = this.closeMessagePassword.bind(this);
            backPage(this);
        } catch (error) {
            console.log(error);
        }
    }

    componentDidMount() {
        setInterval(() => {
            if (sessionStorage.getItem("Logo") === null) {
                this.props.history.push(`/`);
            }
        }, 1000);
        sessionStorage.setItem("Action", "GoHome");
        document.getElementById("success-back-buttom").style.display = "block";
    }

    componentWillUnmount() {
        if (document.getElementById("success-back-buttom") !== null) {
            document.getElementById("success-back-buttom").style.display =
                "none";
        }
    }

    closeMessagePassword() {
        this.setState({
            hideMessagePassword: true
        });
    }

    render() {
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div>
                    <div className={"content-message-password"} style={
                        this.state.hideMessagePassword === false ?
                            {
                                display: 'block',
                            }
                            :
                            {
                                display: 'none',
                            }
                    }
                    >
                        Reagendado com sucesso!

                        <span
                            onClick={this.closeMessagePassword}
                        >
                            <i className={"fa fa-close"}></i>
                        </span>
                    </div>
                    <div style={{ maxWidth: 750, margin: '0 auto' }}>
                        <div style={{ margin: 'auto', maxWidth: 'none' }} className={"content-conclude-wrapper"}>
                            <>
                                <div className="spacer" />
                                <div style={{ padding: 0, maxWidth: 'none' }} className={"conclude-assist-container"}>
                                    <span style={{ fontSize: 16, fontWeight: 700 }} className={"question-color-text"}>
                                        Protocolo
                                </span>
                                    <span className={"question-color-text"}>
                                        {this.state.idAssist}
                                    </span>
                                    <div className={"divisor-line"} />
                                </div>
                                <div className="spacer" />
                                <div className="datebox">
                                    <div
                                        className={"conclude-assist-container"}
                                        style={
                                            this.state.reschedulingDateIni !== "undefined"
                                                ? { display: "grid", padding: 0, maxWidth: 'none', marginLeft: 0 }
                                                : { display: "none", padding: 0, maxWidth: 'none', marginLeft: 0 }
                                        }
                                    >
                                        <span style={{ fontSize: 16, fontWeight: 700 }} className={"question-color-text"}>
                                        Data e hora do agendamento
                                    </span>
                                        <span className={"question-color-text"}>
                                            {this.state.reschedulingDateIni}
                                        </span>
                                        <div className={"divisor-line"} />
                                    </div>
                                    <div
                                        className={"conclude-assist-container"}
                                        style={
                                            this.state.reschedulingDate !== "undefined"
                                                ? { display: "grid", padding: 0, maxWidth: 'none' }
                                                : { display: "none", padding: 0, maxWidth: 'none' }
                                        }
                                    >
                                        <span style={{ fontSize: 16, fontWeight: 700 }} className={"question-color-text"}>
                                        Prazo para chegada até o local
                                    </span>
                                        <span className={"question-color-text"}>
                                            {this.state.reschedulingDate}
                                        </span>
                                        <div className={"divisor-line"} />
                                    </div>
                                </div>
                            </>
                        </div>

                        <div className="spacer" />
                        <div style={{ padding: 0, maxWidth: 'none' }} className={"conclude-assist-container"}>
                            <span
                                className={
                                    "question-color-text conclude-alert-label"
                                }
                            >
                                Atenção!
                        </span>
                            {sessionStorage.getItem("AssistTimeType") ===
                                "Agendado" &&
                                sessionStorage.getItem("SelectedWaletId") === "2" ? (
                                    <span
                                        className={"question-color-text conclude-label"}
                                    >
                                        {`
                                            Para serviços agendados, o prazo para o
                                            profissional comparecer ao local indicado é de
                                            até ${this.state.hourString} horas a partir do horário selecionado.
                                        `}
                                    </span>
                                ) : (
                                    <></>
                                )}

                            <span className={"question-color-text conclude-label"}>
                                {`O prazo para o profissional comparecer ao local indicado é de até ${this.state.hourString} horas
                                a partir do horário selecionado.`}
                            </span>
                            <span className={"question-color-text conclude-label"}>
                                Mesmo através desse canal virtual, temos um time de profissionais acompanhando
                                sua solicitação 24h por dia.
                            </span>
                            <span className={"question-color-text conclude-label"}>
                                Enquanto você nos espera, use os botões abaixo para
                                acompanhar o trajeto de nosso profissional até você.
                        </span>
                        </div>
                        <div className="spacer" />
                        <div style={{ margin: 0 }} className="question-ans">
                            <div
                                onClick={this.chooseChoice.bind(this, 1)}
                                className="waves-effect waves-light btn-large btn-danger btn-success"
                            >
                                <i className="fa fa-lg fa-home container__button--margin " />
                            Finalizar
                        </div>
                            <div
                                onClick={this.chooseChoice.bind(this, 0)}
                                className="waves-effect waves-light btn-large btn-success"
                            >
                                <i className="fa fa-lg fa-info-circle container__button--margin" />
                                Acompanhar
                                </div>
                        </div>
                        <div className="spacer" />
                        <div style={{ maxWidth: 'none' }} className={"conclude-assist-container"}>
                            <span className={"question-color-text conclude-label"}>
                                A qualquer momento, consulte demais informações na
                                opção FAQ no menu lateral
                        </span>
                        </div>
                    </div>
                </div>

            </CSSTransition>
        );
    }

    async chooseChoice(element) {
        if (element === 1) {
            this.props.history.push("/");
        } else {
            /** Para exibição da previsão de chegada */
            await Accompaniment();
            let reschedulingDate = sessionStorage.getItem("ReschedulingDate");
            const idclientecorporativo = sessionStorage.getItem("idclientecorporativo");
            const hour = idclientecorporativo === '542' ? 2 : 3;
            let reschedulingDateIni = format(new Date(Number(reschedulingDate)), 'dd/MM/yyyy HH:mm');
            reschedulingDate = format(addHours(new Date(Number(reschedulingDate)), hour), 'dd/MM/yyyy HH:mm');
            sessionStorage.setItem("DateRange", reschedulingDateIni + ' - ' + reschedulingDate);

            sessionStorage.setItem("IdAnswer", this.state.idAssist);
            sessionStorage.setItem("Action", "AssistDetail");
            this.props.history.push("/carregando");
        }
    }
}

export default ConcludeAssist;
