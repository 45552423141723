import React, { Component } from "react";
import CSSTransition from "react-transition-group/esm/CSSTransition";
import { backPageQuestions } from "../App";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

class FAQ extends Component {
    constructor(props) {
        super(props);

        const FaqQuestion = [];
        const FaqAnswer = [];
        for (let i = 0; i < sessionStorage.length; i++) {
            if (sessionStorage.key(i).includes("FaqQuestion")) {
                FaqQuestion.push(FaqQuestion.length);
            }
            if (sessionStorage.key(i).includes("FaqAnswer")) {
                FaqAnswer.push(FaqAnswer.length);
            }
        }

        for (let i = 0; i < FaqQuestion.length; i++) {
            FaqQuestion[i] = sessionStorage.getItem("FaqQuestion" + i);
            FaqAnswer[i] = sessionStorage.getItem("FaqAnswer" + i);
        }

        this.state = {
            FaqQuestion,
            FaqAnswer,
        };

        props.keep();
        backPageQuestions(this);
    }

    componentDidMount() {
        setInterval(() => {
            if (sessionStorage.getItem("Logo") === null) {
                this.props.history.push(`/`);
            }
        }, 1000);
    }

    render() {
        return (
            <CSSTransition
                in={true}
                appear={true}
                timeout={800}
                classNames="fade"
            >
                <div>
                    <div className="content-header">
                        <div className="persona-header">
                            <div className="persona-header__content">
                                <h3 className="persona-header__title">FAQ</h3>
                            </div>
                        </div>
                    </div>
                    <div className="section">
                        <div className="row center"></div>
                        <div
                            id="home-wallet"
                            className="row row__cards--display list__question--padding"
                        >
                            <Accordion className={"accordion-container"}>
                                {this.state.FaqQuestion.length !== 0 ? (
                                    this.state.FaqQuestion.map(
                                        (number, cont) => (
                                            <Card key={cont} id={cont}>
                                                <Accordion.Toggle
                                                    as={Card.Header}
                                                    eventKey={cont}
                                                >
                                                    {
                                                        this.state.FaqQuestion[
                                                            cont
                                                        ]
                                                    }
                                                </Accordion.Toggle>
                                                <Accordion.Collapse
                                                    eventKey={cont}
                                                >
                                                    <Card.Body>
                                                        <span className="faq-answer-label">
                                                            {
                                                                this.state
                                                                    .FaqAnswer[
                                                                    cont
                                                                ]
                                                            }
                                                        </span>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        )
                                    )
                                ) : (
                                    <p className="faq-text-colot intro__text padding-15">
                                        {sessionStorage.getItem("NoFaqReturn")}
                                    </p>
                                )}
                            </Accordion>
                        </div>
                    </div>
                </div>
            </CSSTransition>
        );
    }
}

export default FAQ;
