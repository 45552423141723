import React from "react";
import { NavLink } from "react-router-dom";
import { topFunction } from "../../../App";
import CSSTransition from "react-transition-group/esm/CSSTransition";

const Attendance = () => {
    return (
        <CSSTransition in={true} appear={true} timeout={800} classNames="fade">
            <div>
                <div className="content-header">
                    <div className="persona-header">
                        <div className="persona-header__content">
                            <h3 className="persona-header__title">
                                Informações do Atendimento
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="card card--no-pad">
                        <div className="card-content">
                            <ul className="collection collection--acionamento with-header">
                                <li className="collection-item">
                                    <div className="valign-wrapper hspace-between">
                                        <div className="valign-wrapper">
                                            <i className="fa container__ul__li--margin fa-clipboard" />
                                            <span className="title">
                                                {" "}
                                                Nº ATENDIMENTO
                                            </span>
                                        </div>
                                        <div>432424132132</div>
                                    </div>
                                </li>
                                <li className="collection-item">
                                    <div className="valign-wrapper hspace-between">
                                        <div className="valign-wrapper">
                                            <i className="fa container__ul__li--margin fa-car" />
                                            <span className="title">
                                                {" "}
                                                Veículo
                                            </span>
                                        </div>
                                        <div>Corsa Sedã 2014, Preto</div>
                                    </div>
                                </li>
                                <li className="collection-item">
                                    <div className="valign-wrapper hspace-between">
                                        <div className="valign-wrapper">
                                            <i className="fa container__ul__li--margin fa-id-card" />
                                            <span className="title">
                                                {" "}
                                                Placa
                                            </span>
                                        </div>
                                        <div>HBB 8596</div>
                                    </div>
                                </li>
                                <li className="collection-item">
                                    <div className="valign-wrapper hspace-between">
                                        <div className="valign-wrapper">
                                            <i className="fa container__ul__li--margin fa-user" />
                                            <span className="title">
                                                {" "}
                                                Condutor
                                            </span>
                                        </div>
                                        <div>
                                            João da Silva -<br /> 001.001.001-35
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="container__div__footer">
                        <NavLink
                            onClick={function(event) {
                                topFunction();
                            }}
                            to="/"
                            className="waves-effect waves-light btn-large btn-success"
                        >
                            <i className="fa fa-lg fa-check margin__button-big" />{" "}
                            Voltar ao inicio
                        </NavLink>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default Attendance;
